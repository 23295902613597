import { ServerApi } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PublicService = /** @class */ (function () {
    function PublicService(http) {
        this.http = http;
    }
    PublicService.prototype.getHome = function () {
        return this.http.get(ServerApi.PublicRouteTemp + "/home");
    };
    PublicService.prototype.contactUsDownload = function (obj) {
        return this.http.post(ServerApi.PublicRouteTemp + "/book", obj, { responseType: 'blob' });
    };
    PublicService.prototype.downloadBook = function (obj) {
        return this.http.post(ServerApi.PublicRouteTemp + "/add-pending-user", obj);
    };
    PublicService.prototype.contactUsForm = function (obj) {
        return this.http.post(ServerApi.PublicRouteTemp + "/contacts", obj);
    };
    PublicService.prototype.downloadNumber = function () {
        return this.http.get(ServerApi.PublicRouteTemp + "/book");
    };
    PublicService.prototype.convertCurrency = function (obj) {
        return this.http.post(ServerApi.PublicRouteTemp + "/currency/convert", obj);
    };
    PublicService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PublicService_Factory() { return new PublicService(i0.ɵɵinject(i1.HttpClient)); }, token: PublicService, providedIn: "root" });
    return PublicService;
}());
export { PublicService };
