import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {UserAuthService} from "../../services/user-auth.service";

@Component({
  selector: 'app-toast-dialog',
  templateUrl: './toast-dialog.component.html',
  styleUrls: ['./toast-dialog.component.css']
})
export class ToastDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private userAuthService: UserAuthService) { }

  message = this.data.message
  isActivateAccount = this.data.isActivateAccount;
  userEmail = this.data.email
  ngOnInit() {
  }

  resendTheActivationCode() {
    this.userAuthService.sendActivationCodeAgain({email: this.userEmail}).subscribe(res => {
      this.message = 'تم إرسال رمز التفعيل مرة أخرى بنجاح'
      this.userAuthService.openDialog(this.message);
      setTimeout(() => {
        window.location.assign('https://mail.google.com/');
      }, 3000);
    });
  }
}
