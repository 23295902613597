/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./email-confirm.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./email-confirm.component";
import * as i5 from "../../services/user-auth.service";
var styles_EmailConfirmComponent = [i0.styles];
var RenderType_EmailConfirmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmailConfirmComponent, data: {} });
export { RenderType_EmailConfirmComponent as RenderType_EmailConfirmComponent };
function View_EmailConfirmComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "button2"], ["style", "margin: auto;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵted(-1, null, [" Login "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, "/logInClient"); _ck(_v, 1, 0, currVal_0); }, null); }
function View_EmailConfirmComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "button2"], ["style", "margin: auto;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵted(-1, null, ["\u062A\u0633\u062C\u064A\u0644 \u0627\u0644\u062F\u062E\u0648\u0644"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, "/logInClient"); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EmailConfirmComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "page-wrapper p-t-180 p-b-100 font-robo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "p", [["style", "text-align: center;"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmailConfirmComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmailConfirmComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.direction == "ltr"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.direction == "rtl"); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.answer.message_ar; _ck(_v, 1, 0, currVal_0); }); }
export function View_EmailConfirmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-email-confirm", [], null, null, null, View_EmailConfirmComponent_0, RenderType_EmailConfirmComponent)), i1.ɵdid(1, 114688, null, 0, i4.EmailConfirmComponent, [i2.ActivatedRoute, i5.UserAuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailConfirmComponentNgFactory = i1.ɵccf("app-email-confirm", i4.EmailConfirmComponent, View_EmailConfirmComponent_Host_0, {}, {}, []);
export { EmailConfirmComponentNgFactory as EmailConfirmComponentNgFactory };
