import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({name: "safehtml"})
export class safehtmlpipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer){
    }
  transform(value){
    let font: String = value;
    if(font && font.length > 0 && font.includes("</font>")){
      font = font.substring(font.indexOf("<font"), font.length);
      font = font.substring(0, font.indexOf(">")+1);
      return value.replace(font, "").replace("</font>", "");
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
