import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ServerApi } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PublicService {
  constructor(private http: HttpClient) { }

  getHome(): Observable<any> {
    return this.http.get(`${ServerApi.PublicRouteTemp}/home`);
  }

  contactUsDownload(obj): Observable<any> {
    return this.http.post(`${ServerApi.PublicRouteTemp}/book`, obj,{responseType: 'blob'});
  }

  downloadBook(obj) {
    return this.http.post(`${ServerApi.PublicRouteTemp}/add-pending-user`, obj);
  }
  contactUsForm(obj): Observable<any> {
    return this.http.post(`${ServerApi.PublicRouteTemp}/contacts`, obj);
  }

  downloadNumber(): Observable<any> {
    return this.http.get(`${ServerApi.PublicRouteTemp}/book`);
  }

  convertCurrency(obj): Observable<any> {
    return this.http.post(`${ServerApi.PublicRouteTemp}/currency/convert`, obj);
  }

}
