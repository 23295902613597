<!-- <a href=''>
    <section class="section">
        <div class="container">
            <div class="row mt-15">

                <div class="text-center widthset">
                    <div class="position-relative rounded-top progress-wrapper" style=" background-image: linear-gradient(to bottom, #f8e739, #f1d42d, #eac222, #e1b019, #d89e10);">
                        <div class="wave" style="bottom: 80%;">
                            <h1 class="heading-style">C</h1>
                        </div>
                    </div>


                </div>

                <div class="text-center widthset">
                    <div class="position-relative rounded-top progress-wrapper" style="background-image: linear-gradient(to bottom, #d89e10, #e28f00, #ec7f00, #f66b00, #ff5200);">
                        <div class="wave" style="bottom: 80%;">
                            <h1 class="heading-style">o</h1>
                        </div>
                    </div>


                </div>

                <div class="text-center widthset">
                    <div class="position-relative rounded-top progress-wrapper" style="background-image: linear-gradient(to bottom, #e2130b, #e1110c, #e00f0d, #df0c0d, #de090e);">
                        <div class="wave" style="bottom: 80%;">
                            <h1 class="heading-style">m</h1>
                        </div>
                    </div>


                </div>

                <div class="text-center widthset">
                    <div class="position-relative rounded-top progress-wrapper" style="background-image: linear-gradient(to bottom, #ce0061, #d50079, #d60094, #d000b3, #c115d4);">
                        <div class="wave" style="bottom: 80%;">
                            <h1 class="heading-style">i</h1>
                        </div>
                    </div>


                </div>

                <div class="text-center widthset">
                    <div class="position-relative rounded-top progress-wrapper" style="background-image: linear-gradient(to bottom, #6a0ddc, #5f0ddd, #530edd, #450fde, #3210de);">
                        <div class="wave" style="bottom: 80%;">
                            <h1 class="heading-style">n</h1>
                        </div>
                    </div>


                </div>


                <div class="text-center widthset">
                    <div class="position-relative rounded-top progress-wrapper" style="background-image: linear-gradient(to bottom, #0c2fd4, #0045d8, #0056d9, #0065d8, #1b73d5);">
                        <div class="wave" style="bottom: 80%;">
                            <h1 class="heading-style">g</h1>
                        </div>
                    </div>


                </div>


                <div class="text-center widthset">
                    <div class="position-relative rounded-top progress-wrapper" style=" background-image: linear-gradient(to bottom, #f8e739, #f1d42d, #eac222, #e1b019, #d89e10);">
                        <div class="wave" style="bottom: 80%;">
                            <h1 class="heading-style" style="color:#fff;border-color:#fff">.</h1>
                        </div>
                    </div>


                </div>
                <div class="text-center widthset">
                    <div class="position-relative rounded-top progress-wrapper" style=" background-image: linear-gradient(to bottom, #d89e10, #e28f00, #ec7f00, #f66b00, #ff5200);">
                        <div class="wave" style="bottom: 80%;">
                            <h1 class="heading-style">S</h1>
                        </div>
                    </div>


                </div>


                <div class="text-center widthset">
                    <div class="position-relative rounded-top progress-wrapper" style=" background-image: linear-gradient(to bottom, #e2130b, #e1110c, #e00f0d, #df0c0d, #de090e);">
                        <div class="wave" style="bottom: 80%;">
                            <h1 class="heading-style">o</h1>
                        </div>
                    </div>


                </div>


                <div class="text-center widthset">
                    <div class="position-relative rounded-top progress-wrapper" style=" background-image: linear-gradient(to bottom, #6a0ddc, #5f0ddd, #530edd, #450fde, #3210de);">
                        <div class="wave" style="bottom: 80%;">
                            <h1 class="heading-style">o</h1>
                        </div>
                    </div>


                </div>


                <div class="text-center widthset">
                    <div class="position-relative rounded-top progress-wrapper" style=" background-image: linear-gradient(to bottom, #0c2fd4, #0045d8, #0056d9, #0065d8, #1b73d5);">
                        <div class="wave" style="bottom: 80%;">
                            <h1 class="heading-style">n</h1>
                        </div>
                    </div>


                </div>

                <div class="text-center widthset">
                    <div class="position-relative rounded-top progress-wrapper" style=" background-image: linear-gradient(to bottom, #f8e739, #f1d42d, #eac222, #e1b019, #d89e10);">
                        <div class="wave" style="bottom: 80%;">
                            <h1 class="heading-style" style="color:#fff;border-color:#fff">.</h1>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </section>
</a> -->

<p style="text-align: center;margin: 5rem auto;font-size: 30px;" *ngIf="direction=='rtl'">قريبا...</p>
<p style="text-align: center;margin: 5rem auto;font-size: 30px;" *ngIf="direction=='ltr'">Coming Soon...</p>