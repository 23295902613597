/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toast-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/common";
import * as i9 from "./toast-dialog.component";
import * as i10 from "../../services/user-auth.service";
var styles_ToastDialogComponent = [i0.styles];
var RenderType_ToastDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastDialogComponent, data: {} });
export { RenderType_ToastDialogComponent as RenderType_ToastDialogComponent };
function View_ToastDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "mx-auto w-100"], ["mat-button", ""], ["mat-dialog-close", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).dialogRef.close(i1.ɵnov(_v, 2).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.resendTheActivationCode() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 606208, null, 0, i6.MatDialogClose, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, [" \u0625\u0639\u0627\u062F\u0629 \u0625\u0631\u0633\u0627\u0644 \u0627\u0644\u0628\u0631\u064A\u062F \u0627\u0644\u0625\u0644\u0643\u062A\u0631\u0648\u0646\u064A "]))], function (_ck, _v) { var currVal_4 = ""; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 2).ariaLabel || null); var currVal_3 = i1.ɵnov(_v, 2).type; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_ToastDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "text-center p-5"], ["dir", "rtl"]], [[1, "dir", 0]], null, null, null, null)), i1.ɵprd(6144, null, i7.Directionality, null, [i7.Dir]), i1.ɵdid(2, 1196032, null, 0, i7.Dir, [], { dir: [0, "dir"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [["class", "txtMainColor smLineHeight"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i6.MatDialogActions, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastDialogComponent_1)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["class", "mx-auto w-100"], ["mat-button", ""], ["mat-dialog-close", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).dialogRef.close(i1.ɵnov(_v, 11).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(11, 606208, null, 0, i6.MatDialogClose, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["\u0627\u063A\u0644\u0627\u0642"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "rtl"; _ck(_v, 2, 0, currVal_1); var currVal_3 = _co.isActivateAccount; _ck(_v, 8, 0, currVal_3); var currVal_8 = ""; _ck(_v, 11, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2)._rawDir; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.message; _ck(_v, 4, 0, currVal_2); var currVal_4 = (i1.ɵnov(_v, 10).disabled || null); var currVal_5 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); var currVal_6 = (i1.ɵnov(_v, 11).ariaLabel || null); var currVal_7 = i1.ɵnov(_v, 11).type; _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_ToastDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toast-dialog", [], null, null, null, View_ToastDialogComponent_0, RenderType_ToastDialogComponent)), i1.ɵdid(1, 114688, null, 0, i9.ToastDialogComponent, [i6.MAT_DIALOG_DATA, i10.UserAuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToastDialogComponentNgFactory = i1.ɵccf("app-toast-dialog", i9.ToastDialogComponent, View_ToastDialogComponent_Host_0, {}, {}, []);
export { ToastDialogComponentNgFactory as ToastDialogComponentNgFactory };
