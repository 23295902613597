<div class="container text-right py-5">
    <h1 class="text-center">الشروط و الاحكام</h1>
    <p class="lightFont">مرحبًا بك في <a href="www.thebravespirit.org">thebravespirit.org</a></p>
    <p class="lightFont">يرجى قراءة شروط الاستخدام هذه بعناية قبل استخدام الموقع.</p>
    <p class="lightFont">باستخدامك لهذا الموقع ، فهذا يعني موافقتك على شروط الاستخدام هذه.</p>
    <p class="lightFont">إذا كنت لا توافق على شروط الاستخدام هذه ، فلا يجوز لك استخدام الموقع.</p>
    <ul>
        <li>
            <p class="lightFont">تشكل اتفاقية شروط وأحكام الاستخدام هذه اتفاقية ملزمة بينك وبين <a href="www.thebravespirit.org">thebravespirit.org</a> كما لو كانت شروط الاستخدام هذه
                عبارة عن عقد تم إبرامه بالكامل وتم تنفيذه بالكامل بين الطرفين.</p>
        </li>
        <li>
            <p class="lightFont">يجوز لنا تغيير شروط الاستخدام هذه من وقت لآخر دون إشعارك ، وسيتم نشر أحدث إصدار على هذا الموقع.</p>
        </li>
    </ul>


    <h1>خصوصية حسابك:</h1>
    <p class="lightFont">خصوصيتك مهمة جدا بالنسبة لنا. ونحن ملتزمون بحماية خصوصيتك.</p>
    <p class="lightFont">يرجى مراجعة سياسة الخصوصية الخاصة بنا ، والتي تحكم أيضًا في استخدامك لهذا الموقع، لفهم أحكام الخصوصية لدينا.</p>
    <p class="lightFont">كل الدورات والمواد على موقعنا برايف سبيريت هي موجهة للبالغين الذين يمكنهم الشراء باستخدام بطاقة ائتمان أو أي طريقة
        دفع أخرى مسموح بها.</p>
    <p class="lightFont">إذا كنت ترغب في استخدام خدماتنا، يجب أن تقرأ وتوافق على الالتزام بسياساتنا المتعلقة بهذه الخدمات. على وجه الخصوص،</p>
    <ul>
        <li>
            <p class="lightFont">إذا كان عمرك أقل من 18 عامًا ، فلا يجوز لك استخدام الموقع إلا بمشاركة أحد الوالدين أو الوصي.</p>
        </li>
        <li>
            <p class="lightFont">نحتفظ بالحق في رفض الخدمة أو إنهاء الحسابات أو إزالة المحتوى أو تحريره أو إلغاء الطلبات وفقًا لتقديرنا الخاص.</p>
        </li>
        <li>
            <p class="lightFont">نحتفظ بالحق في إلغاء تسجيل أي عضو مشكوك في أمره.</p>
        </li>
    </ul>

    <h1>المحتوى:</h1>
    <ul>
        <li><p class="lightFont">المواد الواردة في هذا الموقع محمية بقوانين حقوق النشر والعلامات التجارية المعمول بها.</p></li>
        <li><p class="lightFont">يتم تقديم المواد على هذا الموقع لأغراض تعليمية فقط.</p></li>
        <li><p class="lightFont">أي تطبيق للمواد على هذا الموقع هو على مسؤوليتك الخاصة. أنت تتحمل المسؤولية الكاملة وتوافق ضمنيًا على أنك درست المادة وبحثت فيها قبل التطبيق وتشاورت مع أخصائي في هذا المجال.</p></li>
        <li><p class="lightFont">موقع بريف سبيرت ليس مسؤول عمَا إذا كانت المعلومات المتوفرة على هذا الموقع غير دقيقة أو كاملة أو خالية من الاخطاء.</p></li>
        <li><p class="lightFont">لا يمكننا التأكد من أنك سوف تكون راضيًا عن أي منتج أو خدمة أو محتوى تشتريه من موقعنا. اذا لم تكن راضي في النهاية يمكنك طلب استعادة المبلغ و سنعيد لك مدفوعاتك بدون أي اسئلة.</p></li>
        <li><p class="lightFont">بعد الشراء يتم منح الإذن للعرض والتعلم من المواد ، وبموجب هذه الاتفاقية ، لا يجوز لك تعديل أو نسخ أو مشاركة أي جزء  أو كل المواد المتاحة على الموقع.</p></li>
        <li><p class="lightFont">بريف سبيريت يحتفظ بالحق بتعديل أو إيقاف المحتوى (أو أي جزء أو محتوى منه) في أي وقت دون أية إشعار.</p></li>
        <li><p class="lightFont">الموقع لا يتحمل المسؤولية تجاهك أو تجاه أي طرف ثالث عن أي تعديل أو تغيير في الأسعار أو وقف أو تعليق المواد المقدمة.</p></li>
    </ul>

    <h1>اسعار الدورات :</h1>
    <ul>
        <li><p class="lightFont">الأسعار لمنتجاتنا والدورات قابلة للتغيير دون إشعار.</p></li>
        <li><p class="lightFont">الدورات المدفوعة ليست مجانية.</p></li>
        <li><p class="lightFont">اشتراكك في الدورات المدفوعة و دفع مستحقاتها سيساعد الفقراء و الطلبة و ذوي المدخول الزهيد على الاشتراك بالمجان و سيساعدنا على انتاج المزيد من الدورات .. و لهذا فنحن نثق في أمانتك و في انك ستعطي كل ذي حق حقه بدون ان تأكل ماله بالباطل.</p></li>
        <li><p class="lightFont">يمكن للمستخدم ان يشترك في الدورات المدفوعة بشكل مجاني في حالة واحدة فقط و هي عندما يعجز عن الدفع بسبب ظروفه المالية السيئة او بسبب الدخل الزهيد و الفقر .. حينها فقط يمكن للمستخدم طلب الاشتراك المجاني مع توضيح سبب العجز عن الدفع.</p></li>
        <li><p class="lightFont">يحق لنا رفض طلب الاعانة المالية او الاشتراك المجاني اذا وجدنا بان اسباب طلب الاشتراك المجاني غير مقنعة.</p></li>
        <li><p class="lightFont">لا يحق للمستخدمين الاشتراك بالمجان في حالة عدم وجود طريقة للدفع .. اذا لم لديك بطاقة دفع الكتروني او طريقة دفع مناسبة في دولتك يمكنك التواصل مع فريق الدعم في الموقع حتى نتفق على طرق الدفع البديلة.</p></li>
    </ul>


    <h1>توافر الموقع :</h1>
    <ul>
        <li><p class="lightFont">انت تقر بانه من المستحيل تقنيًا ان يكون الموقع متاحا بدون انقطاع بشكل مؤقت طول الوقت و ان تشغيل الموقع قد يتأثر سلبا بسبب الظروف الخارجة عن سيطرتنا مثل و على سبيل المثال وصلات الارسال ، و خدمة استضافة الموقع ، و اوقات الصيانة.</p></li>
        <li><p class="lightFont">يجوز لنا تعديل او الغاء كل او جزء من المحتوى الموجود في الموقع في اي وقت دون ان نكون ملزمين بإعلامك بهذه التغيرات. لا نقدم اي ضمانات او التزامات بشأن تحديث المحتوى على الموقع.</p></li>
        <li><p class="lightFont">بريف سبيرت غير مسؤول عن اي ضرر ينتج عن استخدام الموقع او عدم القدرة على الوصول إلى الموقع. كما أننا لا نضمن عدم حدوث أخطاء أو توقف في الموقع أو الخدمات المقدمة من خلاله.</p></li>
    </ul>

    <h1>سياسة الاسترجاع:</h1>
    <p class="lightFont">إذا وجدت بأن الدورة التي اشتركت فيها لا تستحق مالك ووقتك، يمكنك طلب استرداد المبلغ خلال مدة 21 يومًا بشرط واحد:</p>
    <ol>
        <li><p class="lightFont">أن تكون قد شاهدت جميع ملفات الدورة بالكامل.</p></li>
    </ol>
    <p class="lightFont">بالنسبة لجميع المطالبات المتعلقة برد المبالغ المدفوعة، ما عليك سوى الاتصال بفريق الدعم لدينا.</p>

    <h1>تعليمات الاستخدام:</h1>
    <ul>
        <li><p class="lightFont">أنت توافق على تقديم معلومات الشراء والحساب الحالية والكاملة والدقيقة لجميع مشترياتك التي تتم في متجرنا. أنت توافق على تحديث حسابك أو أية معلومات أخرى على الفور، بما في ذلك عنوان بريدك الإلكتروني وأرقام بطاقات الائتمان وتواريخ انتهاء الصلاحية، حتى نتمكن من إكمال معاملاتك والاتصال بك حسب الحاجة.</p></li>
        <li><p class="lightFont">أنت توافق على احترام قوانين حقوق النشر.</p></li>
        <li><p class="lightFont">نحن لسنا ولن نكون ملزمين بالحفاظ على سرية أي تعليقات؛ لدفع تعويض عن أي تعليقات؛ أو الرد على أي تعليقات.</p></li>
        <li><p class="lightFont">لا يجوز لك تغيير أو تعديل أو حذف أو عرض أو نقل أو تكييف أو استغلال أو نسخ للتوزيع أو البيع أي معلومات أو مواد أو استخدام العلامة التجارية أو حقوق النشر على المواقع. يجب أن تحصل على إذن كتابي منا أو من أي كيان آخر يمتلك الملكية الفكرية على الموقع قبل أن تتمكن من نشر أو توزيع أو عرض أو استغلال أي مادة تجاريًا من الموقع، وإذا لم تحصل على إذن كتابي منا، فستكون مسؤولًا عن أضرار جسيمة تلحقها بنا.</p></li>
        <li><p class="lightFont">نحتفظ بالحق في إلغاء الوصول إلى أي من دوراتنا دون استرجاع المستحقات المدفوعة إذا كان العضو المعني لا يحترم أي عضو في فريقنا أو الأعضاء المستخدمين في موقع "BRAVE SPIRIT" وهذا يشمل، على سبيل المثال لا الحصر، السب، الاعتداء اللفظي والمضايقة والسلوك غير المحترم و/أو إرسال الرسائل غير الملائمة. نحث جميع الأعضاء على معاملة الآخرين بالاحترام والرقي.</p></li>
        <li><p class="lightFont">لن يُسمح لأي عضو تم حظره بالمشاركة في الدورات التدريبية مستقبلاً.</p></li>
        <li><p class="lightFont">أي انتهاك أو خرق للشروط سيؤدي إلى إنهاء خدماتك.</p></li>
    </ul>


    <h1>قيود الاستخدام، ترخيص محدود</h1>
    <ul>
        <li><p class="lightFont">إن أي علامات تجارية وعلامات خدمة ورسومات وشعارات ورؤوس صفحات وأيقونات ونصوص وأسماء تجارية (كل منها "علامة") موجودة على الموقع الإلكتروني هي ملكية لنا أو لمرخصينا أو المرخص لهم. لا يجوز استخدام علاماتنا فيما يتعلق بأي منتج أو خدمة ليست لنا بأي شكل من الأشكال التي من المحتمل أن تسبب ارتباكًا للمستخدمين أو التي قد تسيء إلينا أو تقلل من شأننا أو أي شخص آخر. جميع العلامات التجارية الأخرى التي لا نملكها والتي تظهر على الموقع الإلكتروني هي ملك لأصحابها ، الذين قد يكونون تابعين لنا أو غير مرتبطين بنا أو مرتبطين بنا.</p></li>
        <li><p class="lightFont">نحن نمنحك ترخيصًا محدودًا للوصول إلى الموقع واستخدامه الشخصي. ولا يجوز لك نسخ أو إعادة إنتاج أي محتوى أو نقله أو توزيعه بأي شكل من الأشكال، أو بيعه أو استغلاله لأي غرض تجاري. باستثناء أنه يمكنك تنزيل نسخة واحدة (1) من المحتوى الذي نوفره لك لهذه الأغراض على جهاز كمبيوتر واحد لاستخدامك الشخصي غير التجاري للاستخدام الشخصي فقط، شريطة أن:</p></li>
            <ol>
                <li class="lightFont">تحافظ على جميع إشعارات حقوق النشر والعلامات التجارية وحقوق الملكية الأخرى سليمة.</li>
                <li class="lightFont">لا تقم بأي تعديل على المحتوى.</li>
                <li class="lightFont">لا تستخدم أي محتوى خاص بك بطريقة توحي بالارتباط مع منتج من منتجاتنا أو خدماتنا أو علاماتنا التجارية.</li>
                <li class="lightFont">يُحظر تمامًا استخدامك لمحتوى موقعنا (thebravespirit.org) على أي موقع ويب أو بيئة كمبيوتر أخرى بدون الحصول على إذن كتابي منا.</li>
            </ol>
        <li><p class="lightFont">لا يشمل الترخيص الممنوح لك، ويستبعد على وجه التحديد، أي حقوق في: إعادة بيع أو استخدام أي استخدام تجاري للموقع أو أي محتوى؛ جمع واستخدام أي قوائم منتجات أو أوصاف أو أسعار؛ أي استخدام مشتق للموقع أو المحتوى؛ تنزيل أو نسخ معلومات الحساب لصالح أي شخص آخر؛ أو استخدام أي شكل من أشكال استخراج البيانات أو الروبوتات أو أدوات جمع البيانات واستخراجها.</p></li>
    </ul>

    <h1>استخدام وحماية كلمة المرور والهوية</h1>
    <ul>
        <li>
            <p class="lightFont">كل مشترك بالموقع لديه معرف وكلمة مرور خاصة به، حتى يتمكن من الوصول إلى مناطق معينة من الموقع واستخدامها. يُعتبر كل مستخدم يستخدم كلمة المرور والمعرف الخاصين به مصرحًا له بالوصول إلى محتويات الموقع واستخدامه. أنت تقر وتوافق على أنك ستكون مسؤولًا وحدك عن كل وصول إلى الموقع واستخدامه من قبل شخص آخر استخدم كلمة المرور والمعرف الخاصين بك، بما في ذلك جميع الاتصالات وجميع الالتزامات بما فيها الالتزامات المالية لعمليات الشراء عبر الموقع الإلكتروني.</p>
        </li>
        <li>
            <p class="lightFont">أنت وحدك المسؤول عن حماية أمان وسرية كلمة المرور والمعرف الخاصين بك. يجب عليك على الفور إخطار فريق الدعم الفني في حال الاشتباه بأي استخدام غير مصرح به لكلمة المرور أو المعرّف الخاصين بك، أو أي خرق آخر أو تهديد خرق لأمان الموقع الإلكتروني الذي تعرفه. ستكون مسؤولًا عن أي نشاط يتم إجراؤه باستخدام كلمة المرور أو المعرّف المخصص لك.</p>
        </li>
    </ul>


    <h1>إخلاء المسؤولية عن الضمانات:</h1>
    <ul>
        <li><p class="lightFont">محمد نجمو هو معالج حاصل على تدريب رسمي في منهج EMDR ولكنه ليس طبيب نفسي وليس معالجًا مرخصًا بعد ولا THE BRAVE SPIRIT كيانًا مرخصًا لتقديم الخدمات العلاجية الطبية بأي شكل من الأشكال، لا يُقصد من منتجاتنا وخدماتنا استخدامها كنصيحة طبية أو كبديل للنصيحة الطبية المهنية أو التشخيص أو العلاج الفعلي مع مختص على أرض الواقع ولا ينبغي استخدامها على هذا النحو.</p></li>
        <li><p class="lightFont">يتم تقديم المعلومات المتعلقة بالاضطرابات النفسية والمشاكل الأخرى في هذا الموقع بهدف التوعية وتوفير الدعم النفسي للمصابين والبدء في عملية التشافي الذاتي، هذا يعني بأنك من خلال الاستماع إلى النصائح والمعلومات الموجودة، فإنك تتحمل مسؤولية حياتك وصحتك النفسية والعقلية بنسبة 100٪ وتتحمل مسؤولية عواقب تطبيقك للمادة العلمية الموجودة في الموقع على حالتك ولا يتحمل موقع thebravespirit.org ولا أي من موظفيه ولا محمد نجمو المسؤولية بأي شكل من الأشكال عن النتائج التي تحصل عليها من خلال اتباعك لهذه المعلومات!</p></li>
        <li><p class="lightFont">إذا كان لديك مخاوف معينة أو حالة نفسية تتطلب منك المشورة المهنية أو الطبية، فينبغي استشارة طبيب متخصص ومؤهل بشكل مناسب، مثل طبيب نفساني مرخص أو معالج مرخص.</p></li>
        <li><p class="lightFont">أنت تقر بأنك قد قرأت بعناية "هذا التنازل" وأنك تفهم تمامًا أن هذا هو سبب المسؤولية. أنت توافق صراحةً على التنازل عن جميع الدعاوى أو أسباب الدعوى، وتوافق على الاستسلام والتنازل نهائيًا عن أية حقوق قد تلزمها في أي وقت الطرف المعوض عن الإصابة الشخصية أو أضرار الممتلكات.</p></li>
        <li><p class="lightFont">نحن لا نضمن بأن المواد لن يتم قطعها أو خلوها من الأخطاء، أو أنه سيتم تصحيح العيوب، كما لا نضمن أن موقعنا الإلكتروني أو الخوادم التي نستخدمها ستكون دائماً خالية من الفيروسات أو المكونات الضارة الأخرى. لذلك، فنحن لا نتحمل تكاليف إصلاح أو تصحيح أي من معداتك أو برامجك في حال تعرضها للضرر. نحن لا نقدم أي إقرارات أو ضمانات فيما يتعلق بالاستخدام، أو نتائج الاستخدام، لأي محتوى أو منتج أو خدمة معروضة على موقعنا.</p></li>
    </ul>





    <h1>الاتصالات الإلكترونية:</h1>
    <ul>
        <li>
            <p class="lightFont">عندما تقوم بزيارة موقعنا THE BRAVE SPIRIT، أو إرسال رسائل بريد إلكتروني إلينا، أو التواصل مع وكلائنا، فأنت تتواصل معنا إلكترونيًا. أنت توافق على تلقي الاتصالات منا إلكترونيًا. سوف نتواصل معك عن طريق البريد الإلكتروني أو عن طريق نشر الإشعارات على الموقع. أنت توافق على استلامك رسائل من موقعنا سواء على بريدك الإلكتروني أو على رقم هاتفك أو على الواتساب الخاص بك.</p>
        </li>
    </ul>
    <p class="text-center">جميع الحقوق محفوظة <a href="https://thebravespirit.org">THE BRAVE SPIRIT ©</a></p>

</div>
