/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./whatsapp-icon.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./whatsapp-icon.component";
var styles_WhatsappIconComponent = [i0.styles];
var RenderType_WhatsappIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WhatsappIconComponent, data: {} });
export { RenderType_WhatsappIconComponent as RenderType_WhatsappIconComponent };
export function View_WhatsappIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "d-md-none"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "i", [["class", "fab fa-whatsapp whatsapp-icon"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["class", "d-none d-md-inline"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "i", [["class", "fab fa-whatsapp whatsapp-icon"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.iconStyle; _ck(_v, 3, 0, currVal_1); var currVal_3 = _co.iconStyle; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("https://wa.me/" + _co.contactNumber); _ck(_v, 0, 0, currVal_0); var currVal_2 = ("https://web.whatsapp.com/send?phone=" + _co.contactNumber); _ck(_v, 4, 0, currVal_2); }); }
export function View_WhatsappIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-whatsapp-icon", [], null, null, null, View_WhatsappIconComponent_0, RenderType_WhatsappIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.WhatsappIconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WhatsappIconComponentNgFactory = i1.ɵccf("app-whatsapp-icon", i3.WhatsappIconComponent, View_WhatsappIconComponent_Host_0, { iconStyle: "iconStyle" }, {}, []);
export { WhatsappIconComponentNgFactory as WhatsappIconComponentNgFactory };
