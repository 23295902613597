import {Component, Input, OnInit} from '@angular/core';
import {StripeService} from "../../services/payments/stripe.service";

@Component({
  selector: 'app-stripe-checkout',
  templateUrl: './stripe-checkout.component.html',
  styleUrls: ['./stripe-checkout.component.css']
})
export class StripeCheckoutComponent implements OnInit {
  stripe: any;
  elements: any;
  items = [{ id: "xl-tshirt" }];
  @Input() courseId: string;

  @Input() amount: number;

  constructor(
      private _stripeService: StripeService,
  ) {
    this._stripeService.loadStripe();
  }

  ngOnInit() {
    if(this.amount && this.courseId) {
      this._stripeService.initialize(this.amount, this.courseId).then(() => {
        this._stripeService.checkStatus(); // copy this to component.
      });
    }
  }


  handleSubmit(e: Event) {
    this._stripeService.handleSubmit(e);
  }

}
