/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./blog.component";
import * as i4 from "../../services/user-auth.service";
var styles_BlogComponent = [i0.styles];
var RenderType_BlogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlogComponent, data: {} });
export { RenderType_BlogComponent as RenderType_BlogComponent };
function View_BlogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["style", "text-align: center;margin: 5rem auto;font-size: 30px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0642\u0631\u064A\u0628\u0627..."]))], null, null); }
function View_BlogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["style", "text-align: center;margin: 5rem auto;font-size: 30px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Coming Soon..."]))], null, null); }
export function View_BlogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlogComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlogComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.direction == "rtl"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.direction == "ltr"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_BlogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-blog", [], null, null, null, View_BlogComponent_0, RenderType_BlogComponent)), i1.ɵdid(1, 114688, null, 0, i3.BlogComponent, [i4.UserAuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BlogComponentNgFactory = i1.ɵccf("app-blog", i3.BlogComponent, View_BlogComponent_Host_0, {}, {}, []);
export { BlogComponentNgFactory as BlogComponentNgFactory };
