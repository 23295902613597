import * as i0 from "@angular/core";
var CountriesService = /** @class */ (function () {
    function CountriesService() {
        // All arabic countries
        this.ar_countries = [
            {
                "country_code": "AF",
                "country_name": "أفغانستان",
                "phone_code": 93,
                "id": 1
            }, {
                "country_code": "AX",
                "country_name": "جزر آلاند",
                "phone_code": 358,
                "id": 2
            }, {
                "country_code": "AL",
                "country_name": "ألبانيا",
                "phone_code": 355,
                "id": 3
            }, {
                "country_code": "DZ",
                "country_name": "الجزائر",
                "phone_code": 213,
                "id": 4
            }, {
                "country_code": "AS",
                "country_name": "ساموا الأمريكية",
                "phone_code": 1684,
                "id": 5
            }, {
                "country_code": "AD",
                "country_name": "أندورا",
                "phone_code": 376,
                "id": 6
            }, {
                "country_code": "AO",
                "country_name": "أنغولا",
                "phone_code": 244,
                "id": 7
            }, {
                "country_code": "AI",
                "country_name": "أنغيلا",
                "phone_code": 1264,
                "id": 8
            }, {
                "country_code": "AQ",
                "country_name": "أنتاركتيكا",
                "phone_code": 672,
                "id": 9
            }, {
                "country_code": "AG",
                "country_name": "أنتيغوا وبربودا",
                "phone_code": 1268,
                "id": 10
            }, {
                "country_code": "AR",
                "country_name": "الأرجنتين",
                "phone_code": 54,
                "id": 11
            }, {
                "country_code": "AM",
                "country_name": "أرمينيا",
                "phone_code": 374,
                "id": 12
            }, {
                "country_code": "AW",
                "country_name": "أروبا",
                "phone_code": 297,
                "id": 13
            }, {
                "country_code": "AU",
                "country_name": "أستراليا",
                "phone_code": 61,
                "id": 14
            }, {
                "country_code": "AT",
                "country_name": "النمسا",
                "phone_code": 43,
                "id": 15
            }, {
                "country_code": "AZ",
                "country_name": "أذربيجان",
                "phone_code": 994,
                "id": 16
            }, {
                "country_code": "BS",
                "country_name": "جزر البهاما",
                "phone_code": 1242,
                "id": 17
            }, {
                "country_code": "BH",
                "country_name": "البحرين",
                "phone_code": 973,
                "id": 18
            }, {
                "country_code": "BD",
                "country_name": "بنغلاديش",
                "phone_code": 880,
                "id": 19
            }, {
                "country_code": "BB",
                "country_name": "بربادوس",
                "phone_code": 1246,
                "id": 20
            }, {
                "country_code": "BY",
                "country_name": "بيلاروسيا",
                "phone_code": 375,
                "id": 21
            }, {
                "country_code": "BE",
                "country_name": "بلجيكا",
                "phone_code": 32,
                "id": 22
            }, {
                "country_code": "BZ",
                "country_name": "بليز",
                "phone_code": 501,
                "id": 23
            }, {
                "country_code": "BJ",
                "country_name": "بنين",
                "phone_code": 229,
                "id": 24
            }, {
                "country_code": "BM",
                "country_name": "برمودا",
                "phone_code": 1441,
                "id": 25
            }, {
                "country_code": "BT",
                "country_name": "بوتان",
                "phone_code": 975,
                "id": 26
            }, {
                "country_code": "BO",
                "country_name": "بوليفيا",
                "phone_code": 591,
                "id": 27
            }, {
                "country_code": "BQ",
                "country_name": "بونير وسانت يوستاتيوس وسابا",
                "phone_code": 599,
                "id": 28
            }, {
                "country_code": "BA",
                "country_name": "البوسنة والهرسك",
                "phone_code": 387,
                "id": 29
            }, {
                "country_code": "BW",
                "country_name": "بوتسوانا",
                "phone_code": 267,
                "id": 30
            }, {
                "country_code": "BV",
                "country_name": "جزيرة بوفيت",
                "phone_code": 55,
                "id": 31
            }, {
                "country_code": "BR",
                "country_name": "البرازيل",
                "phone_code": 55,
                "id": 32
            }, {
                "country_code": "IO",
                "country_name": "إقليم المحيط البريطاني الهندي",
                "phone_code": 246,
                "id": 33
            }, {
                "country_code": "BN",
                "country_name": "بروناي دار السلام",
                "phone_code": 673,
                "id": 34
            }, {
                "country_code": "BG",
                "country_name": "بلغاريا",
                "phone_code": 359,
                "id": 35
            }, {
                "country_code": "BF",
                "country_name": "بوركينا فاسو",
                "phone_code": 226,
                "id": 36
            }, {
                "country_code": "BI",
                "country_name": "بوروندي",
                "phone_code": 257,
                "id": 37
            }, {
                "country_code": "KH",
                "country_name": "كمبوديا",
                "phone_code": 855,
                "id": 38
            }, {
                "country_code": "CM",
                "country_name": "الكاميرون",
                "phone_code": 237,
                "id": 39
            }, {
                "country_code": "CA",
                "country_name": "كندا",
                "phone_code": 1,
                "id": 40
            }, {
                "country_code": "CV",
                "country_name": "الرأس الأخضر",
                "phone_code": 238,
                "id": 41
            }, {
                "country_code": "KY",
                "country_name": "جزر كايمان",
                "phone_code": 1345,
                "id": 42
            }, {
                "country_code": "CF",
                "country_name": "جمهورية افريقيا الوسطى",
                "phone_code": 236,
                "id": 43
            }, {
                "country_code": "TD",
                "country_name": "تشاد",
                "phone_code": 235,
                "id": 44
            }, {
                "country_code": "CL",
                "country_name": "تشيلي",
                "phone_code": 56,
                "id": 45
            }, {
                "country_code": "CN",
                "country_name": "الصين",
                "phone_code": 86,
                "id": 46
            }, {
                "country_code": "CX",
                "country_name": "جزيرة الكريسماس",
                "phone_code": 61,
                "id": 47
            }, {
                "country_code": "CC",
                "country_name": "جزر كوكوس (كيلينغ)",
                "phone_code": 672,
                "id": 48
            }, {
                "country_code": "CO",
                "country_name": "كولومبيا",
                "phone_code": 57,
                "id": 49
            }, {
                "country_code": "KM",
                "country_name": "جزر القمر",
                "phone_code": 269,
                "id": 50
            }, {
                "country_code": "CG",
                "country_name": "الكونغو",
                "phone_code": 242,
                "id": 51
            }, {
                "country_code": "CD",
                "country_name": "الكونغو ، جمهورية الكونغو الديمقراطية",
                "phone_code": 242,
                "id": 52
            }, {
                "country_code": "CK",
                "country_name": "جزر كوك",
                "phone_code": 682,
                "id": 53
            }, {
                "country_code": "CR",
                "country_name": "كوستا ريكا",
                "phone_code": 506,
                "id": 54
            }, {
                "country_code": "CI",
                "country_name": "ساحل العاج",
                "phone_code": 225,
                "id": 55
            }, {
                "country_code": "HR",
                "country_name": "كرواتيا",
                "phone_code": 385,
                "id": 56
            }, {
                "country_code": "CU",
                "country_name": "كوبا",
                "phone_code": 53,
                "id": 57
            }, {
                "country_code": "CW",
                "country_name": "كوراكاو",
                "phone_code": 599,
                "id": 58
            }, {
                "country_code": "CY",
                "country_name": "قبرص",
                "phone_code": 357,
                "id": 59
            }, {
                "country_code": "CZ",
                "country_name": "الجمهورية التشيكية",
                "phone_code": 420,
                "id": 60
            }, {
                "country_code": "DK",
                "country_name": "الدنمارك",
                "phone_code": 45,
                "id": 61
            }, {
                "country_code": "DJ",
                "country_name": "جيبوتي",
                "phone_code": 253,
                "id": 62
            }, {
                "country_code": "DM",
                "country_name": "دومينيكا",
                "phone_code": 1767,
                "id": 63
            }, {
                "country_code": "DO",
                "country_name": "جمهورية الدومنيكان",
                "phone_code": 1809,
                "id": 64
            }, {
                "country_code": "EC",
                "country_name": "الاكوادور",
                "phone_code": 593,
                "id": 65
            }, {
                "country_code": "EG",
                "country_name": "مصر",
                "phone_code": 20,
                "id": 66
            }, {
                "country_code": "SV",
                "country_name": "السلفادور",
                "phone_code": 503,
                "id": 67
            }, {
                "country_code": "GQ",
                "country_name": "غينيا الإستوائية",
                "phone_code": 240,
                "id": 68
            }, {
                "country_code": "ER",
                "country_name": "إريتريا",
                "phone_code": 291,
                "id": 69
            }, {
                "country_code": "EE",
                "country_name": "إستونيا",
                "phone_code": 372,
                "id": 70
            }, {
                "country_code": "ET",
                "country_name": "أثيوبيا",
                "phone_code": 251,
                "id": 71
            }, {
                "country_code": "FK",
                "country_name": "جزر فوكلاند (مالفيناس)",
                "phone_code": 500,
                "id": 72
            }, {
                "country_code": "FO",
                "country_name": "جزر فاروس",
                "phone_code": 298,
                "id": 73
            }, {
                "country_code": "FJ",
                "country_name": "فيجي",
                "phone_code": 679,
                "id": 74
            }, {
                "country_code": "FI",
                "country_name": "فنلندا",
                "phone_code": 358,
                "id": 75
            }, {
                "country_code": "FR",
                "country_name": "فرنسا",
                "phone_code": 33,
                "id": 76
            }, {
                "country_code": "GF",
                "country_name": "غيانا الفرنسية",
                "phone_code": 594,
                "id": 77
            }, {
                "country_code": "PF",
                "country_name": "بولينيزيا الفرنسية",
                "phone_code": 689,
                "id": 78
            }, {
                "country_code": "TF",
                "country_name": "المناطق الجنوبية لفرنسا",
                "phone_code": 262,
                "id": 79
            }, {
                "country_code": "GA",
                "country_name": "الجابون",
                "phone_code": 241,
                "id": 80
            }, {
                "country_code": "GM",
                "country_name": "غامبيا",
                "phone_code": 220,
                "id": 81
            }, {
                "country_code": "GE",
                "country_name": "جورجيا",
                "phone_code": 995,
                "id": 82
            }, {
                "country_code": "DE",
                "country_name": "ألمانيا",
                "phone_code": 49,
                "id": 83
            }, {
                "country_code": "GH",
                "country_name": "غانا",
                "phone_code": 233,
                "id": 84
            }, {
                "country_code": "GI",
                "country_name": "جبل طارق",
                "phone_code": 350,
                "id": 85
            }, {
                "country_code": "GR",
                "country_name": "اليونان",
                "phone_code": 30,
                "id": 86
            }, {
                "country_code": "GL",
                "country_name": "الأرض الخضراء",
                "phone_code": 299,
                "id": 87
            }, {
                "country_code": "GD",
                "country_name": "غرينادا",
                "phone_code": 1473,
                "id": 88
            }, {
                "country_code": "GP",
                "country_name": "جوادلوب",
                "phone_code": 590,
                "id": 89
            }, {
                "country_code": "GU",
                "country_name": "غوام",
                "phone_code": 1671,
                "id": 90
            }, {
                "country_code": "GT",
                "country_name": "غواتيمالا",
                "phone_code": 502,
                "id": 91
            }, {
                "country_code": "GG",
                "country_name": "غيرنسي",
                "phone_code": 44,
                "id": 92
            }, {
                "country_code": "GN",
                "country_name": "غينيا",
                "phone_code": 224,
                "id": 93
            }, {
                "country_code": "GW",
                "country_name": "غينيا بيساو",
                "phone_code": 245,
                "id": 94
            }, {
                "country_code": "GY",
                "country_name": "غيانا",
                "phone_code": 592,
                "id": 95
            }, {
                "country_code": "HT",
                "country_name": "هايتي",
                "phone_code": 509,
                "id": 96
            }, {
                "country_code": "HM",
                "country_name": "قلب الجزيرة وجزر ماكدونالز",
                "phone_code": 0,
                "id": 97
            }, {
                "country_code": "VA",
                "country_name": "الكرسي الرسولي (دولة الفاتيكان)",
                "phone_code": 39,
                "id": 98
            }, {
                "country_code": "HN",
                "country_name": "هندوراس",
                "phone_code": 504,
                "id": 99
            }, {
                "country_code": "HK",
                "country_name": "هونج كونج",
                "phone_code": 852,
                "id": 100
            }, {
                "country_code": "HU",
                "country_name": "هنغاريا",
                "phone_code": 36,
                "id": 101
            }, {
                "country_code": "IS",
                "country_name": "أيسلندا",
                "phone_code": 354,
                "id": 102
            }, {
                "country_code": "IN",
                "country_name": "الهند",
                "phone_code": 91,
                "id": 103
            }, {
                "country_code": "ID",
                "country_name": "إندونيسيا",
                "phone_code": 62,
                "id": 104
            }, {
                "country_code": "IR",
                "country_name": "جمهورية إيران الإسلامية",
                "phone_code": 98,
                "id": 105
            }, {
                "country_code": "IQ",
                "country_name": "العراق",
                "phone_code": 964,
                "id": 106
            }, {
                "country_code": "IE",
                "country_name": "أيرلندا",
                "phone_code": 353,
                "id": 107
            }, {
                "country_code": "IM",
                "country_name": "جزيرة آيل أوف مان",
                "phone_code": 44,
                "id": 108
            }, {
                "country_code": "IL",
                "country_name": "إسرائيل",
                "phone_code": 972,
                "id": 109
            }, {
                "country_code": "IT",
                "country_name": "إيطاليا",
                "phone_code": 39,
                "id": 110
            }, {
                "country_code": "JM",
                "country_name": "جامايكا",
                "phone_code": 1876,
                "id": 111
            }, {
                "country_code": "JP",
                "country_name": "اليابان",
                "phone_code": 81,
                "id": 112
            }, {
                "country_code": "JE",
                "country_name": "جيرسي",
                "phone_code": 44,
                "id": 113
            }, {
                "country_code": "JO",
                "country_name": "الأردن",
                "phone_code": 962,
                "id": 114
            }, {
                "country_code": "KZ",
                "country_name": "كازاخستان",
                "phone_code": 7,
                "id": 115
            }, {
                "country_code": "KE",
                "country_name": "كينيا",
                "phone_code": 254,
                "id": 116
            }, {
                "country_code": "KI",
                "country_name": "كيريباتي",
                "phone_code": 686,
                "id": 117
            }, {
                "country_code": "KP",
                "country_name": "كوريا، الجمهورية الشعبية الديمقراطية",
                "phone_code": 850,
                "id": 118
            }, {
                "country_code": "KR",
                "country_name": "جمهورية كوريا",
                "phone_code": 82,
                "id": 119
            }, {
                "country_code": "XK",
                "country_name": "كوسوفو",
                "phone_code": 381,
                "id": 120
            }, {
                "country_code": "KW",
                "country_name": "الكويت",
                "phone_code": 965,
                "id": 121
            }, {
                "country_code": "KG",
                "country_name": "قيرغيزستان",
                "phone_code": 996,
                "id": 122
            }, {
                "country_code": "LA",
                "country_name": "جمهورية لاو الديمقراطية الشعبية",
                "phone_code": 856,
                "id": 123
            }, {
                "country_code": "LV",
                "country_name": "لاتفيا",
                "phone_code": 371,
                "id": 124
            }, {
                "country_code": "LB",
                "country_name": "لبنان",
                "phone_code": 961,
                "id": 125
            }, {
                "country_code": "LS",
                "country_name": "ليسوتو",
                "phone_code": 266,
                "id": 126
            }, {
                "country_code": "LR",
                "country_name": "ليبيريا",
                "phone_code": 231,
                "id": 127
            }, {
                "country_code": "LY",
                "country_name": "الجماهيرية العربية الليبية",
                "phone_code": 218,
                "id": 128
            }, {
                "country_code": "LI",
                "country_name": "ليختنشتاين",
                "phone_code": 423,
                "id": 129
            }, {
                "country_code": "LT",
                "country_name": "ليتوانيا",
                "phone_code": 370,
                "id": 130
            }, {
                "country_code": "LU",
                "country_name": "لوكسمبورغ",
                "phone_code": 352,
                "id": 131
            }, {
                "country_code": "MO",
                "country_name": "ماكاو",
                "phone_code": 853,
                "id": 132
            }, {
                "country_code": "MK",
                "country_name": "مقدونيا ، جمهورية يوغوسلافيا السابقة",
                "phone_code": 389,
                "id": 133
            }, {
                "country_code": "MG",
                "country_name": "مدغشقر",
                "phone_code": 261,
                "id": 134
            }, {
                "country_code": "MW",
                "country_name": "ملاوي",
                "phone_code": 265,
                "id": 135
            }, {
                "country_code": "MY",
                "country_name": "ماليزيا",
                "phone_code": 60,
                "id": 136
            }, {
                "country_code": "MV",
                "country_name": "جزر المالديف",
                "phone_code": 960,
                "id": 137
            }, {
                "country_code": "ML",
                "country_name": "مالي",
                "phone_code": 223,
                "id": 138
            }, {
                "country_code": "MT",
                "country_name": "مالطا",
                "phone_code": 356,
                "id": 139
            }, {
                "country_code": "MH",
                "country_name": "جزر مارشال",
                "phone_code": 692,
                "id": 140
            }, {
                "country_code": "MQ",
                "country_name": "مارتينيك",
                "phone_code": 596,
                "id": 141
            }, {
                "country_code": "MR",
                "country_name": "موريتانيا",
                "phone_code": 222,
                "id": 142
            }, {
                "country_code": "MU",
                "country_name": "موريشيوس",
                "phone_code": 230,
                "id": 143
            }, {
                "country_code": "YT",
                "country_name": "مايوت",
                "phone_code": 269,
                "id": 144
            }, {
                "country_code": "MX",
                "country_name": "المكسيك",
                "phone_code": 52,
                "id": 145
            }, {
                "country_code": "FM",
                "country_name": "ولايات ميكرونيزيا الموحدة",
                "phone_code": 691,
                "id": 146
            }, {
                "country_code": "MD",
                "country_name": "جمهورية مولدوفا",
                "phone_code": 373,
                "id": 147
            }, {
                "country_code": "MC",
                "country_name": "موناكو",
                "phone_code": 377,
                "id": 148
            }, {
                "country_code": "MN",
                "country_name": "منغوليا",
                "phone_code": 976,
                "id": 149
            }, {
                "country_code": "ME",
                "country_name": "الجبل الأسود",
                "phone_code": 382,
                "id": 150
            }, {
                "country_code": "MS",
                "country_name": "مونتسيرات",
                "phone_code": 1664,
                "id": 151
            }, {
                "country_code": "MA",
                "country_name": "المغرب",
                "phone_code": 212,
                "id": 152
            }, {
                "country_code": "MZ",
                "country_name": "موزمبيق",
                "phone_code": 258,
                "id": 153
            }, {
                "country_code": "MM",
                "country_name": "ميانمار",
                "phone_code": 95,
                "id": 154
            }, {
                "country_code": "NA",
                "country_name": "ناميبيا",
                "phone_code": 264,
                "id": 155
            }, {
                "country_code": "NR",
                "country_name": "ناورو",
                "phone_code": 674,
                "id": 156
            }, {
                "country_code": "NP",
                "country_name": "نيبال",
                "phone_code": 977,
                "id": 157
            }, {
                "country_code": "NL",
                "country_name": "هولندا",
                "phone_code": 31,
                "id": 158
            }, {
                "country_code": "AN",
                "country_name": "جزر الأنتيل الهولندية",
                "phone_code": 599,
                "id": 159
            }, {
                "country_code": "NC",
                "country_name": "كاليدونيا الجديدة",
                "phone_code": 687,
                "id": 160
            }, {
                "country_code": "NZ",
                "country_name": "نيوزيلاندا",
                "phone_code": 64,
                "id": 161
            }, {
                "country_code": "NI",
                "country_name": "نيكاراغوا",
                "phone_code": 505,
                "id": 162
            }, {
                "country_code": "NE",
                "country_name": "النيجر",
                "phone_code": 227,
                "id": 163
            }, {
                "country_code": "NG",
                "country_name": "نيجيريا",
                "phone_code": 234,
                "id": 164
            }, {
                "country_code": "NU",
                "country_name": "نيوي",
                "phone_code": 683,
                "id": 165
            }, {
                "country_code": "NF",
                "country_name": "جزيرة نورفولك",
                "phone_code": 672,
                "id": 166
            }, {
                "country_code": "MP",
                "country_name": "جزر مريانا الشمالية",
                "phone_code": 1670,
                "id": 167
            }, {
                "country_code": "NO",
                "country_name": "النرويج",
                "phone_code": 47,
                "id": 168
            }, {
                "country_code": "OM",
                "country_name": "سلطنة عمان",
                "phone_code": 968,
                "id": 169
            }, {
                "country_code": "PK",
                "country_name": "باكستان",
                "phone_code": 92,
                "id": 170
            }, {
                "country_code": "PW",
                "country_name": "بالاو",
                "phone_code": 680,
                "id": 171
            }, {
                "country_code": "PS",
                "country_name": "الأراضي الفلسطينية المحتلة",
                "phone_code": 970,
                "id": 172
            }, {
                "country_code": "PA",
                "country_name": "بنما",
                "phone_code": 507,
                "id": 173
            }, {
                "country_code": "PG",
                "country_name": "بابوا غينيا الجديدة",
                "phone_code": 675,
                "id": 174
            }, {
                "country_code": "PY",
                "country_name": "باراغواي",
                "phone_code": 595,
                "id": 175
            }, {
                "country_code": "PE",
                "country_name": "بيرو",
                "phone_code": 51,
                "id": 176
            }, {
                "country_code": "PH",
                "country_name": "فيلبيني",
                "phone_code": 63,
                "id": 177
            }, {
                "country_code": "PN",
                "country_name": "بيتكيرن",
                "phone_code": 64,
                "id": 178
            }, {
                "country_code": "PL",
                "country_name": "بولندا",
                "phone_code": 48,
                "id": 179
            }, {
                "country_code": "PT",
                "country_name": "البرتغال",
                "phone_code": 351,
                "id": 180
            }, {
                "country_code": "PR",
                "country_name": "بورتوريكو",
                "phone_code": 1787,
                "id": 181
            }, {
                "country_code": "QA",
                "country_name": "دولة قطر",
                "phone_code": 974,
                "id": 182
            }, {
                "country_code": "RE",
                "country_name": "جمع شمل",
                "phone_code": 262,
                "id": 183
            }, {
                "country_code": "RO",
                "country_name": "رومانيا",
                "phone_code": 40,
                "id": 184
            }, {
                "country_code": "RU",
                "country_name": "الاتحاد الروسي",
                "phone_code": 70,
                "id": 185
            }, {
                "country_code": "RW",
                "country_name": "رواندا",
                "phone_code": 250,
                "id": 186
            }, {
                "country_code": "BL",
                "country_name": "سانت بارتيليمي",
                "phone_code": 590,
                "id": 187
            }, {
                "country_code": "SH",
                "country_name": "سانت هيلانة",
                "phone_code": 290,
                "id": 188
            }, {
                "country_code": "KN",
                "country_name": "سانت كيتس ونيفيس",
                "phone_code": 1869,
                "id": 189
            }, {
                "country_code": "LC",
                "country_name": "القديسة لوسيا",
                "phone_code": 1758,
                "id": 190
            }, {
                "country_code": "MF",
                "country_name": "القديس مارتن",
                "phone_code": 590,
                "id": 191
            }, {
                "country_code": "PM",
                "country_name": "سانت بيير وميكلون",
                "phone_code": 508,
                "id": 192
            }, {
                "country_code": "VC",
                "country_name": "سانت فنسنت وجزر غرينادين",
                "phone_code": 1784,
                "id": 193
            }, {
                "country_code": "WS",
                "country_name": "ساموا",
                "phone_code": 684,
                "id": 194
            }, {
                "country_code": "SM",
                "country_name": "سان مارينو",
                "phone_code": 378,
                "id": 195
            }, {
                "country_code": "ST",
                "country_name": "ساو تومي وبرينسيبي",
                "phone_code": 239,
                "id": 196
            }, {
                "country_code": "SA",
                "country_name": "المملكة العربية السعودية",
                "phone_code": 966,
                "id": 197
            }, {
                "country_code": "SN",
                "country_name": "السنغال",
                "phone_code": 221,
                "id": 198
            }, {
                "country_code": "RS",
                "country_name": "صربيا",
                "phone_code": 381,
                "id": 199
            }, {
                "country_code": "CS",
                "country_name": "صربيا والجبل الأسود",
                "phone_code": 381,
                "id": 200
            }, {
                "country_code": "SC",
                "country_name": "سيشيل",
                "phone_code": 248,
                "id": 201
            }, {
                "country_code": "SL",
                "country_name": "سيرا ليون",
                "phone_code": 232,
                "id": 202
            }, {
                "country_code": "SG",
                "country_name": "سنغافورة",
                "phone_code": 65,
                "id": 203
            }, {
                "country_code": "SX",
                "country_name": "سينت مارتن",
                "phone_code": 1,
                "id": 204
            }, {
                "country_code": "SK",
                "country_name": "سلوفاكيا",
                "phone_code": 421,
                "id": 205
            }, {
                "country_code": "SI",
                "country_name": "سلوفينيا",
                "phone_code": 386,
                "id": 206
            }, {
                "country_code": "SB",
                "country_name": "جزر سليمان",
                "phone_code": 677,
                "id": 207
            }, {
                "country_code": "SO",
                "country_name": "الصومال",
                "phone_code": 252,
                "id": 208
            }, {
                "country_code": "ZA",
                "country_name": "جنوب أفريقيا",
                "phone_code": 27,
                "id": 209
            }, {
                "country_code": "GS",
                "country_name": "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
                "phone_code": 500,
                "id": 210
            }, {
                "country_code": "SS",
                "country_name": "جنوب السودان",
                "phone_code": 211,
                "id": 211
            }, {
                "country_code": "ES",
                "country_name": "إسبانيا",
                "phone_code": 34,
                "id": 212
            }, {
                "country_code": "LK",
                "country_name": "سيريلانكا",
                "phone_code": 94,
                "id": 213
            }, {
                "country_code": "SD",
                "country_name": "السودان",
                "phone_code": 249,
                "id": 214
            }, {
                "country_code": "SR",
                "country_name": "سورينام",
                "phone_code": 597,
                "id": 215
            }, {
                "country_code": "SJ",
                "country_name": "سفالبارد وجان ماين",
                "phone_code": 47,
                "id": 216
            }, {
                "country_code": "SZ",
                "country_name": "سوازيلاند",
                "phone_code": 268,
                "id": 217
            }, {
                "country_code": "SE",
                "country_name": "السويد",
                "phone_code": 46,
                "id": 218
            }, {
                "country_code": "CH",
                "country_name": "سويسرا",
                "phone_code": 41,
                "id": 219
            }, {
                "country_code": "SY",
                "country_name": "الجمهورية العربية السورية",
                "phone_code": 963,
                "id": 220
            }, {
                "country_code": "TW",
                "country_name": "مقاطعة تايوان الصينية",
                "phone_code": 886,
                "id": 221
            }, {
                "country_code": "TJ",
                "country_name": "طاجيكستان",
                "phone_code": 992,
                "id": 222
            }, {
                "country_code": "TZ",
                "country_name": "جمهورية تنزانيا المتحدة",
                "phone_code": 255,
                "id": 223
            }, {
                "country_code": "TH",
                "country_name": "تايلاند",
                "phone_code": 66,
                "id": 224
            }, {
                "country_code": "TL",
                "country_name": "تيمور ليشتي",
                "phone_code": 670,
                "id": 225
            }, {
                "country_code": "TG",
                "country_name": "توجو",
                "phone_code": 228,
                "id": 226
            }, {
                "country_code": "TK",
                "country_name": "توكيلاو",
                "phone_code": 690,
                "id": 227
            }, {
                "country_code": "TO",
                "country_name": "تونغا",
                "phone_code": 676,
                "id": 228
            }, {
                "country_code": "TT",
                "country_name": "ترينداد وتوباغو",
                "phone_code": 1868,
                "id": 229
            }, {
                "country_code": "TN",
                "country_name": "تونس",
                "phone_code": 216,
                "id": 230
            }, {
                "country_code": "TR",
                "country_name": "ديك رومى",
                "phone_code": 90,
                "id": 231
            }, {
                "country_code": "TM",
                "country_name": "تركمانستان",
                "phone_code": 7370,
                "id": 232
            }, {
                "country_code": "TC",
                "country_name": "جزر تركس وكايكوس",
                "phone_code": 1649,
                "id": 233
            }, {
                "country_code": "TV",
                "country_name": "توفالو",
                "phone_code": 688,
                "id": 234
            }, {
                "country_code": "UG",
                "country_name": "أوغندا",
                "phone_code": 256,
                "id": 235
            }, {
                "country_code": "UA",
                "country_name": "أوكرانيا",
                "phone_code": 380,
                "id": 236
            }, {
                "country_code": "AE",
                "country_name": "الإمارات العربية المتحدة",
                "phone_code": 971,
                "id": 237
            }, {
                "country_code": "GB",
                "country_name": "المملكة المتحدة",
                "phone_code": 44,
                "id": 238
            }, {
                "country_code": "US",
                "country_name": "الولايات المتحدة",
                "phone_code": 1,
                "id": 239
            }, {
                "country_code": "UM",
                "country_name": "جزر الولايات المتحدة البعيدة الصغرى",
                "phone_code": 1,
                "id": 240
            }, {
                "country_code": "UY",
                "country_name": "أوروغواي",
                "phone_code": 598,
                "id": 241
            }, {
                "country_code": "UZ",
                "country_name": "أوزبكستان",
                "phone_code": 998,
                "id": 242
            }, {
                "country_code": "VU",
                "country_name": "فانواتو",
                "phone_code": 678,
                "id": 243
            }, {
                "country_code": "VE",
                "country_name": "فنزويلا",
                "phone_code": 58,
                "id": 244
            }, {
                "country_code": "VN",
                "country_name": "فييت نام",
                "phone_code": 84,
                "id": 245
            }, {
                "country_code": "VG",
                "country_name": "جزر العذراء البريطانية",
                "phone_code": 1284,
                "id": 246
            }, {
                "country_code": "VI",
                "country_name": "جزر فيرجن ، الولايات المتحدة",
                "phone_code": 1340,
                "id": 247
            }, {
                "country_code": "WF",
                "country_name": "واليس وفوتونا",
                "phone_code": 681,
                "id": 248
            }, {
                "country_code": "EH",
                "country_name": "الصحراء الغربية",
                "phone_code": 212,
                "id": 249
            }, {
                "country_code": "YE",
                "country_name": "اليمن",
                "phone_code": 967,
                "id": 250
            }, {
                "country_code": "ZM",
                "country_name": "زامبيا",
                "phone_code": 260,
                "id": 251
            }, {
                "country_code": "ZW",
                "country_name": "زيمبابوي",
                "phone_code": 263,
                "id": 252
            }
        ];
        // All en countries
        this.en_countries = [
            {
                "country_code": "AF",
                "country_name": "Afghanistan",
                "phone_code": 93,
                "id": 1
            }, {
                "country_code": "AX",
                "country_name": "Aland Islands",
                "phone_code": 358,
                "id": 2
            }, {
                "country_code": "AL",
                "country_name": "Albania",
                "phone_code": 355,
                "id": 3
            }, {
                "country_code": "DZ",
                "country_name": "Algeria",
                "phone_code": 213,
                "id": 4
            }, {
                "country_code": "AS",
                "country_name": "American Samoa",
                "phone_code": 1684,
                "id": 5
            }, {
                "country_code": "AD",
                "country_name": "Andorra",
                "phone_code": 376,
                "id": 6
            }, {
                "country_code": "AO",
                "country_name": "Angola",
                "phone_code": 244,
                "id": 7
            }, {
                "country_code": "AI",
                "country_name": "Anguilla",
                "phone_code": 1264,
                "id": 8
            }, {
                "country_code": "AQ",
                "country_name": "Antarctica",
                "phone_code": 672,
                "id": 9
            }, {
                "country_code": "AG",
                "country_name": "Antigua and Barbuda",
                "phone_code": 1268,
                "id": 10
            }, {
                "country_code": "AR",
                "country_name": "Argentina",
                "phone_code": 54,
                "id": 11
            }, {
                "country_code": "AM",
                "country_name": "Armenia",
                "phone_code": 374,
                "id": 12
            }, {
                "country_code": "AW",
                "country_name": "Aruba",
                "phone_code": 297,
                "id": 13
            }, {
                "country_code": "AU",
                "country_name": "Australia",
                "phone_code": 61,
                "id": 14
            }, {
                "country_code": "AT",
                "country_name": "Austria",
                "phone_code": 43,
                "id": 15
            }, {
                "country_code": "AZ",
                "country_name": "Azerbaijan",
                "phone_code": 994,
                "id": 16
            }, {
                "country_code": "BS",
                "country_name": "Bahamas",
                "phone_code": 1242,
                "id": 17
            }, {
                "country_code": "BH",
                "country_name": "Bahrain",
                "phone_code": 973,
                "id": 18
            }, {
                "country_code": "BD",
                "country_name": "Bangladesh",
                "phone_code": 880,
                "id": 19
            }, {
                "country_code": "BB",
                "country_name": "Barbados",
                "phone_code": 1246,
                "id": 20
            }, {
                "country_code": "BY",
                "country_name": "Belarus",
                "phone_code": 375,
                "id": 21
            }, {
                "country_code": "BE",
                "country_name": "Belgium",
                "phone_code": 32,
                "id": 22
            }, {
                "country_code": "BZ",
                "country_name": "Belize",
                "phone_code": 501,
                "id": 23
            }, {
                "country_code": "BJ",
                "country_name": "Benin",
                "phone_code": 229,
                "id": 24
            }, {
                "country_code": "BM",
                "country_name": "Bermuda",
                "phone_code": 1441,
                "id": 25
            }, {
                "country_code": "BT",
                "country_name": "Bhutan",
                "phone_code": 975,
                "id": 26
            }, {
                "country_code": "BO",
                "country_name": "Bolivia",
                "phone_code": 591,
                "id": 27
            }, {
                "country_code": "BQ",
                "country_name": "Bonaire, Sint Eustatius and Saba",
                "phone_code": 599,
                "id": 28
            }, {
                "country_code": "BA",
                "country_name": "Bosnia and Herzegovina",
                "phone_code": 387,
                "id": 29
            }, {
                "country_code": "BW",
                "country_name": "Botswana",
                "phone_code": 267,
                "id": 30
            }, {
                "country_code": "BV",
                "country_name": "Bouvet Island",
                "phone_code": 55,
                "id": 31
            }, {
                "country_code": "BR",
                "country_name": "Brazil",
                "phone_code": 55,
                "id": 32
            }, {
                "country_code": "IO",
                "country_name": "British Indian Ocean Territory",
                "phone_code": 246,
                "id": 33
            }, {
                "country_code": "BN",
                "country_name": "Brunei Darussalam",
                "phone_code": 673,
                "id": 34
            }, {
                "country_code": "BG",
                "country_name": "Bulgaria",
                "phone_code": 359,
                "id": 35
            }, {
                "country_code": "BF",
                "country_name": "Burkina Faso",
                "phone_code": 226,
                "id": 36
            }, {
                "country_code": "BI",
                "country_name": "Burundi",
                "phone_code": 257,
                "id": 37
            }, {
                "country_code": "KH",
                "country_name": "Cambodia",
                "phone_code": 855,
                "id": 38
            }, {
                "country_code": "CM",
                "country_name": "Cameroon",
                "phone_code": 237,
                "id": 39
            }, {
                "country_code": "CA",
                "country_name": "Canada",
                "phone_code": 1,
                "id": 40
            }, {
                "country_code": "CV",
                "country_name": "Cape Verde",
                "phone_code": 238,
                "id": 41
            }, {
                "country_code": "KY",
                "country_name": "Cayman Islands",
                "phone_code": 1345,
                "id": 42
            }, {
                "country_code": "CF",
                "country_name": "Central African Republic",
                "phone_code": 236,
                "id": 43
            }, {
                "country_code": "TD",
                "country_name": "Chad",
                "phone_code": 235,
                "id": 44
            }, {
                "country_code": "CL",
                "country_name": "Chile",
                "phone_code": 56,
                "id": 45
            }, {
                "country_code": "CN",
                "country_name": "China",
                "phone_code": 86,
                "id": 46
            }, {
                "country_code": "CX",
                "country_name": "Christmas Island",
                "phone_code": 61,
                "id": 47
            }, {
                "country_code": "CC",
                "country_name": "Cocos (Keeling) Islands",
                "phone_code": 672,
                "id": 48
            }, {
                "country_code": "CO",
                "country_name": "Colombia",
                "phone_code": 57,
                "id": 49
            }, {
                "country_code": "KM",
                "country_name": "Comoros",
                "phone_code": 269,
                "id": 50
            }, {
                "country_code": "CG",
                "country_name": "Congo",
                "phone_code": 242,
                "id": 51
            }, {
                "country_code": "CD",
                "country_name": "Congo, Democratic Republic of the Congo",
                "phone_code": 242,
                "id": 52
            }, {
                "country_code": "CK",
                "country_name": "Cook Islands",
                "phone_code": 682,
                "id": 53
            }, {
                "country_code": "CR",
                "country_name": "Costa Rica",
                "phone_code": 506,
                "id": 54
            }, {
                "country_code": "CI",
                "country_name": "Cote D'Ivoire",
                "phone_code": 225,
                "id": 55
            }, {
                "country_code": "HR",
                "country_name": "Croatia",
                "phone_code": 385,
                "id": 56
            }, {
                "country_code": "CU",
                "country_name": "Cuba",
                "phone_code": 53,
                "id": 57
            }, {
                "country_code": "CW",
                "country_name": "Curacao",
                "phone_code": 599,
                "id": 58
            }, {
                "country_code": "CY",
                "country_name": "Cyprus",
                "phone_code": 357,
                "id": 59
            }, {
                "country_code": "CZ",
                "country_name": "Czech Republic",
                "phone_code": 420,
                "id": 60
            }, {
                "country_code": "DK",
                "country_name": "Denmark",
                "phone_code": 45,
                "id": 61
            }, {
                "country_code": "DJ",
                "country_name": "Djibouti",
                "phone_code": 253,
                "id": 62
            }, {
                "country_code": "DM",
                "country_name": "Dominica",
                "phone_code": 1767,
                "id": 63
            }, {
                "country_code": "DO",
                "country_name": "Dominican Republic",
                "phone_code": 1809,
                "id": 64
            }, {
                "country_code": "EC",
                "country_name": "Ecuador",
                "phone_code": 593,
                "id": 65
            }, {
                "country_code": "EG",
                "country_name": "Egypt",
                "phone_code": 20,
                "id": 66
            }, {
                "country_code": "SV",
                "country_name": "El Salvador",
                "phone_code": 503,
                "id": 67
            }, {
                "country_code": "GQ",
                "country_name": "Equatorial Guinea",
                "phone_code": 240,
                "id": 68
            }, {
                "country_code": "ER",
                "country_name": "Eritrea",
                "phone_code": 291,
                "id": 69
            }, {
                "country_code": "EE",
                "country_name": "Estonia",
                "phone_code": 372,
                "id": 70
            }, {
                "country_code": "ET",
                "country_name": "Ethiopia",
                "phone_code": 251,
                "id": 71
            }, {
                "country_code": "FK",
                "country_name": "Falkland Islands (Malvinas)",
                "phone_code": 500,
                "id": 72
            }, {
                "country_code": "FO",
                "country_name": "Faroe Islands",
                "phone_code": 298,
                "id": 73
            }, {
                "country_code": "FJ",
                "country_name": "Fiji",
                "phone_code": 679,
                "id": 74
            }, {
                "country_code": "FI",
                "country_name": "Finland",
                "phone_code": 358,
                "id": 75
            }, {
                "country_code": "FR",
                "country_name": "France",
                "phone_code": 33,
                "id": 76
            }, {
                "country_code": "GF",
                "country_name": "French Guiana",
                "phone_code": 594,
                "id": 77
            }, {
                "country_code": "PF",
                "country_name": "French Polynesia",
                "phone_code": 689,
                "id": 78
            }, {
                "country_code": "TF",
                "country_name": "French Southern Territories",
                "phone_code": 262,
                "id": 79
            }, {
                "country_code": "GA",
                "country_name": "Gabon",
                "phone_code": 241,
                "id": 80
            }, {
                "country_code": "GM",
                "country_name": "Gambia",
                "phone_code": 220,
                "id": 81
            }, {
                "country_code": "GE",
                "country_name": "Georgia",
                "phone_code": 995,
                "id": 82
            }, {
                "country_code": "DE",
                "country_name": "Germany",
                "phone_code": 49,
                "id": 83
            }, {
                "country_code": "GH",
                "country_name": "Ghana",
                "phone_code": 233,
                "id": 84
            }, {
                "country_code": "GI",
                "country_name": "Gibraltar",
                "phone_code": 350,
                "id": 85
            }, {
                "country_code": "GR",
                "country_name": "Greece",
                "phone_code": 30,
                "id": 86
            }, {
                "country_code": "GL",
                "country_name": "Greenland",
                "phone_code": 299,
                "id": 87
            }, {
                "country_code": "GD",
                "country_name": "Grenada",
                "phone_code": 1473,
                "id": 88
            }, {
                "country_code": "GP",
                "country_name": "Guadeloupe",
                "phone_code": 590,
                "id": 89
            }, {
                "country_code": "GU",
                "country_name": "Guam",
                "phone_code": 1671,
                "id": 90
            }, {
                "country_code": "GT",
                "country_name": "Guatemala",
                "phone_code": 502,
                "id": 91
            }, {
                "country_code": "GG",
                "country_name": "Guernsey",
                "phone_code": 44,
                "id": 92
            }, {
                "country_code": "GN",
                "country_name": "Guinea",
                "phone_code": 224,
                "id": 93
            }, {
                "country_code": "GW",
                "country_name": "Guinea-Bissau",
                "phone_code": 245,
                "id": 94
            }, {
                "country_code": "GY",
                "country_name": "Guyana",
                "phone_code": 592,
                "id": 95
            }, {
                "country_code": "HT",
                "country_name": "Haiti",
                "phone_code": 509,
                "id": 96
            }, {
                "country_code": "HM",
                "country_name": "Heard Island and Mcdonald Islands",
                "phone_code": 0,
                "id": 97
            }, {
                "country_code": "VA",
                "country_name": "Holy See (Vatican City State)",
                "phone_code": 39,
                "id": 98
            }, {
                "country_code": "HN",
                "country_name": "Honduras",
                "phone_code": 504,
                "id": 99
            }, {
                "country_code": "HK",
                "country_name": "Hong Kong",
                "phone_code": 852,
                "id": 100
            }, {
                "country_code": "HU",
                "country_name": "Hungary",
                "phone_code": 36,
                "id": 101
            }, {
                "country_code": "IS",
                "country_name": "Iceland",
                "phone_code": 354,
                "id": 102
            }, {
                "country_code": "IN",
                "country_name": "India",
                "phone_code": 91,
                "id": 103
            }, {
                "country_code": "ID",
                "country_name": "Indonesia",
                "phone_code": 62,
                "id": 104
            }, {
                "country_code": "IR",
                "country_name": "Iran, Islamic Republic of",
                "phone_code": 98,
                "id": 105
            }, {
                "country_code": "IQ",
                "country_name": "Iraq",
                "phone_code": 964,
                "id": 106
            }, {
                "country_code": "IE",
                "country_name": "Ireland",
                "phone_code": 353,
                "id": 107
            }, {
                "country_code": "IM",
                "country_name": "Isle of Man",
                "phone_code": 44,
                "id": 108
            }, {
                "country_code": "IL",
                "country_name": "Israel",
                "phone_code": 972,
                "id": 109
            }, {
                "country_code": "IT",
                "country_name": "Italy",
                "phone_code": 39,
                "id": 110
            }, {
                "country_code": "JM",
                "country_name": "Jamaica",
                "phone_code": 1876,
                "id": 111
            }, {
                "country_code": "JP",
                "country_name": "Japan",
                "phone_code": 81,
                "id": 112
            }, {
                "country_code": "JE",
                "country_name": "Jersey",
                "phone_code": 44,
                "id": 113
            }, {
                "country_code": "JO",
                "country_name": "Jordan",
                "phone_code": 962,
                "id": 114
            }, {
                "country_code": "KZ",
                "country_name": "Kazakhstan",
                "phone_code": 7,
                "id": 115
            }, {
                "country_code": "KE",
                "country_name": "Kenya",
                "phone_code": 254,
                "id": 116
            }, {
                "country_code": "KI",
                "country_name": "Kiribati",
                "phone_code": 686,
                "id": 117
            }, {
                "country_code": "KP",
                "country_name": "Korea, Democratic People's Republic of",
                "phone_code": 850,
                "id": 118
            }, {
                "country_code": "KR",
                "country_name": "Korea, Republic of",
                "phone_code": 82,
                "id": 119
            }, {
                "country_code": "XK",
                "country_name": "Kosovo",
                "phone_code": 381,
                "id": 120
            }, {
                "country_code": "KW",
                "country_name": "Kuwait",
                "phone_code": 965,
                "id": 121
            }, {
                "country_code": "KG",
                "country_name": "Kyrgyzstan",
                "phone_code": 996,
                "id": 122
            }, {
                "country_code": "LA",
                "country_name": "Lao People's Democratic Republic",
                "phone_code": 856,
                "id": 123
            }, {
                "country_code": "LV",
                "country_name": "Latvia",
                "phone_code": 371,
                "id": 124
            }, {
                "country_code": "LB",
                "country_name": "Lebanon",
                "phone_code": 961,
                "id": 125
            }, {
                "country_code": "LS",
                "country_name": "Lesotho",
                "phone_code": 266,
                "id": 126
            }, {
                "country_code": "LR",
                "country_name": "Liberia",
                "phone_code": 231,
                "id": 127
            }, {
                "country_code": "LY",
                "country_name": "Libyan Arab Jamahiriya",
                "phone_code": 218,
                "id": 128
            }, {
                "country_code": "LI",
                "country_name": "Liechtenstein",
                "phone_code": 423,
                "id": 129
            }, {
                "country_code": "LT",
                "country_name": "Lithuania",
                "phone_code": 370,
                "id": 130
            }, {
                "country_code": "LU",
                "country_name": "Luxembourg",
                "phone_code": 352,
                "id": 131
            }, {
                "country_code": "MO",
                "country_name": "Macao",
                "phone_code": 853,
                "id": 132
            }, {
                "country_code": "MK",
                "country_name": "Macedonia, the Former Yugoslav Republic of",
                "phone_code": 389,
                "id": 133
            }, {
                "country_code": "MG",
                "country_name": "Madagascar",
                "phone_code": 261,
                "id": 134
            }, {
                "country_code": "MW",
                "country_name": "Malawi",
                "phone_code": 265,
                "id": 135
            }, {
                "country_code": "MY",
                "country_name": "Malaysia",
                "phone_code": 60,
                "id": 136
            }, {
                "country_code": "MV",
                "country_name": "Maldives",
                "phone_code": 960,
                "id": 137
            }, {
                "country_code": "ML",
                "country_name": "Mali",
                "phone_code": 223,
                "id": 138
            }, {
                "country_code": "MT",
                "country_name": "Malta",
                "phone_code": 356,
                "id": 139
            }, {
                "country_code": "MH",
                "country_name": "Marshall Islands",
                "phone_code": 692,
                "id": 140
            }, {
                "country_code": "MQ",
                "country_name": "Martinique",
                "phone_code": 596,
                "id": 141
            }, {
                "country_code": "MR",
                "country_name": "Mauritania",
                "phone_code": 222,
                "id": 142
            }, {
                "country_code": "MU",
                "country_name": "Mauritius",
                "phone_code": 230,
                "id": 143
            }, {
                "country_code": "YT",
                "country_name": "Mayotte",
                "phone_code": 269,
                "id": 144
            }, {
                "country_code": "MX",
                "country_name": "Mexico",
                "phone_code": 52,
                "id": 145
            }, {
                "country_code": "FM",
                "country_name": "Micronesia, Federated States of",
                "phone_code": 691,
                "id": 146
            }, {
                "country_code": "MD",
                "country_name": "Moldova, Republic of",
                "phone_code": 373,
                "id": 147
            }, {
                "country_code": "MC",
                "country_name": "Monaco",
                "phone_code": 377,
                "id": 148
            }, {
                "country_code": "MN",
                "country_name": "Mongolia",
                "phone_code": 976,
                "id": 149
            }, {
                "country_code": "ME",
                "country_name": "Montenegro",
                "phone_code": 382,
                "id": 150
            }, {
                "country_code": "MS",
                "country_name": "Montserrat",
                "phone_code": 1664,
                "id": 151
            }, {
                "country_code": "MA",
                "country_name": "Morocco",
                "phone_code": 212,
                "id": 152
            }, {
                "country_code": "MZ",
                "country_name": "Mozambique",
                "phone_code": 258,
                "id": 153
            }, {
                "country_code": "MM",
                "country_name": "Myanmar",
                "phone_code": 95,
                "id": 154
            }, {
                "country_code": "NA",
                "country_name": "Namibia",
                "phone_code": 264,
                "id": 155
            }, {
                "country_code": "NR",
                "country_name": "Nauru",
                "phone_code": 674,
                "id": 156
            }, {
                "country_code": "NP",
                "country_name": "Nepal",
                "phone_code": 977,
                "id": 157
            }, {
                "country_code": "NL",
                "country_name": "Netherlands",
                "phone_code": 31,
                "id": 158
            }, {
                "country_code": "AN",
                "country_name": "Netherlands Antilles",
                "phone_code": 599,
                "id": 159
            }, {
                "country_code": "NC",
                "country_name": "New Caledonia",
                "phone_code": 687,
                "id": 160
            }, {
                "country_code": "NZ",
                "country_name": "New Zealand",
                "phone_code": 64,
                "id": 161
            }, {
                "country_code": "NI",
                "country_name": "Nicaragua",
                "phone_code": 505,
                "id": 162
            }, {
                "country_code": "NE",
                "country_name": "Niger",
                "phone_code": 227,
                "id": 163
            }, {
                "country_code": "NG",
                "country_name": "Nigeria",
                "phone_code": 234,
                "id": 164
            }, {
                "country_code": "NU",
                "country_name": "Niue",
                "phone_code": 683,
                "id": 165
            }, {
                "country_code": "NF",
                "country_name": "Norfolk Island",
                "phone_code": 672,
                "id": 166
            }, {
                "country_code": "MP",
                "country_name": "Northern Mariana Islands",
                "phone_code": 1670,
                "id": 167
            }, {
                "country_code": "NO",
                "country_name": "Norway",
                "phone_code": 47,
                "id": 168
            }, {
                "country_code": "OM",
                "country_name": "Oman",
                "phone_code": 968,
                "id": 169
            }, {
                "country_code": "PK",
                "country_name": "Pakistan",
                "phone_code": 92,
                "id": 170
            }, {
                "country_code": "PW",
                "country_name": "Palau",
                "phone_code": 680,
                "id": 171
            }, {
                "country_code": "PS",
                "country_name": "Palestinian Territory, Occupied",
                "phone_code": 970,
                "id": 172
            }, {
                "country_code": "PA",
                "country_name": "Panama",
                "phone_code": 507,
                "id": 173
            }, {
                "country_code": "PG",
                "country_name": "Papua New Guinea",
                "phone_code": 675,
                "id": 174
            }, {
                "country_code": "PY",
                "country_name": "Paraguay",
                "phone_code": 595,
                "id": 175
            }, {
                "country_code": "PE",
                "country_name": "Peru",
                "phone_code": 51,
                "id": 176
            }, {
                "country_code": "PH",
                "country_name": "Philippines",
                "phone_code": 63,
                "id": 177
            }, {
                "country_code": "PN",
                "country_name": "Pitcairn",
                "phone_code": 64,
                "id": 178
            }, {
                "country_code": "PL",
                "country_name": "Poland",
                "phone_code": 48,
                "id": 179
            }, {
                "country_code": "PT",
                "country_name": "Portugal",
                "phone_code": 351,
                "id": 180
            }, {
                "country_code": "PR",
                "country_name": "Puerto Rico",
                "phone_code": 1787,
                "id": 181
            }, {
                "country_code": "QA",
                "country_name": "Qatar",
                "phone_code": 974,
                "id": 182
            }, {
                "country_code": "RE",
                "country_name": "Reunion",
                "phone_code": 262,
                "id": 183
            }, {
                "country_code": "RO",
                "country_name": "Romania",
                "phone_code": 40,
                "id": 184
            }, {
                "country_code": "RU",
                "country_name": "Russian Federation",
                "phone_code": 70,
                "id": 185
            }, {
                "country_code": "RW",
                "country_name": "Rwanda",
                "phone_code": 250,
                "id": 186
            }, {
                "country_code": "BL",
                "country_name": "Saint Barthelemy",
                "phone_code": 590,
                "id": 187
            }, {
                "country_code": "SH",
                "country_name": "Saint Helena",
                "phone_code": 290,
                "id": 188
            }, {
                "country_code": "KN",
                "country_name": "Saint Kitts and Nevis",
                "phone_code": 1869,
                "id": 189
            }, {
                "country_code": "LC",
                "country_name": "Saint Lucia",
                "phone_code": 1758,
                "id": 190
            }, {
                "country_code": "MF",
                "country_name": "Saint Martin",
                "phone_code": 590,
                "id": 191
            }, {
                "country_code": "PM",
                "country_name": "Saint Pierre and Miquelon",
                "phone_code": 508,
                "id": 192
            }, {
                "country_code": "VC",
                "country_name": "Saint Vincent and the Grenadines",
                "phone_code": 1784,
                "id": 193
            }, {
                "country_code": "WS",
                "country_name": "Samoa",
                "phone_code": 684,
                "id": 194
            }, {
                "country_code": "SM",
                "country_name": "San Marino",
                "phone_code": 378,
                "id": 195
            }, {
                "country_code": "ST",
                "country_name": "Sao Tome and Principe",
                "phone_code": 239,
                "id": 196
            }, {
                "country_code": "SA",
                "country_name": "Saudi Arabia",
                "phone_code": 966,
                "id": 197
            }, {
                "country_code": "SN",
                "country_name": "Senegal",
                "phone_code": 221,
                "id": 198
            }, {
                "country_code": "RS",
                "country_name": "Serbia",
                "phone_code": 381,
                "id": 199
            }, {
                "country_code": "CS",
                "country_name": "Serbia and Montenegro",
                "phone_code": 381,
                "id": 200
            }, {
                "country_code": "SC",
                "country_name": "Seychelles",
                "phone_code": 248,
                "id": 201
            }, {
                "country_code": "SL",
                "country_name": "Sierra Leone",
                "phone_code": 232,
                "id": 202
            }, {
                "country_code": "SG",
                "country_name": "Singapore",
                "phone_code": 65,
                "id": 203
            }, {
                "country_code": "SX",
                "country_name": "Sint Maarten",
                "phone_code": 1,
                "id": 204
            }, {
                "country_code": "SK",
                "country_name": "Slovakia",
                "phone_code": 421,
                "id": 205
            }, {
                "country_code": "SI",
                "country_name": "Slovenia",
                "phone_code": 386,
                "id": 206
            }, {
                "country_code": "SB",
                "country_name": "Solomon Islands",
                "phone_code": 677,
                "id": 207
            }, {
                "country_code": "SO",
                "country_name": "Somalia",
                "phone_code": 252,
                "id": 208
            }, {
                "country_code": "ZA",
                "country_name": "South Africa",
                "phone_code": 27,
                "id": 209
            }, {
                "country_code": "GS",
                "country_name": "South Georgia and the South Sandwich Islands",
                "phone_code": 500,
                "id": 210
            }, {
                "country_code": "SS",
                "country_name": "South Sudan",
                "phone_code": 211,
                "id": 211
            }, {
                "country_code": "ES",
                "country_name": "Spain",
                "phone_code": 34,
                "id": 212
            }, {
                "country_code": "LK",
                "country_name": "Sri Lanka",
                "phone_code": 94,
                "id": 213
            }, {
                "country_code": "SD",
                "country_name": "Sudan",
                "phone_code": 249,
                "id": 214
            }, {
                "country_code": "SR",
                "country_name": "Suriname",
                "phone_code": 597,
                "id": 215
            }, {
                "country_code": "SJ",
                "country_name": "Svalbard and Jan Mayen",
                "phone_code": 47,
                "id": 216
            }, {
                "country_code": "SZ",
                "country_name": "Swaziland",
                "phone_code": 268,
                "id": 217
            }, {
                "country_code": "SE",
                "country_name": "Sweden",
                "phone_code": 46,
                "id": 218
            }, {
                "country_code": "CH",
                "country_name": "Switzerland",
                "phone_code": 41,
                "id": 219
            }, {
                "country_code": "SY",
                "country_name": "Syrian Arab Republic",
                "phone_code": 963,
                "id": 220
            }, {
                "country_code": "TW",
                "country_name": "Taiwan, Province of China",
                "phone_code": 886,
                "id": 221
            }, {
                "country_code": "TJ",
                "country_name": "Tajikistan",
                "phone_code": 992,
                "id": 222
            }, {
                "country_code": "TZ",
                "country_name": "Tanzania, United Republic of",
                "phone_code": 255,
                "id": 223
            }, {
                "country_code": "TH",
                "country_name": "Thailand",
                "phone_code": 66,
                "id": 224
            }, {
                "country_code": "TL",
                "country_name": "Timor-Leste",
                "phone_code": 670,
                "id": 225
            }, {
                "country_code": "TG",
                "country_name": "Togo",
                "phone_code": 228,
                "id": 226
            }, {
                "country_code": "TK",
                "country_name": "Tokelau",
                "phone_code": 690,
                "id": 227
            }, {
                "country_code": "TO",
                "country_name": "Tonga",
                "phone_code": 676,
                "id": 228
            }, {
                "country_code": "TT",
                "country_name": "Trinidad and Tobago",
                "phone_code": 1868,
                "id": 229
            }, {
                "country_code": "TN",
                "country_name": "Tunisia",
                "phone_code": 216,
                "id": 230
            }, {
                "country_code": "TR",
                "country_name": "Turkey",
                "phone_code": 90,
                "id": 231
            }, {
                "country_code": "TM",
                "country_name": "Turkmenistan",
                "phone_code": 7370,
                "id": 232
            }, {
                "country_code": "TC",
                "country_name": "Turks and Caicos Islands",
                "phone_code": 1649,
                "id": 233
            }, {
                "country_code": "TV",
                "country_name": "Tuvalu",
                "phone_code": 688,
                "id": 234
            }, {
                "country_code": "UG",
                "country_name": "Uganda",
                "phone_code": 256,
                "id": 235
            }, {
                "country_code": "UA",
                "country_name": "Ukraine",
                "phone_code": 380,
                "id": 236
            }, {
                "country_code": "AE",
                "country_name": "United Arab Emirates",
                "phone_code": 971,
                "id": 237
            }, {
                "country_code": "GB",
                "country_name": "United Kingdom",
                "phone_code": 44,
                "id": 238
            }, {
                "country_code": "US",
                "country_name": "United States",
                "phone_code": 1,
                "id": 239
            }, {
                "country_code": "UM",
                "country_name": "United States Minor Outlying Islands",
                "phone_code": 1,
                "id": 240
            }, {
                "country_code": "UY",
                "country_name": "Uruguay",
                "phone_code": 598,
                "id": 241
            }, {
                "country_code": "UZ",
                "country_name": "Uzbekistan",
                "phone_code": 998,
                "id": 242
            }, {
                "country_code": "VU",
                "country_name": "Vanuatu",
                "phone_code": 678,
                "id": 243
            }, {
                "country_code": "VE",
                "country_name": "Venezuela",
                "phone_code": 58,
                "id": 244
            }, {
                "country_code": "VN",
                "country_name": "Viet Nam",
                "phone_code": 84,
                "id": 245
            }, {
                "country_code": "VG",
                "country_name": "Virgin Islands, British",
                "phone_code": 1284,
                "id": 246
            }, {
                "country_code": "VI",
                "country_name": "Virgin Islands, U.s.",
                "phone_code": 1340,
                "id": 247
            }, {
                "country_code": "WF",
                "country_name": "Wallis and Futuna",
                "phone_code": 681,
                "id": 248
            }, {
                "country_code": "EH",
                "country_name": "Western Sahara",
                "phone_code": 212,
                "id": 249
            }, {
                "country_code": "YE",
                "country_name": "Yemen",
                "phone_code": 967,
                "id": 250
            }, {
                "country_code": "ZM",
                "country_name": "Zambia",
                "phone_code": 260,
                "id": 251
            }, {
                "country_code": "ZW",
                "country_name": "Zimbabwe",
                "phone_code": 263,
                "id": 252
            }
        ];
    }
    CountriesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CountriesService_Factory() { return new CountriesService(); }, token: CountriesService, providedIn: "root" });
    return CountriesService;
}());
export { CountriesService };
