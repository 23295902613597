<app-whatsapp-icon></app-whatsapp-icon>
<div class="p-5 text-center">
    <h1 class="txtMainColor d-none d-md-block">احجز مقعدك من هنا</h1>
    <h2 class="txtMainColor d-md-none">احجز مقعدك من هنا</h2>
</div>

<div class="row">
    <div class="col-10 col-lg-5 offset-1 offset-lg-0 card px-5 p-md-5">
        <h2 class="txtMainColor mb-3 d-none d-md-block">جلسة استشارية</h2>
        <h3 class="txtMainColor mb-3 d-md-none">جلسة استشارية</h3>
        <div>
            <p class="lightFont">
                نحن نعتذر عن عدم توافر الاستشارات الخاصة حاليًا إلا لعملائنا الحاليين، بسبب الطلب العالي والعدد المحدود
                للحصص.
            </p>
            <p class="lightFont">
                برجاء تحديد أي نوع من العملاء أنت:
            </p>
        </div>

        <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="old-tab" data-toggle="tab" data-target="#old" type="button"
                        role="tab" aria-controls="old" aria-selected="true">قديم
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="new-tab" data-toggle="tab" data-target="#new" type="button"
                        role="tab" aria-controls="new" aria-selected="false">جديد
                </button>
            </li>
        </ul>
        <div class="tab-content pt-4" id="myTabContent">
            <div class="tab-pane fade show active" id="old" role="tabpanel" aria-labelledby="old-tab">
                <a class="bookBtn d-block bgMainColor text-light" target="_blank" [href]="bookingLink">احجز الآن</a>
                <small class="text-danger">
                    يرجى ملاحظة أنه يمكن الحجز عبر الرابط أعلاه فقط إذا كنت قد اخذت حصصاً مسبقاً وتريد استكمال علاجك.
                    نشكركم على تفهمكم ونتطلع إلى خدمتكم في المستقبل.
                </small>
            </div>
            <div class="tab-pane fade" id="new" role="tabpanel" aria-labelledby="new-tab">
                <form *ngIf="!isSubmittedSuccessfully" [formGroup]="form">
                    <div class="mb-3 text-right">
                        <input formControlName="email" type="email" class="form-control"
                               placeholder="البريد الإلكتروني">
                        <div *ngIf="
                                      (form.controls.email.invalid && isSubmitted) ||
                                      form.controls.email.dirty ||
                                      form.controls.email.touched
                                    ">
                            <small class="text-danger fw-bold"
                                   *ngIf="form.controls.email.hasError('required')">
                                {{direction == 'ltr' ? 'Email is required' : 'البريد الإلكتروني مطلوب'}}
                            </small>
                            <small class="text-danger fw-bold"
                                   *ngIf="form.controls.email.hasError('email')">
                                {{direction == 'ltr' ? 'Email is invalid' : 'البريد الإلكتروني غير صالح'}}
                            </small>
                        </div>
                    </div>

                    <div class="mb-3 text-right">
                        <input formControlName="name" type="text" class="form-control" placeholder="الإسم">
                        <div *ngIf="
                                      (form.controls.name.invalid && isSubmitted) ||
                                      form.controls.name.dirty ||
                                      form.controls.name.touched
                                    ">
                            <small class="text-danger fw-bold"
                                   *ngIf="form.controls.name.hasError('required')">
                                {{direction == 'ltr' ? 'Name is required' : 'الإسم مطلوب'}}
                            </small>
                            <small class="text-danger fw-bold"
                                   *ngIf="form.controls.name.hasError('minlength')">
                                {{direction == 'ltr' ? 'Name must be at least 3 characters' : 'الإسم يجب أن يكون 3 أحرف على الأقل'}}
                            </small>
                            <small class="text-danger fw-bold"
                                   *ngIf="form.controls.name.hasError('maxlength')">
                                {{direction == 'ltr' ? 'Name must be at most 30 characters' : 'الإسم يجب أن يكون 30 حرف على الأكثر'}}
                            </small>
                        </div>
                    </div>
                    <div class="mb-3 text-right">
                        <input
                                [placeholder]="direction=='ltr'? 'Country' : 'الدولة ' "
                                class="form-control"
                                list="countries"
                                formControlName="country"
                                name="country"
                        >
                        <datalist id="countries">
                            <option value="" disabled="disabled" selected="selected">اختر الدولة</option>
                            <option
                                    *ngFor="let country of ar_countries"
                                    [value]="country.country_name">{{country.country_name}}
                            </option>
                        </datalist>
                        <div *ngIf="
                                      (form.controls.country.invalid && isSubmitted) ||
                                      form.controls.country.dirty ||
                                      form.controls.country.touched
                                    ">
                            <small class="text-danger fw-bold"
                                   *ngIf="form.controls.country.hasError('required')">
                                {{direction == 'ltr' ? 'Country is required' : 'الدولة مطلوبة'}}
                            </small>
                        </div>
                    </div>
                    <button type="submit" class="bookBtn bgMainColor text-light" (click)="addToWaitList()">
                        <small>الإنضمام إلى قائمة الإنتظار</small>
                    </button>
                </form>
                <section *ngIf="isSubmittedSuccessfully">
                    <img src="https://media1.tenor.com/images/06ae072fb343a704ee80c2c55d2da80a/tenor.gif?itemid=14090897" alt="image">
                </section>
            </div>
        </div>
    </div>
</div>