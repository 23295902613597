import {Component, OnInit} from '@angular/core';
import {environment, ServerApi} from "../../../environments/environment";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CountriesService} from "../../services/countries.service";

@Component({
  selector: 'app-consultation-session',
  templateUrl: './consultation-session.component.html',
  styleUrls: ['./consultation-session.component.css']
})
export class ConsultationSessionComponent implements OnInit {

  constructor(private fb: FormBuilder, private _http: HttpClient, private _snackBar: MatSnackBar, private _countriesService: CountriesService) {
  }

  form: FormGroup;

  bookingLink: string = environment.BOOKING_LINK;

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      country: ['', [Validators.required]],
    })
  }


  direction: string = 'rtl';
  isSubmitted: boolean = false;
  ar_countries = this._countriesService.ar_countries;
  isSubmittedSuccessfully: boolean = false;
  addToWaitList() {
    this.isSubmitted = true;
    console.log(this.form.value)
    this._http.post(ServerApi.PublicRouteTemp + '/reservation-waitlist', this.form.value).subscribe(() => {
        this.openSnackBar('تم إضافة البريد الإلكتروني بنجاح، سيتم إعلامكم بالموعد المتاح قريباً');
        this.form.reset();
        this.isSubmitted = false;
        this.isSubmittedSuccessfully = true;
        setTimeout(() => {
          this.isSubmittedSuccessfully = false;
        }, 4000)
    }, (err) => {
      let msg = 'حدث خطأ ما، يرجى المحاولة لاحقاً';
      console.log(err);
      if(err.error.msg === 'Email already exists in the file.') {
        msg = 'البريد الإلكتروني موجود بالفعل، سيتم إعلامكم بالموعد المتاح قريباً';
      }
      this.openSnackBar(msg);
    });
  }

  openSnackBar(msg) {
    this._snackBar.open(msg, 'إغلاق', {duration: 5000});
  }
}
