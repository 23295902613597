import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Subject } from "rxjs/internal/Subject";
import { ServerApi } from "../../environments/environment";
import * as cryptoJS from 'crypto-js';

@Injectable({
  providedIn: "root",
})
export class UserService {
  user: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) {}
  

  getFile(url){
    return this.http.get(`${url}`, {
      responseType: 'blob' as 'json'});
  }

  getPublicCourse(id): Observable<any> {
    return this.http.get(`${ServerApi.PublicRouteTemp}/courses/public/${id}`);
  }
  cipherEncrypt2(data) {
    const encryptedData = cryptoJS.AES.encrypt(
      data,
      ServerApi.CIPHER_KEY,
    ).toString();
    return encryptedData;
  }

  addPayment(obj): Observable<any> {
    let cryptedObj = this.cipherEncrypt2(JSON.stringify(obj));
    return this.http.post(`${ServerApi.PublicRouteTemp}/subscriptions`,
        { data: cryptedObj}
    );
  }

  addRequestPayment(obj): Observable<any> {
    return this.http.post(
      `${ServerApi.PublicRouteTemp}/subscriptions/request`,
      obj
    );
  }


  getUser(): Observable<any> {
    return this.http.get(`${ServerApi.PublicRouteTemp}/users/account`);
  }

  editUserInfo(user): Observable<any> {
    return this.http.patch(
      `${ServerApi.PublicRouteTemp}/users/infos`, user);
  }
  editUserImage(img): Observable<any> {
    const data = new FormData();
    data.append("image", img);
    return this.http.patch(
      `${ServerApi.PublicRouteTemp}/users/image`,
      data
    );
  }

  getCourse(id): Observable<any> {
    return this.http.get(`${ServerApi.PublicRouteTemp}/courses/user/${id}`);
  }

  getAllCourses(): Observable<any> {
    return this.http.get(`${ServerApi.PublicRouteTemp}/courses/public`);
  }

  getAllRequests() {
    return this.http.get(`${ServerApi.PublicRouteTemp}/subscriptions/user/requests`);
  }

  logPaymentDetails(body: object) {
    return this.http.post(`${ServerApi.PublicRouteTemp}/payments/log`, body);
  }
}
export interface File {
  id?: string;
  title: string;
  summary: string;
  url: string;
  chapter_id?: string;
}

export interface Chapter {
  id?: string;
  index: number;
  name: string;
  desc: string;
  course_id?: string;
  files?: Array<File>;
}
