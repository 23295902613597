<div class="bgMainColor footer-clean py-5 container-fluid">
    <footer>
        <div class="container">
            <div class="row justify-content-center" style="text-align: start;">
                <div class="col-lg-4 item social col-md-6 item">
                    <div class="logo">
                        <img class="logoimg" src="../../../assets/images/logo.png" alt="logo">
                    </div>
                    <a class="linkIcon" href="https://www.facebook.com/Mohamed-Nadjmo-%D9%85%D8%AD%D9%85%D8%AF-%D9%86%D8%AC%D9%85%D9%88-989906337859301" aria-label="facebook">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                    <a class="linkIcon" href="https://www.instagram.com/mohamed_nadjmo/" aria-label="instagram">
                        <i class="fab fa-instagram"></i>
                    </a>
                    <a class="linkIcon" href="https://www.youtube.com/channel/UCPwnWpoyhke7kvOsVdlnuSA" aria-label="youtube">
                        <i class="fab fa-youtube"></i>
                    </a>
                    <p *ngIf="direction=='ltr'" class="copyright">© 2022 Brave Spirit. All rights reserved.</p>
                    <p *ngIf="direction=='rtl'" class="copyright text-light">كل الحقوق محفوظة <span>© {{currentYear}}  Brave Spirit</span></p>
                    <p>
                        <a routerLink="/terms">
                            <small *ngIf="direction=='rtl'">الشروط والأحكام</small>
                        </a>|<a routerLink="/privacy-policy">
                            <small *ngIf="direction=='rtl'">سياسة الخصوصية</small>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </footer>
</div>