import { HttpHeaders } from "@angular/common/http";
import { ElementRef } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { environment, ServerApi } from "src/environments/environment";
import { Subject } from "rxjs/internal/Subject";
import { ToastDialogComponent } from "../components/toast-dialog/toast-dialog.component";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "@angular/material/dialog";
var UserAuthService = /** @class */ (function () {
    function UserAuthService(http, router, dialog) {
        var _this = this;
        this.http = http;
        this.router = router;
        this.dialog = dialog;
        this._accessToken = "AccessTokenUser";
        this._refreshToken = "RefreshTokenUser";
        this.isLoggedIn = new BehaviorSubject(this.accessToken);
        this.userId = new Subject();
        this.source = new BehaviorSubject("ltr");
        this.dir = this.source.asObservable();
        gapi.load('auth2', function () {
            _this.auth2 = gapi.auth2.init({ client_id: environment.GOOGLE_OAUTH_CLIENT_ID });
        });
    }
    UserAuthService.prototype.changeSource = function (value) {
        this.source.next(value);
    };
    Object.defineProperty(UserAuthService.prototype, "accessToken", {
        get: function () {
            return localStorage.getItem(this._accessToken);
        },
        set: function (value) {
            localStorage.setItem(this._accessToken, value);
            this.userId.next(value);
            this.isLoggedIn.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserAuthService.prototype, "loggedUserId", {
        get: function () {
            try {
                var user = JSON.parse(localStorage.getItem("user"));
                return user._id;
            }
            catch (Error) {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserAuthService.prototype, "user", {
        get: function () {
            try {
                var user = JSON.parse(localStorage.getItem("user"));
                return user;
            }
            catch (Error) {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    UserAuthService.prototype.userLogin = function (user) {
        return this.http.post(ServerApi.PublicRouteTemp + "/login", user);
    };
    UserAuthService.prototype.userGoogleLogin = function () {
        return this.auth2.signIn();
    };
    UserAuthService.prototype.registerGoogle = function (user) {
        return this.http.post(ServerApi.PublicRouteTemp + "/register/google", { token: user.getAuthResponse(true).id_token });
    };
    UserAuthService.prototype.loginGoogle = function () {
        if (this.auth2.isSignedIn.get()) {
            var profile = this.auth2.currentUser.get().getBasicProfile();
            var user = {
                email: profile.getEmail(),
                google_id: profile.getId()
            };
            return this.http.post(ServerApi.PublicRouteTemp + "/login/google", user);
        }
        else
            return null;
    };
    UserAuthService.prototype.register = function (user) {
        return this.http.post(ServerApi.PublicRouteTemp + "/register", user);
    };
    UserAuthService.prototype.saveData = function (result) {
        if (!result.user) {
            if (this.auth2.isSignedIn.get()) {
                var profile = this.auth2.currentUser.get().getBasicProfile();
                result.user = {
                    firstname: profile.getGivenName(),
                    lastname: profile.getFamilyName() ? profile.getFamilyName() : profile.getName(),
                    email: profile.getEmail(),
                    _id: profile.getId()
                };
            }
        }
        localStorage.setItem("user", JSON.stringify(result.user));
        this.accessToken = result.accesstoken;
        localStorage.setItem(this._refreshToken, result.refreshtoken);
    };
    UserAuthService.prototype.removeTokens = function () {
        localStorage.removeItem(this._accessToken);
        localStorage.removeItem("user");
        this.isLoggedIn.next(null);
        this.userId.next(null);
        localStorage.removeItem(this._refreshToken);
    };
    UserAuthService.prototype.refreshTheToken = function () {
        var _this = this;
        return this.http
            .post(ServerApi.PublicRouteTemp + "/refreshtoken", {
            refreshtoken: localStorage.getItem(this._refreshToken),
        })
            .pipe(tap(function (res) {
            _this.accessToken = res.accessToken;
        }), catchError(function (error) {
            // this.signUserOut();
            return of(error);
        }));
    };
    UserAuthService.prototype.signUserOut = function () {
        this.removeTokens();
        this.router.navigate(["/logInClient"]);
    };
    UserAuthService.prototype.logout = function () {
        return this.http.get(ServerApi.PublicRouteTemp + "/logout");
    };
    UserAuthService.prototype.resetPassword = function (email) {
        return this.http.post(ServerApi.PublicRouteTemp + "/forget", { email: email });
    };
    UserAuthService.prototype.emailConfirm = function (token) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
        });
        return this.http.get(ServerApi.PublicRouteTemp + "/verify", { headers: headers });
    };
    UserAuthService.prototype.handleLoginAndRegisterError = function (error) {
        var msg = 'خطأ في البريد الإلكتروني أو كلمة المرور';
        if (error.error.error === "Email already exists") {
            msg = 'البريد الإلكتروني موجود مسبقا';
        }
        else if (['user not found', 'User not found'].includes(error.error.error)) {
            msg = 'البريد الإلكتروني غير موجود';
        }
        else if (error.error.error === 'User account not verified yet') {
            msg = 'لم يتم تفعيل الحساب بعد برجاء التوجه للبريد الإلكتروني لتفعيل الحساب';
        }
        return msg;
    };
    UserAuthService.prototype.openDialog = function (message, isActivateAccount, email) {
        if (isActivateAccount === void 0) { isActivateAccount = false; }
        if (email === void 0) { email = ''; }
        return this.dialog.open(ToastDialogComponent, {
            width: '400px',
            direction: 'rtl',
            data: {
                message: message,
                isActivateAccount: isActivateAccount,
                email: email
            }
        });
    };
    UserAuthService.prototype.mustMatch = function (controlName, matchingControlName) {
        return function (formGroup) {
            var control = formGroup.controls[controlName];
            var matchingControl = formGroup.controls[matchingControlName];
            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            }
            else {
                matchingControl.setErrors(null);
            }
        };
    };
    UserAuthService.prototype.resetPasswordWithToken = function (password, token) {
        return this.http.post(ServerApi.PublicRouteTemp + "/reset-password", { password: password }, {
            headers: {
                Authorization: "Bearer " + token
            }
        });
    };
    UserAuthService.prototype.updateUserInfo = function (value) {
        return this.http.put(ServerApi.PublicRouteTemp + "/users/update-info", value);
    };
    UserAuthService.prototype.updateUserPassword = function (value) {
        return this.http.put(ServerApi.PublicRouteTemp + "/users/update-password", value);
    };
    UserAuthService.prototype.togglePasswordVisibility = function (eyeIcon, passwordInputType) {
        if (passwordInputType === "password") {
            passwordInputType = "text";
            eyeIcon.nativeElement.classList.remove("fa-eye");
            eyeIcon.nativeElement.classList.add("fa-eye-slash");
        }
        else {
            passwordInputType = "password";
            eyeIcon.nativeElement.classList.remove("fa-eye-slash");
            eyeIcon.nativeElement.classList.add("fa-eye");
        }
        return passwordInputType;
    };
    UserAuthService.prototype.sendActivationCodeAgain = function (user) {
        return this.http.post(ServerApi.PublicRouteTemp + "/get-new-activation-code", user);
    };
    UserAuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserAuthService_Factory() { return new UserAuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.MatDialog)); }, token: UserAuthService, providedIn: "root" });
    return UserAuthService;
}());
export { UserAuthService };
