import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserAuthService } from 'src/app/services/user-auth.service';
import { UserService } from 'src/app/services/user.service';
import { ServerApi } from 'src/environments/environment';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";



@Component({
  selector: 'app-view-client-profile',
  templateUrl: './view-client-profile.component.html',
  styleUrls: ['./view-client-profile.component.css']
})
export class ViewClientProfileComponent implements OnInit {

  publicUrl = ServerApi.PublicRouteTemp;

  @ViewChild("user_image", { static: false }) user_image: ElementRef;
  picSrc: String | ArrayBuffer = "";
  loading: boolean = false;


  constructor(
    private _snackBar: MatSnackBar,
    private userService: UserService,
    private router: Router, private userAuthService: UserAuthService,
    private _formBuilder: FormBuilder
  ) { }

  openSnackBar(msg) {
    this._snackBar.open(msg, 'إغلاق', {duration: 10000});
  }

  public id = "";
  public user: any = null;
  direction='rtl';
  total_prices = 0;

  infoForm: FormGroup;
  passwordForm: FormGroup;

  ngOnInit() {
      const {firstname, lastname, phone} = JSON.parse(localStorage.getItem('user'));
      this.infoForm = this._formBuilder.group({
          firstname: [firstname, [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
          lastname: [lastname, [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
          phone:  [phone, [Validators.minLength(3), Validators.maxLength(20), Validators.pattern('^[0-9]*$')]],
          password: ['', [Validators.required]],
      });

      this.passwordForm = this._formBuilder.group({
        password: ['', [Validators.required]],
        newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      },
          {validator: this.userAuthService.mustMatch('newPassword', 'confirmPassword')}
      );


    this.direction = localStorage.getItem('direction');
    this.userAuthService.source.subscribe((value) => {
      this.direction = value;
    });

    this.id = this.userAuthService.loggedUserId;

    this.userService
      .getUser().subscribe(
        (res: any) => {
          let user;
          user = res.user;
          user.payments = res.subscriptions;
          user.courses = user.payments.map((sub) => {
            return sub.course;
          });
          user.requests = res.requests;
          user.requestedCourses = user.requests.map((req) => {
            return req.course;
          });
          this.picSrc = this.publicUrl + '/users/image/'+ user._id || '/assets/images/default.png';
          user.payments.map((p) => {this.total_prices = p.price.euro;})
          // user.courses = user.courses.map((course) => {
          //     console.log(course);
          //   course = this.publicUrl + '/courses/cover/' + course;
          //   return course;
          // });
            // console.log(user.courses)
          // console.log(user);
            user.courses = res.courses;
          this.user = user;
        },
        () => {
          this.router.navigate([`/`]);
        }
      );
  }

  changePic(files) {
    let tempPic;

    if (files.length == 0) return;
    const reader = new FileReader();

    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      tempPic = reader.result;
      this.userService
      .editUserImage(files[0]).subscribe(
        () => {
          this.picSrc = tempPic;
          this.openSnackBar("تم تعديل الصورة");
        },
        () => {
          this.openSnackBar("حدث خطأ");
        }
      );
    };
  }
    isFormInfoSubmitted = false;
    submitUpdateInfoForm() {
        this.isFormInfoSubmitted = true;
        if(this.infoForm.invalid) return;
        this.loading = true;
        this.userAuthService.updateUserInfo(this.infoForm.value).subscribe(
            (res: any) => {
                let msg = 'تم تعديل المعلومات بنجاح';
                localStorage.setItem("user", JSON.stringify(res.user));
                this.openSnackBar(msg);
                this.infoForm.controls['password'].setValue('');
                this.infoForm.controls['password'].markAsUntouched();
                this.infoForm.controls['password'].markAsPristine();
                this.isFormInfoSubmitted = false;
                this.loading = false;
            },
            (error) => {
                let msg = error.error.error;
                this.openSnackBar(this.direction == 'rtl' ? 'كلمة السر غير صحيحة' : msg);
                this.infoForm.controls['password'].setValue('');
                this.isFormInfoSubmitted = false;
                this.loading = false;
            }
        );
    }

    isFormPasswordSubmitted = false;
    submitPasswordForm() {
        this.isFormPasswordSubmitted = true;
        if(this.passwordForm.invalid) return;
        this.loading = true;
        delete this.passwordForm.value.confirmPassword;
        this.userAuthService.updateUserPassword(this.passwordForm.value).subscribe(
            () => {
                let msg = 'تم تعديل كلمة المرور بنجاح';
                this.openSnackBar(msg);
                this.passwordForm.reset();
                this.isFormPasswordSubmitted = false;
                this.loading = false;
            },
            (error) => {
                let msg = error.error.error;
                this.openSnackBar(this.direction == 'rtl' ? 'كلمة السر الحالية غير صحيحة' : msg);
                this.passwordForm.reset();
                this.isFormPasswordSubmitted = false;
                this.loading = false;
            }
        );
    }
}
