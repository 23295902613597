import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnInit, QueryList } from "@angular/core";
import Stepper from "bs-stepper";
import { PlyrComponent } from "ngx-plyr";
import { environment, ServerApi } from "src/environments/environment";
var ClientCourseViewComponent = /** @class */ (function () {
    function ClientCourseViewComponent(_snackBar, _activeRouter, userService, userAuthService, _router, _fontsService, _publicServices) {
        this._snackBar = _snackBar;
        this._activeRouter = _activeRouter;
        this.userService = userService;
        this.userAuthService = userAuthService;
        this._router = _router;
        this._fontsService = _fontsService;
        this._publicServices = _publicServices;
        this.publicUrl = ServerApi.PublicRouteTemp;
        this.paymentMethod = "";
        this.total = 0;
        this.paymentSuccess = null;
        this.paymentFailed = null;
        this.freeAccessSuccess = null;
        this.freeAccessFailed = null;
        this.canViewCourse = false;
        this.loggedIn = false;
        this.loading = false;
        this.isAlreadySubscribed = true;
        this.canAskFreeAccess = true;
        this.isLoaded = true;
        this.isFreeSubscribeBtnDisabled = false;
        this.paymentHandler = null;
        this.success = false;
        this.course = null;
        this.chapters = [];
        this.error = null;
        this.direction = 'rtl';
        this.selectedFileIndex = null;
        this.selectedChapterIndex = null;
        this.watchedFilesCount = 0;
        this.currency = 'EUR ';
        this.isSubscribeProcess = false;
        this.drivePattern = /^https?:\/\/drive\.google\.com\/(file\/d\/|open\?id=)([a-zA-Z0-9_-]+)([?&]usp=sharing)?/;
        this.youTubePattern = /^https?:\/\/(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)([?&]t=[0-9]+)?/;
        this.isDriveUrl = false;
        this.isYouTubeUrl = true;
        this.isNormalText = false;
        this.showLoader = false;
        this.selectedFileTitle = null;
        this.CcpFileError = "";
        this.requests = [];
        this.pausedCourses = ['6472286f37d7451351aea2fe'];
    }
    ClientCourseViewComponent.prototype.ngAfterViewInit = function () {
        this.accordion.changes.subscribe(function (comps) {
            comps.first && comps.first.openAll();
        });
    };
    ClientCourseViewComponent.prototype.openSnackBar = function (msg) {
        this._snackBar.open(msg, 'إغلاق', { duration: 5000 });
    };
    ClientCourseViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        var paymentIndent = new URLSearchParams(window.location.search).get("payment_intent");
        if (paymentIndent) {
            this.isSubscribeProcess = true;
        }
        //About stripe
        this.invokeStripe();
        this.getRequests();
        $.getScript("../../../assets/imagesSlideshowJs/dropdown.js");
        this.direction = localStorage.getItem('direction');
        this.userAuthService.source.subscribe(function (value) {
            _this.direction = value;
        });
        this._activeRouter.params.subscribe(function (params) { return (_this.courseId = params.id); });
        this.userId = this.userAuthService.loggedUserId;
        if (this.userId)
            this.loggedIn = true;
        this.user = this.userAuthService.user;
        // http://localhost:4200/clientCourseView/6472286f37d7451351aea2fe?payment_intent=pi_3NH7GjCmC3ZwNXOu0pbulvIl&payment_intent_client_secret=pi_3NH7GjCmC3ZwNXOu0pbulvIl_secret_XHrpxGdbg3zJSEQj3KL37NHCS&redirect_status=succeeded
        this.getCurrentCourse(this.courseId);
        this.stepper = new Stepper(document.querySelector("#stepper1"), {
            linear: false,
            animation: true,
        });
    };
    ClientCourseViewComponent.prototype.getCurrentCourse = function (courseId) {
        var _this = this;
        this.userService.getCourse(courseId).subscribe(function (res) {
            _this.canViewCourse = true;
            _this.course = res;
            _this.updateCourseDescription();
            _this.chapters = _this.course.chapters;
            if (localStorage['selectedFile' + _this.course._id]) {
                var file = JSON.parse(localStorage['selectedFile' + _this.course._id]);
                _this.changeVideoUrl(file, file.selectedChapterIndex, file.selectedFileIndex);
            }
            else {
                if (_this.chapters && _this.chapters && _this.chapters[0].files) {
                    if (_this.isAlreadySubscribed) {
                        var fileUrl = _this.chapters[0].files[0].link;
                        _this.checkAndSetUrl(fileUrl);
                    }
                    _this.selectedFileTitle = _this.chapters[0].files[0].title.ar;
                }
                if (_this.isAlreadySubscribed) {
                    _this.selectedFileIndex = _this.selectedChapterIndex = 0;
                }
            }
            //price
            _this.total =
                _this.course.price.euro -
                    ((_this.course.discount ? _this.course.discount : 0) * _this.course.price.euro) / 100;
            _this.total = _this.total.toFixed(2);
        }, function (error) {
            _this.isAlreadySubscribed = false;
            _this.error = error;
            _this.getPublicCourse();
        });
    };
    ClientCourseViewComponent.prototype.refreshCourse = function () {
        var _this = this;
        this.userService.getCourse(this.courseId).subscribe(function (res) {
            _this.canViewCourse = _this.isAlreadySubscribed = true;
            _this.course = res;
            _this.updateCourseDescription();
            _this.chapters = _this.course.chapters;
            _this.chapterBtn.nativeElement.click();
            _this.changeVideoUrl(_this.course.chapters[0].files[0]);
        }, function (error) {
            _this.error = error;
        });
    };
    ClientCourseViewComponent.prototype.goto = function (url) {
        var _this = this;
        if (!url)
            return;
        this.downloading = url;
        this.userService.getFile(url).subscribe(function (res) {
            var dataType = res.type;
            var binaryData = [];
            binaryData.push(res);
            var downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
            if (url.split("-")[3]) {
                downloadLink.setAttribute('download', url.split("-")[3]);
            }
            downloadLink.target = "_blank";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            _this.downloading = null;
        }, function () {
            _this.downloading = null;
        });
    };
    ClientCourseViewComponent.prototype.next = function () {
        this.stepper.next();
    };
    ClientCourseViewComponent.prototype.getPublicCourse = function () {
        var _this = this;
        this.isLoaded = false;
        this.userService.getPublicCourse(this.courseId).subscribe(function (res) {
            _this.course = res;
            if (_this.course.price.euro != 0) {
                _this._publicServices.convertCurrency({ amount: res.price.euro, courseCurrency: 'EUR' })
                    .subscribe(function (res) {
                    _this.originalEuroPriceBeforeConversion = _this.course.price.euro;
                    _this.course.price.euro = res.data.convertedAmount;
                    _this.currency = res.data.clientCurrency;
                    _this.updateCourseDescriptionAndSetPrice();
                }, function (err) {
                    console.error(err);
                    _this.updateCourseDescriptionAndSetPrice();
                });
            }
            else {
                _this.updateCourseDescriptionAndSetPrice();
            }
        }, function (error) {
            _this.error = error;
        });
    };
    ClientCourseViewComponent.prototype.changeVideoUrl = function (file, chapterIndex, fileIndex) {
        var _this = this;
        if (chapterIndex === void 0) { chapterIndex = 0; }
        if (fileIndex === void 0) { fileIndex = 0; }
        window.scrollTo(0, 400);
        this.selectedChapterIndex = chapterIndex;
        this.selectedFileIndex = fileIndex;
        if (!this.isAlreadySubscribed) {
            return;
        }
        this.measureWatchedFilesCount(chapterIndex, fileIndex);
        var url = file.link;
        this.selectedFileTitle = file.title.ar;
        this.showLoader = true;
        this.sleep(1000).then(function () {
            _this.checkAndSetUrl(url);
            _this.showLoader = false;
            file.selectedFileIndex = _this.selectedFileIndex = fileIndex;
            file.selectedChapterIndex = _this.selectedChapterIndex = chapterIndex;
            file.watchedFilesCount = _this.watchedFilesCount;
            localStorage['selectedFile' + _this.courseId] = JSON.stringify(file);
        });
    };
    ClientCourseViewComponent.prototype.sleep = function (ms) {
        return new Promise(function (resolve) { return setTimeout(resolve, ms); });
    };
    ClientCourseViewComponent.prototype.submitFreeAccess = function () {
        var _this = this;
        this.freeAccessSuccess = null;
        this.freeAccessFailed = null;
        if (!this.reasonFreeAccess.nativeElement.value)
            return;
        var freeAccess = new FormData();
        var data = {
            request: {
                type: "help",
                reason: this.reasonFreeAccess.nativeElement.value,
            },
            course: this.course._id
        };
        var requestAsFile = new Blob([JSON.stringify(data)], {
            type: 'application/json',
        });
        freeAccess.append("data", requestAsFile, "data");
        this.userService.addRequestPayment(freeAccess).subscribe(function () {
            _this.freeAccessSuccess = "success";
            _this.openSnackBar("تمت العملية");
            _this.canAskFreeAccess = false;
        }, function (error) {
            _this.freeAccessFailed = error;
            _this.openSnackBar("حدث خطأ");
        });
    };
    ClientCourseViewComponent.prototype.addFreeCourse = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var freePayment;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isFreeSubscribeBtnDisabled = true;
                        if (!!this.loggedIn) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._router.navigateByUrl('/logInClient')];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                    case 2:
                        this.loading = true;
                        freePayment = {
                            method: "free",
                            state: "completed",
                            course: this.course._id,
                        };
                        this.userService.addPayment(freePayment).subscribe(function () {
                            _this.paymentMethod = null;
                            _this.loading = false;
                            _this.handleSuccess(true);
                        }, function (error) {
                            _this.paymentFailed = error;
                            _this.loading = false;
                            _this.openSnackBar("حدث خطأ");
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ClientCourseViewComponent.prototype.emptyVars = function () {
        this.CcpFileError = "";
    };
    ClientCourseViewComponent.prototype.setUpPaypalPayment = function () {
        var _this = this;
        paypal
            .Buttons({
            createOrder: function (data, actions) {
                return actions.order.create({
                    purchase_units: [
                        {
                            description: _this.course.name.ar,
                            amount: {
                                value: _this.originalTotalPriceForPayments
                            }
                        }
                    ]
                });
            },
            onApprove: function (data, actions) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var order, payment;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.paymentMethod = 'paypal';
                            this.paymentSuccess = null;
                            this.paymentFailed = null;
                            return [4 /*yield*/, actions.order.capture()];
                        case 1:
                            order = _a.sent();
                            payment = {
                                course: this.course._id,
                                order_id: order.id,
                                seller_transaction_id: order.purchase_units[0].payments.captures[0].id,
                                method: "paypal",
                                state: "completed"
                            };
                            this.userService.addPayment(payment).subscribe(function () {
                                _this.paymentSuccess = order.id;
                                _this.closeBtnForPaymentModal.nativeElement.click();
                                _this._router.navigateByUrl("/payment/success/" + _this.courseId);
                                // this.handleSuccess();
                            }, function (error) {
                                _this.paymentFailed = error;
                                _this.openSnackBar("حدث خطأ في الدفع يرجى التواصل معنا إذا تم سحب المبلغ من حسابكم");
                                _this.thankyoutoggle.nativeElement.click();
                            });
                            return [2 /*return*/];
                    }
                });
            }); },
            onError: function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var payment;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    payment = {
                        course: this.course._id,
                        method: "paypal",
                        state: "error"
                    };
                    this.userService.addPayment(payment).subscribe(function () {
                        _this.closeBtnForPaymentModal.nativeElement.click();
                        _this._router.navigateByUrl("/payment/error/" + _this.courseId);
                        // this.openSnackBar("حدث خطأ في الدفع يرجى التواصل معنا إذا تم سحب المبلغ من حسابكم");
                    }, function () {
                        _this.closeBtnForPaymentModal.nativeElement.click();
                        _this._router.navigateByUrl("/payment/error/" + _this.courseId);
                        // this.openSnackBar("حدث خطأ في الدفع يرجى التواصل معنا إذا تم سحب المبلغ من حسابكم");
                    });
                    return [2 /*return*/];
                });
            }); }
        })
            .render(this.paypalElement.nativeElement);
    };
    ClientCourseViewComponent.prototype.sendMessage = function () {
    };
    ClientCourseViewComponent.prototype.files_count = function () {
        var count = 0;
        this.course.chapters.forEach(function (chapter) {
            count += chapter.files.length;
        });
        return count;
    };
    ClientCourseViewComponent.prototype.attachments_count = function () {
        var count = 0;
        this.course.chapters.forEach(function (chapter) {
            chapter.files.forEach(function (file) {
                if (file.attachments)
                    count += file.attachments.length;
            });
        });
        return count;
    };
    ClientCourseViewComponent.prototype.checkIfCanGetFree = function () {
        return !!(this.course.price.euro !== 0 && this.course.get_free);
    };
    ClientCourseViewComponent.prototype.getRequests = function () {
        var _this = this;
        this.userService.getAllRequests().subscribe(function (res) {
            _this.requests = res.map(function (item) { return item.course; });
            var idIsExist = _this.requests.find(function (id) { return id === _this.courseId; });
            if (idIsExist) {
                _this.isAlreadySubscribed = true;
            }
        }, console.error);
    };
    ClientCourseViewComponent.prototype.checkIsLogged = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.loggedIn) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._router.navigateByUrl('/logInClient')];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ClientCourseViewComponent.prototype.invokeStripe = function () {
        var _this = this;
        if (!window.document.getElementById('stripe-script')) {
            var script = window.document.createElement('script');
            script.id = 'stripe-script';
            script.type = 'text/javascript';
            script.src = 'https://checkout.stripe.com/checkout.js';
            script.onload = function () {
                _this.paymentHandler = window.StripeCheckout.configure({
                    key: environment.STRIPE_PUBLISH_KEY,
                    locale: 'auto',
                    token: function () {
                    },
                });
            };
            window.document.body.appendChild(script);
        }
    };
    ClientCourseViewComponent.prototype.isFilePdf = function (attachment) {
        return attachment.match(/\.pdf$/);
    };
    ClientCourseViewComponent.prototype.isFileAudio = function (attachment) {
        return attachment.match(/\.mp3$/);
    };
    ClientCourseViewComponent.prototype.isFileImg = function (attachment) {
        return attachment.match(/\.(png|jpg|jpeg)$/);
    };
    ClientCourseViewComponent.prototype.isFileDoc = function (attachment) {
        return attachment.match(/\.docx$/);
    };
    ClientCourseViewComponent.prototype.handleSuccess = function (isFree) {
        if (isFree === void 0) { isFree = false; }
        this.refreshCourse();
        this.openSnackBar("تمت عملية الشراء");
        if (!isFree) {
            this.thankyoutoggle.nativeElement.click();
        }
    };
    ClientCourseViewComponent.prototype.getNextFile = function () {
        if (this.selectedFileIndex < this.course.chapters[this.selectedChapterIndex].files.length - 1) {
            this.selectedFileIndex++;
        }
        else if (this.selectedFileIndex === this.course.chapters[this.selectedChapterIndex].files.length - 1
            && this.selectedChapterIndex !== this.watchedFilesCount) {
            this.selectedFileIndex = 0;
            this.selectedChapterIndex++;
        }
        else {
            return;
        }
        this.changeVideoUrl(this.course.chapters[this.selectedChapterIndex].files[this.selectedFileIndex], this.selectedChapterIndex, this.selectedFileIndex);
    };
    ClientCourseViewComponent.prototype.getPrevFile = function () {
        if (this.selectedFileIndex > 0) {
            this.selectedFileIndex--;
        }
        else if (this.selectedFileIndex === 0 && this.selectedChapterIndex !== 0) {
            this.selectedChapterIndex--;
            this.selectedFileIndex = this.course.chapters[this.selectedChapterIndex].files.length - 1;
        }
        else {
            return;
        }
        this.changeVideoUrl(this.course.chapters[this.selectedChapterIndex].files[this.selectedFileIndex], this.selectedChapterIndex, this.selectedFileIndex);
    };
    ClientCourseViewComponent.prototype.measureWatchedFilesCount = function (chapterIndex, fileIndex) {
        var _this = this;
        this.watchedFilesCount = 0;
        this.course.chapters.forEach(function (chapter, index) {
            if (index < chapterIndex) {
                _this.watchedFilesCount += chapter.files.length;
            }
            else if (index === chapterIndex) {
                _this.watchedFilesCount += fileIndex;
            }
        });
    };
    ClientCourseViewComponent.prototype.checkAndSetUrl = function (url) {
        this.isYouTubeUrl = this.isDriveUrl = this.isNormalText = false;
        if (this.drivePattern.test(url)) {
            this.isDriveUrl = true;
            var id = url.match(this.drivePattern)[2];
            url = environment.DRIVE_CONSTANT_URL + id;
        }
        else if (this.youTubePattern.test(url)) {
            this.isYouTubeUrl = true;
        }
        else {
            this.isNormalText = true;
        }
        this.fileUrl = url;
    };
    ClientCourseViewComponent.prototype.updateCourseDescription = function () {
        this.course.description['ar'] = this._fontsService.convertTheFonts(this.course.description['ar'])
            .replace(/<ul>/g, '<ul style="padding-right: 30px;">')
            .replace(/<ol>/g, '<ol style="padding-right: 30px;">');
    };
    ClientCourseViewComponent.prototype.updateCourseDescriptionAndSetPrice = function () {
        this.updateCourseDescription();
        this.total =
            this.course.price.euro -
                ((this.course.discount ? this.course.discount : 0) * this.course.price.euro) / 100;
        this.total = this.total.toFixed(2);
        this.originalTotalPriceForPayments = this.originalEuroPriceBeforeConversion -
            ((this.course.discount ? this.course.discount : 0) * this.originalEuroPriceBeforeConversion) / 100;
        if (isNaN(this.originalTotalPriceForPayments)) {
            this.originalTotalPriceForPayments = this.total;
        }
        this.isLoaded = true;
        this.checkPaypalAvailability();
    };
    ClientCourseViewComponent.prototype.checkPaypalAvailability = function (attempts, maxAttempts, interval) {
        var _this = this;
        if (attempts === void 0) { attempts = 0; }
        if (maxAttempts === void 0) { maxAttempts = 3; }
        if (interval === void 0) { interval = 1000; }
        if (typeof paypal !== 'undefined' && typeof paypal.Buttons === 'function') {
            this.setUpPaypalPayment();
        }
        else {
            // PayPal SDK is not yet available
            console.log('PayPal SDK not yet available');
            if (attempts < maxAttempts) {
                // Retry after a certain interval
                setTimeout(function () {
                    _this.checkPaypalAvailability(attempts + 1, maxAttempts, interval);
                }, interval);
            }
            else {
                // Maximum attempts reached, handle error or fallback to an alternative
                console.error('PayPal SDK could not be loaded');
            }
        }
    };
    ClientCourseViewComponent.prototype.isPausedCourse = function () {
        return this.pausedCourses.includes(this.courseId);
    };
    return ClientCourseViewComponent;
}());
export { ClientCourseViewComponent };
