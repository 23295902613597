import { HttpClient, HttpHeaders } from "@angular/common/http";
import {ElementRef, Injectable} from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import {environment, ServerApi} from "src/environments/environment";
import { Subject } from "rxjs/internal/Subject";
import {FormGroup} from "@angular/forms";
import {ToastDialogComponent} from "../components/toast-dialog/toast-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
export class UserAuthService {
  private readonly _accessToken = "AccessTokenUser";
  private readonly _refreshToken = "RefreshTokenUser";

  isLoggedIn = new BehaviorSubject(this.accessToken);

  userId: Subject<any> = new Subject<any>()
  
  source = new BehaviorSubject<string>("ltr");
  dir = this.source.asObservable();

  // google auth
  private auth2: gapi.auth2.GoogleAuth;

  changeSource(value) {
    this.source.next(value);
  }
  constructor(private http: HttpClient, private router: Router, private dialog: MatDialog) {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({client_id: environment.GOOGLE_OAUTH_CLIENT_ID});
    });
  }

  set accessToken(value) {
    localStorage.setItem(this._accessToken, value);
    this.userId.next(value)
    this.isLoggedIn.next(value);
  }

  get accessToken() {
    return localStorage.getItem(this._accessToken);
  }


  get loggedUserId() {
    try {
      let user = JSON.parse(localStorage.getItem("user"));
      return user._id;
    } catch (Error) {
      return null;
    }
  }

  get user() {
    try {
      let user = JSON.parse(localStorage.getItem("user"));
      return user;
    } catch (Error) {
      return null;
    }
  }

  userLogin(user: { email: string; password: string }): Observable<any> {
    return this.http.post(`${ServerApi.PublicRouteTemp}/login`, user);
  }

  userGoogleLogin(): Promise<gapi.auth2.GoogleUser> {
    return this.auth2.signIn();
  }

  registerGoogle(user): Observable<any> {
    return this.http.post(`${ServerApi.PublicRouteTemp}/register/google`, { token: user.getAuthResponse(true).id_token });
  }

  loginGoogle(): Observable<any> {
    if (this.auth2.isSignedIn.get()) {
      var profile = this.auth2.currentUser.get().getBasicProfile();
      let user = {
        email: profile.getEmail(),
        google_id: profile.getId()
      }
      return this.http.post(`${ServerApi.PublicRouteTemp}/login/google`, user);
    }else return null;
  }
  register(user): Observable<any> {
    return this.http.post(`${ServerApi.PublicRouteTemp}/register`, user);
  }
  saveData(result) {
    if(!result.user){
      if (this.auth2.isSignedIn.get()) {
        var profile = this.auth2.currentUser.get().getBasicProfile();
        result.user = {
          firstname: profile.getGivenName(),
          lastname: profile.getFamilyName() ? profile.getFamilyName() : profile.getName(),
          email: profile.getEmail(),
          _id: profile.getId()
        }
      }
    }
    localStorage.setItem("user", JSON.stringify(result.user));
    this.accessToken = result.accesstoken;
    localStorage.setItem(this._refreshToken, result.refreshtoken);
  }

  removeTokens() {
    localStorage.removeItem(this._accessToken);
    localStorage.removeItem("user");
    this.isLoggedIn.next(null);
    this.userId.next(null);
    localStorage.removeItem(this._refreshToken);
  }

  refreshTheToken() {
    return this.http
      .post<any>(`${ServerApi.PublicRouteTemp}/refreshtoken`, {
        refreshtoken: localStorage.getItem(this._refreshToken),
      })
      .pipe(
        tap((res) => {
          this.accessToken = res.accessToken;
        }),
        catchError((error) => {
          // this.signUserOut();
          return of(error);
        })
      );
  }

  signUserOut() {
    this.removeTokens();
    this.router.navigate([`/logInClient`]);
  }

  logout() {
    return this.http.get(`${ServerApi.PublicRouteTemp}/logout`);
  }


  resetPassword(email): Observable<any> {
    return this.http.post(`${ServerApi.PublicRouteTemp}/forget`, { email });
  }
  emailConfirm(token){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    return this.http.get(`${ServerApi.PublicRouteTemp}/verify`, {headers});
  }

  handleLoginAndRegisterError(error: any) {
    let msg = 'خطأ في البريد الإلكتروني أو كلمة المرور';
    if (error.error.error === "Email already exists") {
      msg = 'البريد الإلكتروني موجود مسبقا';
    } else if (['user not found', 'User not found'].includes(error.error.error)) {
      msg = 'البريد الإلكتروني غير موجود';
    } else if (error.error.error === 'User account not verified yet') {
      msg = 'لم يتم تفعيل الحساب بعد برجاء التوجه للبريد الإلكتروني لتفعيل الحساب';
    }
    return msg;
  }

  public openDialog(message: any, isActivateAccount: boolean = false, email: string = '') {
    return this.dialog.open(ToastDialogComponent, {
      width: '400px',
      direction: 'rtl',
      data: {
        message,
        isActivateAccount,
        email
      }
    });
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({mustMatch: true});
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  resetPasswordWithToken(password, token): Observable<any> {
    return this.http.post(`${ServerApi.PublicRouteTemp}/reset-password`, {password}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  updateUserInfo(value: any) {
    return this.http.put(`${ServerApi.PublicRouteTemp}/users/update-info`, value);
  }

  updateUserPassword(value: any) {
    return this.http.put(`${ServerApi.PublicRouteTemp}/users/update-password`, value);
  }

  togglePasswordVisibility(eyeIcon: ElementRef, passwordInputType: any) {
    if (passwordInputType === "password") {
      passwordInputType = "text";
      eyeIcon.nativeElement.classList.remove("fa-eye");
      eyeIcon.nativeElement.classList.add("fa-eye-slash");
    } else {
      passwordInputType = "password";
      eyeIcon.nativeElement.classList.remove("fa-eye-slash");
      eyeIcon.nativeElement.classList.add("fa-eye");
    }
    return passwordInputType;
  }

  sendActivationCodeAgain(user: object) {
    return this.http.post(`${ServerApi.PublicRouteTemp}/get-new-activation-code`, user);
  }
}
