import {Component, Input, OnInit} from '@angular/core';
import {ServerApi} from "../../../environments/environment";

@Component({
  selector: 'app-whatsapp-icon',
  templateUrl: './whatsapp-icon.component.html',
  styleUrls: ['./whatsapp-icon.component.css']
})
export class WhatsappIconComponent implements OnInit {

  constructor() { }

  contactNumber = ServerApi.CONTACT_NUMBER;
  @Input() iconStyle = {
    'font-size': '4em',
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    color: '#25d366',
  };

  ngOnInit() {
  }

}
