<app-spinner *ngIf="isSubscribeProcess"></app-spinner>

<app-spinner *ngIf="(!isLoaded && !isSubscribeProcess) || (!course && !isSubscribeProcess)"></app-spinner>

<div *ngIf="course && isLoaded && !isSubscribeProcess" class="container-fluid content bgLightBlueColor lgPaddingTop">
    <div class="content__titles container">
        <h1 class="text-right txtMainColor mb-5"
            [innerHTML]="direction=='ltr' ? course.name.en : course.name.ar | safehtml"></h1>
    </div>
    <div class="container">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="content-tab" [ngClass]="{'active' : !isAlreadySubscribed}"
                        data-toggle="tab" data-target="#content" type="button" role="tab" aria-controls="content"
                        [attr.aria-selected]="!isAlreadySubscribed">
                    {{direction === 'rtl' ? 'المحتوى' : 'Content'}}
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button #chapterBtn class="nav-link" id="chapters-tap" [ngClass]="{'active': isAlreadySubscribed}"
                        data-toggle="tab" data-target="#chapter" type="button" role="tab" aria-controls="chapter"
                        [attr.aria-selected]="isAlreadySubscribed ? true : false">
                    {{direction === 'rtl' ? 'الفصول' : 'Chapters'}}
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="documents-tab" data-toggle="tab" data-target="#document" type="button"
                        role="tab" aria-controls="document" aria-selected="false">
                    {{direction === 'rtl' ? 'المستندات' : 'Documents'}}
                </button>
            </li>
<!--            <li class="nav-item" role="presentation">-->
<!--                <button class="nav-link" id="faq-tab" data-toggle="tab" data-target="#faq" type="button" role="tab"-->
<!--                        aria-controls="faq" aria-selected="false">-->
<!--                    {{direction === 'rtl' ? 'الأسئلة الشائعة' : 'FAQ'}}-->
<!--                </button>-->
<!--            </li>-->
        </ul>
    </div>
    <div class="container-fluid bg-white">
        <div class="tab-content" id="myTabContent">
            <div *ngIf="isPausedCourse() && !isAlreadySubscribed" class="alert alert-danger w-50 mx-auto" style="top: 10px">
                <h3 class="text-center text-danger">نأسف لاعلامك ان ورشة الفلاش لم تعد متوفرة، يمكنك مراجعة <a href="/clientCourseView/643c6bbafe6efe1dc0053def">دورة القلق العام</a> لمعرفة المزيد عن التقنية.</h3>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active': !isAlreadySubscribed}" id="content" role="tabpanel"
                 aria-labelledby="content-tab">
                <div class="card container mainSectionSetting mb-3 border-0">
                    <div class="row no-gutters">
<!--                        For mobile -->
                        <div class="col-lg-5 d-lg-none">
                            <img loading="lazy" class="w-100" [src]="publicUrl + '/courses/cover/'+ course._id + '/' + course.cover" alt="cover">
                        </div>
                        <div class="col-lg-6 mt-4 d-lg-none">
                            <div class="card-body py-3 px-1 text-right">
                                <h3 class="txtMainColor d-none d-md-block">حول {{course.name.ar}}</h3>
                                <div class="txtMainColor mdLineHeight mt-3 d-none d-md-block" style="font-size: 20px"
                                     [innerHTML]="direction=='ltr' ? course.description.en : course.description.ar | safehtml"></div>
                                <div class="buttonsContainer d-flex flex-column justify-content-center mt-5" *ngIf="!isAlreadySubscribed && !isPausedCourse()">
                                    <div class="border mx-auto smRadius d-flex align-items-center justify-content-center priceContainer">
                                        <div>
                                            <span class="m-0 txtMainColor">السعر:</span>
                                        </div>
                                        <div *ngIf="course.price.euro" class="mr-2">
                                            <span class="text-primary">
                                                {{currency}} {{total}}
                                            </span>
                                            <small *ngIf="course.discount" class="text-danger">
                                                <del>{{course.price.euro}}</del>
                                            </small>
                                        </div>
                                        <div *ngIf="!course.price.euro" class="mr-2">
                                            <span class="text-primary">
                                                {{direction === 'rtl' ? 'مجاني' : 'Free'}}
                                            </span>
                                        </div>
                                    </div>
                                    <button *ngIf="course.price.euro !== 0"
                                            (click)="emptyVars();requestFree==false; checkIsLogged();"
                                            data-toggle="modal" data-target="#paymentModalUpdate"
                                            class="btn btn-primary w-75 mx-auto mt-3 py-2">{{direction === 'rtl' ? 'اشترك الأن' : 'Subscribe Now'}}</button>
                                    <button *ngIf="course.price.euro === 0" (click)="emptyVars();addFreeCourse();"
                                            [disabled]="isFreeSubscribeBtnDisabled"
                                            class="btn btn-primary w-75 mx-auto mt-3 py-2">{{direction === 'rtl' ? 'اشترك الأن' : 'Subscribe Now'}}</button>
                                    <div class="mt-1 w-100 text-center">
                                        <a *ngIf="checkIfCanGetFree() && canAskFreeAccess" class="btn btn-link"
                                           data-toggle="modal" data-target="#modalFreeAccess"
                                           (click)="emptyVars();checkIsLogged();requestFree=true">لا تستطيع الدفع؟</a>
                                    </div>
                                </div>
                            </div>
                        </div>

<!--                        For desktop-->
                        <div class="col-lg-6 offset-1  p-3 cardContent d-none d-lg-block">
                            <div class="card-body text-right">
                                <h3 class="txtMainColor">حول {{course.name.ar}}</h3>
                                <div class="txtMainColor"
                                     [innerHTML]="direction=='ltr' ? course.description.en : course.description.ar | safehtml"
                                     style="font-size: 20px"
                                ></div>
                                <div class="buttonsContainer d-flex justify-content-center flex-wrap align-items-end" *ngIf="!isAlreadySubscribed && !isPausedCourse()">
                                    <div class="border smRadius d-flex align-items-center justify-content-center priceContainer">
                                        <div>
                                            <span class="m-0 txtMainColor">السعر:</span>
                                        </div>
                                        <div *ngIf="course.price.euro" class="mr-2">
                                            <span class="text-primary">
                                                {{currency}} {{total}}
                                            </span>
                                            <small *ngIf="course.discount" class="text-danger">
                                                <del>{{course.price.euro}}</del>
                                            </small>
                                        </div>
                                        <div *ngIf="!course.price.euro" class="mr-2">
                                            <span class="text-primary">
                                                {{direction === 'rtl' ? 'مجاني' : 'Free'}}
                                            </span>
                                        </div>
                                    </div>
                                    <button *ngIf="course.price.euro !== 0"
                                            (click)="emptyVars();requestFree==false; checkIsLogged()"
                                            data-toggle="modal" data-target="#paymentModalUpdate"
                                            class="btn btn-primary subScribeBtn  mt-3 mx-auto">{{direction === 'rtl' ? 'اشترك الأن' : 'Subscribe Now'}}</button>
                                    <button *ngIf="course.price.euro === 0" (click)="emptyVars();addFreeCourse();"
                                            [disabled]="isFreeSubscribeBtnDisabled"
                                            class="btn btn-primary subScribeBtn  mt-3 mr-auto ml-0">{{direction === 'rtl' ? 'اشترك الأن' : 'Subscribe Now'}}</button>
                                    <div class="mt-1 w-100 text-center d-flex justify-content-sm-end justify-content-center">
                                        <a *ngIf="checkIfCanGetFree() && canAskFreeAccess" class="btn btn-link cantPay"
                                           data-toggle="modal" data-target="#modalFreeAccess"
                                           (click)="emptyVars();checkIsLogged();requestFree=true">لا تستطيع الدفع؟</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 d-none d-lg-block">
                            <img loading="lazy" class="w-100"
                                 [src]="publicUrl + '/courses/cover/'+ course._id + '/' + course.cover" alt="cover">
                        </div>
                    </div>
                </div>
                <div class="container-fluid bigImgContainer bgLightColor ">
                    <div class="container px-0 mb-3 d-none d-md-block" *ngFor="let image of course.images; index as index">
                        <img loading="lazy" class="w-100" [src]="publicUrl + '/courses/image/'+ course._id + '/' + image" alt="image">
                    </div>
                </div>
                <div class="wide-section p-0 mb-3 d-md-none" *ngFor="let image of course.mobileImages; index as index">
                    <img loading="lazy" class="w-100" [src]="publicUrl + '/courses/mobileImage/'+ course._id + '/' + image" alt="mobile image">
                </div>
                <div class="container-fluid px-0 bg-white" *ngIf="!isPausedCourse()">
                    <div class="container lgPaddingBottom smPaddingTop text-center mainSectionSetting d-none d-lg-block">
                        <button *ngIf="!isAlreadySubscribed && course.price.euro !== 0"
                                (click)="emptyVars();requestFree==false; checkIsLogged()" data-toggle="modal"
                                data-target="#paymentModalUpdate"
                                class="btn btn-primary subScribeBtn mt-3">{{direction === 'rtl' ? 'اشترك الأن' : 'Subscribe Now'}}</button>
                        <button *ngIf="!isAlreadySubscribed && course.price.euro === 0"
                                (click)="emptyVars();addFreeCourse();"
                                [disabled]="isFreeSubscribeBtnDisabled"
                                class="btn btn-primary subScribeBtn mt-3">{{direction === 'rtl' ? 'اشترك الأن' : 'Subscribe Now'}}</button>
                    </div>
                    <div class="container lgPaddingBottom smPaddingTop text-center mainSectionSetting d-lg-none">
                        <button *ngIf="!isAlreadySubscribed && course.price.euro !== 0"
                                (click)="emptyVars();requestFree==false; checkIsLogged()" data-toggle="modal"
                                data-target="#paymentModalUpdate"
                                class="btn btn-primary w-100 mt-3">{{direction === 'rtl' ? 'اشترك الأن' : 'Subscribe Now'}}</button>
                        <button *ngIf="!isAlreadySubscribed && course.price.euro === 0"
                                (click)="emptyVars();addFreeCourse();"
                                [disabled]="isFreeSubscribeBtnDisabled"
                                class="btn btn-primary w-100 mt-3">{{direction === 'rtl' ? 'اشترك الأن' : 'Subscribe Now'}}</button>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active': isAlreadySubscribed}" id="chapter" role="tabpanel"
                 aria-labelledby="chapters-tap">
                <div class="container mainSectionSetting">
                    <div class="wrapper--w960 mx-auto">
                        <div class="row">
                            <div class="col-md-12" style="text-align: start;">
                                <div class="video-container">
                                    <h3 *ngIf="isAlreadySubscribed" class="text-center txtMainColor pb-3">{{ selectedFileTitle }}</h3>
                                    <div *ngIf="!showLoader" style="position:relative;width: 100%">
                                        <div *ngIf="isYouTubeUrl" plyr class="player" plyrTitle="Video 1"
                                             [plyrPlaysInline]="true"
                                             [plyrOptions]="{hideControls: 'true', youtube: '{controls:1, showinfo:0, modestbranding:1}'}"
                                             [plyrSources]="[{ src: fileUrl, provider : 'youtube'}]">
                                        </div>
                                        <audio *ngIf="isDriveUrl" class="w-100" controls>
                                            <source
                                                    [src]="fileUrl"
                                                    type="audio/mp3"
                                            >
                                        </audio>
                                        <audio *ngIf="isNormalText" class="w-100" controls>
                                            <source
                                                    [src]="publicUrl + '/courses/user/audio/'+course._id+'/'+fileUrl"
                                                    type="audio/mp3"
                                            >
                                        </audio>
                                        <div class="videoDocuments border" *ngIf="isAlreadySubscribed && chapters[selectedChapterIndex]['files'][selectedFileIndex]['attachments']">
                                            <div class="py-3" *ngFor="let attachment of chapters[selectedChapterIndex]['files'][selectedFileIndex]['attachments']"
                                                 (click)="goto(publicUrl + '/courses/user/attachment/'+course._id+'/'+attachment)">
                                                <div class="d-flex justify-content-between px-5 flex-wrap" style="cursor: pointer">
                                                    <div class="d-flex align-items-center justify-content-center justify-content-md-start flex-grow-1 mb-3 mb-md-0">
                                                        <i class="fa font-weight-bold text-danger"
                                                           [ngClass]="{
                                                               'fa-file-pdf': isFilePdf(attachment),
                                                               'fa-file-audio': isFileAudio(attachment),
                                                               'fa-file-image': isFileImg(attachment),
                                                               'fa-file-word': isFileDoc(attachment)
                                                               }"
                                                           style="font-size: 26px"
                                                        ></i>
                                                        <div class="mr-2">
                                                            <p class="mb-0" [innerHTML]="attachment"
                                                               style="font-size: 15px"
                                                            ></p>
                                                        </div>
                                                    </div>
                                                    <div class="flex-grow-1 text-center text-md-left">
                                                        <button class="btn btn-primary px-5">
                                                            تحميل <i class="fa fa-download"></i>
                                                        </button>
                                                    </div>
                                                    <p *ngIf="downloading == (publicUrl + '/courses/user/attachment/'+course._id+'/'+attachment)"
                                                       >جاري التحميل
                                                        ...</p>
                                                </div>
                                           </div>
                                        </div>
                                        <div *ngIf="isAlreadySubscribed"  class="pagination-buttons d-flex justify-content-center">
                                            <button [disabled]="watchedFilesCount == files_count() - 1" (click)="getNextFile()"
                                                    class="next-button ml-2"><i class="fas fa-chevron-right ml-1"></i> التالي
                                            </button>
                                            <button [disabled]="!watchedFilesCount" (click)="getPrevFile()"
                                                    class="prev-button mr-2">السابق <i class="fas fa-chevron-left mr-1"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="showLoader" class="text-center mt-3"
                                         style="position:relative;width: 100%;margin-bottom: 2.5rem">
                                        <!--                                <img loading="lazy" style="width: 100px; height: 100px" src="/assets/images/loading.gif">-->
<!--                                        <div class="loader">Mohamed Nadjmo</div>-->
                                        <div class="loader">جاري التحميل...</div>
                                    </div>
                                    <div class="overlay" *ngIf="!isAlreadySubscribed"></div>
                                    <div class="lock-icon" *ngIf="!isAlreadySubscribed"></div>
                                </div>
                                <div class="mt-4">
                                    <h2 class="txtMainColor d-none d-md-block">محتوى الدوره من فيديوهات ومقاطع صوتية</h2>
                                    <h5 class="txtMainColor d-md-none">محتوى الدوره من فيديوهات ومقاطع صوتية</h5>
                                </div>
                                <hr>
                                <div class="my-3" style="display: inline-flex;">
                                    <p class="txtMainColor p-0">عدد الفصول: {{ course.chapters.length }}</p>
                                    <p class="fa fa-circle-notch mx-3 pt-1"></p>
                                    <p class="txtMainColor p-0"> عدد المحاضرات:  {{ files_count() }}</p>
                                </div>

                                <mat-accordion [multi]="false" #accordion="matAccordion">
                                    <mat-expansion-panel
                                            style="border: 1px solid #67676742;background: #f6f6f6;color: #525253;"
                                            *ngFor="let chapter of course.chapters; let chapterIndex = index"
                                            [expanded]="chapterIndex == selectedChapterIndex"
                                    >
                                        <mat-expansion-panel-header
                                            class="px-1 px-md-3"
                                        >
                                            <span class="description-mat d-flex flex-nowrap align-items-center">
                                                <span>{{chapter.files.length}} </span>
                                                <span>محاضرة</span>
                                                <i *ngIf="!isAlreadySubscribed"
                                                   class="fas fa-lock text-danger d-flex mr-2 align-items-center"></i>
                                            </span>
                                            <span class="title-mat"
                                                  [innerHTML]="direction=='rtl' ? chapter.title.ar : chapter.title.en">
                                                </span>
                                        </mat-expansion-panel-header>
                                        <div *ngIf="!chapter.files || chapter.files.length == 0">
                                            <p style="color: red;"
                                               [innerHTML]="direction=='rtl' ? 'لا تحتوي هاته الوحدة على فيديوهات' : 'this chapter has no files!' "></p>
                                        </div>
                                        <div class="file-list-item"
                                             [ngStyle]="{'color': (fileIndex == selectedFileIndex && chapterIndex == selectedChapterIndex)
                                             ? '#0ba8d5' : '#525253'
                                             }"
                                             *ngFor="let file of chapter.files; let fileIndex = index"
                                             (click)="changeVideoUrl(file, chapterIndex, fileIndex)">
                                            <i style="display: inline-flex;padding: 0 0 0 5px;font-size: 25px;"
                                               [ngStyle]="{'color': (fileIndex == selectedFileIndex && chapterIndex == selectedChapterIndex)
                                             ? '#0ba8d5' : '#525253'
                                             }"
                                               class="fas fa-play-circle"></i>
                                            <p class="chapter-content"
                                               [innerHTML]="direction=='rtl' ? file.title.ar : file.title.en">
                                            </p>
                                            <i *ngIf="!canViewCourse" style="position: absolute;left: 2rem;"
                                               class="fas fa-lock"></i>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="document" role="tabpanel" aria-labelledby="documents-tab">
                <div class="container mainSectionSetting">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div class="panel panel-default" style="text-align: start;">
                                    <h2 class="txtMainColor">محتوى الدورة من مستندات:</h2>
                                    <hr>
                                    <div *ngIf="isAlreadySubscribed" class="my-3" style="display: inline-flex;">
                                        <p class="txtMainColor h5 ml-2">{{ files_count() }} فيديوهات</p>
                                        <p class="txtMainColor h5">{{ attachments_count() }} مستندات</p>
                                    </div>
                                    <div *ngIf="!isAlreadySubscribed" class="my-3" style="display: inline-flex;">
                                        <i class="fas fa-lock text-danger d-flex ml-2 align-items-center"></i>
                                        <p class="txtMainColor h5">يجب الاشتراك للوصول للمحتوى</p>
                                    </div>

                                    <mat-accordion class="bg-danger" *ngFor="let chapter of chapters;let i=index">
                                        <mat-expansion-panel *ngFor="let file of chapter.files">
                                            <mat-expansion-panel-header>
                                                        <span class="description-mat">
                                                            {{file?.attachments.length}}
                                                        </span>
                                                <span class="title-mat"
                                                      [innerHTML]="direction=='rtl' ? file?.title.ar : file?.title.en"></span>
                                            </mat-expansion-panel-header>

                                            <div *ngIf="!file?.attachments || file?.attachments.length == 0">
                                                <p class="text-center font-weight-bold text-danger"
                                                   [innerHTML]="direction=='rtl' ? 'لا يحتوي هذا الفيديو على مستندات' : 'this video has no attachments!' ">
                                                </p>
                                            </div>

                                            <div class="py-3" *ngFor="let attachment of file?.attachments"
                                                 (click)="goto(publicUrl + '/courses/user/attachment/'+course._id+'/'+attachment)">
                                                <div class="w-75 smRadius h-100 mx-auto d-flex justify-content-between px-1 px-md-5 py-4 flex-wrap"
                                                     style="border: 1px solid #c07f7f;">
                                                    <div class="d-flex align-items-center justify-content-center justify-content-md-start flex-grow-1 mb-3 mb-md-0">
                                                        <i class="fa font-weight-bold text-danger"
                                                           [ngClass]="{
                                                               'fa-file-pdf': isFilePdf(attachment),
                                                               'fa-file-audio': isFileAudio(attachment),
                                                               'fa-file-image': isFileImg(attachment),
                                                               'fa-file-word': isFileDoc(attachment)
                                                               }"
                                                           style="font-size: 26px"
                                                        ></i>
                                                        <div class="mr-2">
                                                            <p class="mb-0" [innerHTML]="attachment"
                                                               style="font-size: 15px"
                                                            ></p>
                                                        </div>
                                                    </div>
                                                    <div class="flex-grow-1 text-center text-md-left">
                                                        <button class="btn btn-primary px-5">
                                                            تحميل <i class="fa fa-download"></i>
                                                        </button>
                                                    </div>
                                                    <p *ngIf="downloading == (publicUrl + '/courses/user/attachment/'+course._id+'/'+attachment)"
                                                       style="position: absolute;left: 1%;margin: 0;">جاري التحميل
                                                        ...</p>
                                                </div>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="faq" role="tabpanel" aria-labelledby="faq-tab">
                <div class="container mainSectionSetting">
                    <div class="row">
                        <div class="col-md-12" style="text-align: start;">
                            <h2 class="txtMainColor">محتوى الدورة من الأسئلة:</h2>
                            <div class="py-4 border mt-5" *ngFor="let message of course.messages">
                                <!--                                <div class="py-5 border mt-5">-->
                                <div class="question px-2 mb-4">
                                    <h3 class="txtMainColor font-weight-bold">
                                        <!--                                                {{ message.message }}-->
                                        هل هناك طريق تساعدني علي التخلص من الرهاب الاجتماعي ؟
                                    </h3>
                                </div>
                                <div class="answer">
                                    <p class="txtMainColor px-5" style="font-size: 24px">
                                        <!--                                                {{!message.answer ? 'لا يوجد جواب بعد' : message.answer}}-->
                                        نعم بالتأكيد هناك العديد من التمارين الفعالة يمكنك استخدامها
                                    </p>
                                </div>
                            </div>
                            <div class="py-4 border mt-5" *ngIf="!course.messages">
                                <h5 class="text-center font-weight-bold text-danger"
                                    *ngIf="!course.messages || course.messages.length == 0">لا يوجد اسئلة بعد...</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modal-default">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 *ngIf="direction=='ltr'" class="modal-title">Ask A Question</h4>
                <h4 *ngIf="direction=='rtl'" class="modal-title">اطرح سؤالا</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form>
                <div class="modal-body">

                    <input class="form-control form-control-lg" name="email" type="email"
                           [placeholder]="direction=='ltr'? 'Email':'البريد الإلكتروني'" [(ngModel)]="email">
                    <br>
                    <input class="form-control form-control-lg" name="qst" type="text"
                           [placeholder]="direction=='ltr'? 'Question':'سؤال'" [(ngModel)]="qst">

                </div>
                <div class="modal-footer justify-content-between">
                    <button *ngIf="direction=='ltr'" type="button" class="btn btn-default" data-dismiss="modal">Cancel
                    </button>
                    <button *ngIf="direction=='rtl'" type="button" class="btn btn-default" data-dismiss="modal">إلغاء
                    </button>
                    <button *ngIf="direction=='ltr'" type="button" class="btn btn-primary" (click)="sendMessage()"
                            data-dismiss="modal">Send
                    </button>
                    <button *ngIf="direction=='rtl'" type="button" class="btn btn-primary" (click)="sendMessage()"
                            data-dismiss="modal">إرسال
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal fade" id="modal-default-1" style="padding:0;">
    <div class="modal-dialog" style="
        width: fit-content;
        margin:auto;
    ">
        <div class="modal-content" style="
        width: 400px;
        margin: auto;
        height: 250px;
        border-radius: 20px;
    ">
            <div class="modal-header" style="
        height: 100px;
        text-align: center;
    ">
                <h4 class="modal-title" style="
        text-align: center;
        margin: auto;
        width: 400px;
        font-size: 40px;
    " [innerHTML]="direction=='ltr'? 'Rate Us': 'قيمنا'"></h4>
                <button aria-label="Close" class="close" data-dismiss="modal" type="button" style="
        padding: 0;
    "><span aria-hidden="true">×</span></button>
            </div>
            <form class="ng-untouched ng-pristine ng-valid">
                <div class="modal-body" style="
        text-align: center;
        padding: 0;
    ">
                    <div style="display: inline-flex;margin:10px;color:gold;text-align: center;/* font-size: 20px; */width: fit-content;margin: 0 30px;margin: auto;height: 100px;vertical-align: middle;">
                        <i class="fas fa-star"
                           style="color:gold;font-size: 40px;text-align: center;margin: auto;"></i><i
                            class="fas fa-star" style="color:gold;margin: auto;font-size: 40px;"></i><i
                            class="fas fa-star" style="color:gold;font-size: 40px;margin: auto;"></i>
                        <i class="fas fa-star" style="color:gold;font-size: 40px;margin: auto;"></i><i
                            class="fas fa-star" style="color:gold;font-size: 40px;margin: auto;"></i></div>
                </div>
                <div class="modal-footer justify-content-between" style="padding: 0;">
                    <button class="btn btn-primary" data-dismiss="modal" type="button" style="
        font-size: 20px;
        color: #fdfdff;
        margin: auto;
        background: #6c9fde;
        border-radius: 5px;
    " [innerHTML]="direction=='ltr'? 'Submit': 'إرسال'"></button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal fade" id="modal-default3" style="width: 100%;">
    <div class="modal-dialog md-pere">
        <style>
            @media (max-width: 1000px) {
                #change_width {
                    width: 100% !important;
                }
            }
        </style>
        <div class="modal-content md-content border-0" style="width: 643px; height: 881px;" id="change_width">
            <form style="margin: 0;">
                <div class="modal-body" style="padding:0;">

                    <div class="wrapper">
                        <div>
                            <div class="container">
                                <div id="stepper1" class="bs-stepper" style="width: 600px; height: auto">
                                    <div class="bs-stepper-header" style="position: relative;">
                                        <div class="step" data-target="#test-l-1">
                                            <button class="step-trigger">
                                                <span class="bs-stepper-circle">1</span>
                                                <span class="bs-stepper-label"
                                                      [innerHTML]="direction=='ltr'? 'Payment' : 'الدفع'"></span>
                                            </button>
                                        </div>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true"
                                                  style="position: absolute;top: 0;left: 0;">&times;</span>
                                        </button>
                                    </div>
                                    <div class="bs-stepper-content">
                                        <form>
                                            <div id="test-l-1" class="content">

                                                <div class="card p-3" style="margin: 0 !important;">
                                                    <div class="row justify-content-center">
                                                        <div class="col-12">
                                                            <h2 class="heading text-center">
                                                                {{direction == 'ltr' ? 'Payment Methods' : 'طرق الدفع'}}
                                                            </h2>
                                                        </div>
                                                    </div>

                                                    <form onsubmit="event.preventDefault()" class="form-card"
                                                          style="height: 36rem;">
                                                        <div class="row justify-content-center mb-4 radio-group"
                                                             style="position: relative;">
                                                            <input type="radio" name="payment" id="paypal"
                                                                   class="checkbox-input" checked="checked"/>
                                                            <label class="label1" style="width: 49%;">
                                                                <label for="paypal" class="checkbox-label"
                                                                       style="margin: 0 5px;width: 85%;">
                                                                    <div class="checkbox-text">
                                                                        <img loading="lazy" src="/assets/images/credit/paypal.jpg" alt="paypal">
                                                                    </div>
                                                                </label>
                                                                <div class="form" style="position: absolute;left: 0;">
                                                                </div>
                                                            </label>

                                                            <input type="radio" name="payment" id="ccp"
                                                                   class="checkbox-input"/>
                                                            <label class="label1 rmpr" style="width: 49%;">
                                                                <label for="ccp" class="checkbox-label"
                                                                       style="margin: 0 5px;width: 85%;">
                                                                    <div class="checkbox-text">
                                                                        <img loading="lazy" src="assets/images/credit/stripe.png"
                                                                             alt="stripe img"
                                                                             class="img-fluid">
                                                                    </div>
                                                                </label>
                                                                <div class="form"
                                                                     style="position: absolute;left: 0;top: 22%;text-align: start;">
                                                                    <div class="text-center pt-5">
                                                                    </div>
                                                                </div>
                                                            </label>
                                                            <br>
                                                        </div>
                                                    </form>
                                                    <input data-dismiss="modal"
                                                           data-toggle="modal" data-target="#modal-thank-you"
                                                           type="submit" #thankyoutoggle>
                                                </div>


                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal fade" id="modalFreeAccess" style="width: 100%;padding: 0;">
    <div class="modal-dialog md-pere">
        <div class="modal-content md-content border-0" style="margin: 8px auto;
            background: transparent;
            box-shadow: none;">
            <form style="margin: 0;">
                <div class="modal-body" style="padding:0;">
                    <div class="wrapper">
                        <div>
                            <div class="container">
                                <div id="stepper2" class="bs-stepper">
                                    <div class="bs-stepper-header" style="display: none;">

                                        <div class="step active" data-target="#test-l-4">
                                            <button class="step-trigger">
                                                <span class="bs-stepper-circle">1</span>
                                                <span class="bs-stepper-label"
                                                      [innerHTML]="direction=='ltr'? 'Informations':'معلومات'"></span>

                                            </button>
                                        </div>
                                    </div>
                                    <div class="bs-stepper-content" style="padding: 0;">

                                        <form>
                                            <div id="test-l-4" class="content active">
                                                <div class="freeAccessContainer card">
                                                    <button type="button" class="close" data-dismiss="modal"
                                                            aria-label="Close"
                                                            style="position: absolute;top: 0%;right: 1%;z-index: 10;">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                    <div class="text-center">
                                                        <h2 class="txtMainColor text-center">
                                                            {{direction == 'ltr' ? 'Free Access' : 'الاشتراك المجاني'}}
                                                        </h2>
                                                    </div>
                                                    <form onsubmit="event.preventDefault()" class="form-card"
                                                          style="height: auto;">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <small>
                                                                    {{direction == 'ltr' ?
                                                                    "If you're unable to participate due to financial difficulties, you can explain your situation at the bottom of the box. We'll review your request and send you an email confirming your participation if it's accepted. If you don't receive a message, it means your request was rejected." :
                                                                    "اذا كنت غير قادر على المشاركة بسبب صعوبات مالية، يمكنك شرح الوضعية الخاصة بك في أسفل الصندوق. سنقوم بمراجعة طلبك وإرسال بريد إلكتروني يؤكد مشاركتك إذا تم قبولها. إذا لم تتلق رسالة، فهذا يعني أن طلبك تم رفضه."}}
                                                                </small>
                                                                <div class="input-group mt-3">
                                                                        <textarea class="freeAccessInput" name="reason"
                                                                                  id="reason" cols="30" rows="5"
                                                                                  #reasonFreeAccess></textarea>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row justify-content-center">
                                                            <div class="col-md-3" style="
                                                                margin: auto 0 auto auto;"><input
                                                                    (click)="submitFreeAccess()" data-dismiss="modal"
                                                                    data-toggle="modal"
                                                                    data-target="#modal-free-access-popup" type="submit"
                                                                    [value]="direction=='ltr' ? 'Send' : 'ارسال'"
                                                                    class="btn btn-pay placeicon sendBtn"
                                                                    style="margin: 0;padding: 0;"></div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal fade" id="modal-thank-you">
    <div class="modal-dialog">
        <div class="modal-content" style="text-align: center;border-radius: 5px;" *ngIf="paymentSuccess">
            <div class="modal-header">
                <h2 class="modal-title" style="margin: auto;"
                    [innerHTML]="direction=='ltr'?'Thank you!' : 'شكرا لك!'"></h2>
            </div>
            <form>
                <div class="modal-body" style="color: #888;padding: 0 20px;">
                    <div style="width: 10rem; margin: auto;margin-bottom: 1em;">
                        <img loading="lazy" style="width: inherit;" src="/assets/images/icons/bill.png" alt="bill">
                    </div>
                    <p *ngIf="direction=='ltr'">Your payment <b style="color: #333;font-size: 15px;"
                                                                *ngIf="course">{{total}}{{currency}} </b> has been processed
                        successfully! <br>A confirmation email has been send to <br><span>{{user.email}}</span>
                        <br> <br> Your transaction id is: <b>{{paymentSuccess}}</b></p>
                    <p *ngIf="direction=='rtl' && paymentMethod=='ccp'">شكرا على الاشتراك يرجي الانتظار ريثما يتححق
                        الادمين من طلبكم. عملية الانتظار قد تاخذ 24 ساعة على الاقل. <a [routerLink]="['/contactUs']">اذا
                            واجهتك اي مشاكل يمكنكم التواصل معنا</a><br> <br> رقم معاملتك هو: <b>{{paymentSuccess}}</b>
                    </p>
                    <p *ngIf="direction=='rtl' && paymentMethod=='paypal'">تمت معالجة الدفع الخاص بك بنجاح!<br> <br> رقم
                        معاملتك هو: <b>{{paymentSuccess}}</b></p>
                    <p *ngIf="direction=='rtl' && !paymentMethod">لقد تم الإشتراك في الدورة بنجاح!</p>
                </div>
                <div class="modal-footer justify-content-between">
                    <button style="margin: auto;background: #4a6ffd;color: #fff;padding: 10px 30px;" type="button"
                            class="btn btn-primary"
                            data-dismiss="modal">{{ direction == 'ltr' ? 'Done' : 'تم' }}</button>
                </div>
            </form>
        </div>
        <div class="modal-content" style="text-align: center;border-radius: 5px;" *ngIf="paymentFailed">
            <div class="modal-header">
                <h2 class="modal-title" style="margin: auto;"
                    [innerHTML]="direction=='ltr'? 'Oh no, your payment failed!' : 'فشل الدفع الخاص بك!'"></h2>

            </div>
            <form>
                <div class="modal-body" style="color: #888;padding: 0 20px;">
                    <div style="width: 10rem; margin: auto;">
                        <i style="color: #fd4a4a;font-size: 90px;
                            margin: 20px 0;
                            padding: 0;" class="fas fa-exclamation-triangle"></i>
                    </div>
                    <p *ngIf="direction=='ltr'"><br>Don't worry, try it again in a few moments.
                        <br> <br> If you still have a problem <span style="color: dodgerblue;cursor: pointer;"
                                                                    [routerLink]="['/contactUs']" data-dismiss="modal">contact us</span>
                        to fix it.</p>
                    <p *ngIf="direction=='rtl'"><br>لا تقلق ، حاول مرة أخرى بعد لحظات قليلة.
                        <br> <br> إذا كنت لا تزال تواجه مشكلة <span style="color: dodgerblue;cursor: pointer;"
                                                                    [routerLink]="['/contactUs']" data-dismiss="modal"> تواصل معنا</span>
                        لإصلاحها.</p>
                </div>
                <div class="modal-footer justify-content-between">
                    <button style="margin: auto;background: #4a6ffd;color: #fff;padding: 10px 30px;" type="button"
                            class="btn btn-primary" data-dismiss="modal"
                            [innerHTML]="direction=='ltr'? 'Done':'تم'"></button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal fade" id="modal-free-access-popup">
    <div class="modal-dialog">
        <div class="modal-content" style="text-align: center;border-radius: 5px;" *ngIf="freeAccessSuccess">
            <div class="modal-header">
            </div>
            <form>
                <div class="modal-body" style="color: #888;padding: 0 20px;">
                    <p *ngIf="direction=='ltr'">Your request has been processed successfully!
                        <br>You will be notified if the admin accepted or denied your request></p>
                    <p *ngIf="direction=='rtl'">تم معالجة طلبك بنجاح!
                        <br>سيتم إعلامك إذا وافق المشرف على طلبك أو رفضه</p>
                </div>
                <div class="modal-footer justify-content-between">
                    <button style="margin: auto;background: #4a6ffd;color: #fff;padding: 10px 30px;" type="button"
                            class="btn btn-primary" data-dismiss="modal"
                            [innerHTML]="direction=='ltr'? 'Done' : 'تم' "></button>
                </div>
            </form>
        </div>
        <div class="modal-content" style="text-align: center;border-radius: 5px;" *ngIf="freeAccessFailed">
            <div class="modal-header">
                <h2 class="modal-title" style="margin: auto;"
                    [innerHTML]="direction=='ltr'? 'Oh no, your request failed!':' لقد فشل طلبك! ' "></h2>

            </div>
            <form>
                <div class="modal-body" style="color: #888;padding: 0 20px;">
                    <div style="width: 10rem; margin: auto;">
                        <i style="color: #fd4a4a;font-size: 90px;
                            margin: 20px 0;
                            padding: 0;" class="fas fa-exclamation-triangle"></i>
                    </div>
                    <p *ngIf="direction=='ltr'"><br>Don't worry, try it again in a few moments.
                        <br> <br> If you still have a problem <span style="color: dodgerblue;cursor: pointer;"
                                                                    [routerLink]="['/contactUs']" data-dismiss="modal">contact us</span>
                        to fix it.</p>

                    <p *ngIf="direction=='rtl'"><br>لا تقلق ، حاول مرة أخرى بعد لحظات قليلة.
                        <br> <br> إذا كنت لا تزال تواجه مشكلة <span style="color: dodgerblue;cursor: pointer;"
                                                                    [routerLink]="['/contactUs']" data-dismiss="modal"> اتصل بنا</span>
                        لإصلاحها.</p>

                </div>
                <div class="modal-footer justify-content-between">
                    <button style="margin: auto;background: #4a6ffd;color: #fff;padding: 10px 30px;" type="button"
                            class="btn btn-primary" data-dismiss="modal"
                            [innerHTML]="direction=='ltr'? 'Done' : 'تم' "></button>
                </div>
            </form>
        </div>
    </div>
</div>


    <!-- Payment Modal -->
    <div class="modal fade p-0 p-md-5" id="paymentModalUpdate"
         data-backdrop="static"
         data-keyboard="false"
         tabindex="-1"
         aria-labelledby="paymentModalUpdateLabel"
         aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeBtnForPaymentModal>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-tabs justify-content-center d-none d-md-flex" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="pills-home-tab" data-toggle="pill" data-target="#pills-home"
                                    type="button" role="tab" aria-controls="pills-home" aria-selected="true"
                                    style="width: 150px; height: 80px; background-image: url('/assets/images/credit/paypal.jpg'); background-size: contain; background-repeat: no-repeat; background-position: center;"
                            >
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-profile-tab" data-toggle="pill" data-target="#pills-profile"
                                    type="button" role="tab" aria-controls="pills-profile" aria-selected="false"
                                    style="width: 150px; height: 80px; background-image: url('/assets/images/credit/stripe.png'); background-size: contain; background-repeat: no-repeat; background-position: center;"
                            >
                            </button>
                        </li>
                    </ul>
                    <ul class="nav nav-tabs justify-content-center d-md-none" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="pills-home-tab" data-toggle="pill" data-target="#pills-home"
                                    type="button" role="tab" aria-controls="pills-home" aria-selected="true"
                                    style="width: 90px; height: 50px; background-image: url('/assets/images/credit/paypal.jpg'); background-size: contain; background-repeat: no-repeat; background-position: center;"
                            >
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-profile-tab" data-toggle="pill" data-target="#pills-profile"
                                    type="button" role="tab" aria-controls="pills-profile" aria-selected="false"
                                    style="width: 90px; height: 50px; background-image: url('/assets/images/credit/stripe.png'); background-size: contain; background-repeat: no-repeat; background-position: center;"
                            >
                            </button>
                        </li>
                    </ul>
                    <div class="tab-content py-5" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div id="paypalButtons"
                                 style="max-width: 35rem;margin: auto;max-height: 18rem;overflow: auto;"
                                 #paypalButtons></div>
                        </div>
                        <div *ngIf="course && isLoaded" class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <app-stripe-checkout
                                    [courseId]="courseId"
                                    [amount]="originalTotalPriceForPayments"
                            ></app-stripe-checkout>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


<app-client-footer *ngIf="course && isLoaded && !isSubscribeProcess"></app-client-footer>