<app-spinner *ngIf="!coursesList"></app-spinner>
<header>
    <div class="container-fluid pt-5 pb-3 text-center">
        <h1 class="txtMainColor mb-3">{{direction == 'ltr' ? 'Courses' : 'أحدث الدورات'}}</h1>
        <p class="txtMainColor lightFont d-none d-md-block">
            استفد من دوراتنا التعليمية التي تساعدك على معالجة نفسك وإنهاء معاناتك ذاتيا.
        </p>
        <p class="txtMainColor lightFont d-md-none">
            استفد من دوراتنا التعليمية التي تساعدك على
            <br>
            معالجة نفسك وإنهاء معاناتك ذاتيا.
        </p>
    </div>
</header>
<section class="container py-3">
    <div class="row">
<!--        <div *ngFor="let course of coursesList | paginate: { itemsPerPage: pageSize, currentPage: page, totalItems: coursesList.length }"-->
        <div *ngFor="let course of coursesList"
             class="col-12 col-md-6 col-lg-4 mb-5 text-right">
            <a class="cardLinkContainer" [routerLink]="['/clientCourseView',course._id]">
                <div class="card border-0 mx-auto lgRadius" style="width: 18rem;">
                    <img [src]="publicUrl + '/courses/cover/'+ course._id + '/' + course.cover"
                         class="card-img-top border-bottom" alt="...">
                    <div class="card-body">
                        <h5 class="card-title txtMainColor">{{course.name.ar}}</h5>
                        <p class="card-text txtMainColor lightFont" [innerHTML]="course.zipped_description.ar"></p>
                        <div class="d-flex">
                            <div class="border px-2 d-flex align-items-center justify-content-center">
                                <span>{{course.price != 0 ? course.priceAfterDiscount + ' €' : 'مجاني'}} </span>
                            </div>
                            <div class="mr-auto">
                                <button class="btn btn-primary">عرض التفاصيل</button>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <h5 *ngIf="!coursesList.length" class="text-danger mx-auto">لا يوجد دورات بعد ...</h5>
    </div>
<!--    <div *ngIf="coursesList.length" class="container text-center pt-5">-->
<!--        <pagination-controls (pageChange)="page = $event" previousLabel="السابق" nextLabel="التالي"></pagination-controls>-->
<!--    </div>-->
</section>
<app-client-footer *ngIf="coursesList"></app-client-footer>