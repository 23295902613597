import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Pipe } from "@angular/core";
import { UserAuthService } from 'src/app/services/user-auth.service';
import "../../../assets/javascript/thumbniles.js";
import { ServerApi } from 'src/environments/environment.js';

@Pipe({ name: 'safeHtml' })
@Component({
  selector: 'app-courses-component',
  templateUrl: './courses-component.component.html',
  styleUrls: ['./courses-component.component.css']
})
export class CoursesComponentComponent implements OnInit {

  page = 1;
  publicUrl = ServerApi.PublicRouteTemp;
  direction = 'rtl';
  constructor(private userService: UserService, private authService: UserAuthService) {}

  public coursesList: Array<any> = null;
  public error;

  ngOnInit() {
    window.scroll(0,0);
    this.direction = localStorage.getItem('direction');
    this.authService.source.subscribe((value) => {
      this.direction = value;

    });
    
    this.userService
      .getAllCourses().subscribe(
        (res: any) => {
          this.coursesList = res.reverse();
        },
        (error) => {
          this.error = error;
        }
      );
  }

}