import * as tslib_1 from "tslib";
import { environment, ServerApi } from "../../../environments/environment";
import { loadStripe } from "@stripe/stripe-js";
import * as i0 from "@angular/core";
import * as i1 from "../user.service";
import * as i2 from "@angular/router";
var StripeService = /** @class */ (function () {
    function StripeService(userService, _router) {
        var _this = this;
        this.userService = userService;
        this._router = _router;
        this.items = [{ id: "xl-tshirt" }];
        this.emailAddress = '';
        loadStripe(environment.STRIPE_PUBLISH_KEY)
            .then(function (stripe) {
            _this.stripe = stripe;
        });
    }
    StripeService.prototype.initialize = function (amount, courseId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, clientSecret, appearance, linkAuthenticationElement, paymentElementOptions, paymentElement;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.courseId = courseId;
                        return [4 /*yield*/, fetch(ServerApi.PublicRouteTemp + "/payments/create-payment-intent", {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify({ amount: amount * 100 }),
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        clientSecret = (_a.sent()).clientSecret;
                        appearance = {
                            theme: 'stripe',
                        };
                        this.elements = this.stripe.elements({ appearance: appearance, clientSecret: clientSecret });
                        linkAuthenticationElement = this.elements.create("linkAuthentication");
                        linkAuthenticationElement.mount("#link-authentication-element");
                        linkAuthenticationElement.on('change', function (event) {
                            _this.emailAddress = event.value.email;
                        });
                        paymentElementOptions = {
                            layout: "tabs",
                        };
                        paymentElement = this.elements.create("payment", paymentElementOptions);
                        paymentElement.mount("#payment-element");
                        return [2 /*return*/];
                }
            });
        });
    };
    StripeService.prototype.handleSubmit = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, error, paymentIntent;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        e.preventDefault();
                        this.setLoading(true);
                        return [4 /*yield*/, this.stripe.confirmPayment({
                                elements: this.elements,
                                confirmParams: {
                                    return_url: environment.APP_DOMAIN + "/clientCourseView/" + this.courseId,
                                    receipt_email: this.emailAddress,
                                },
                            })];
                    case 1:
                        _a = _b.sent(), error = _a.error, paymentIntent = _a.paymentIntent;
                        console.log('paymentIntent ******************', paymentIntent);
                        if (['card_error', 'validation_error'].includes(error.type)) {
                            this.showMessage(error.message);
                        }
                        else {
                            this.showMessage("An unexpected error occurred.");
                        }
                        this.setLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    StripeService.prototype.checkStatus = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var clientSecret, paymentIntent, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
                        if (!clientSecret) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.stripe.retrievePaymentIntent(clientSecret)];
                    case 1:
                        paymentIntent = (_b.sent()).paymentIntent;
                        this.logPaymentDetails(paymentIntent);
                        _a = paymentIntent.status;
                        switch (_a) {
                            case "succeeded": return [3 /*break*/, 2];
                            case "processing": return [3 /*break*/, 3];
                            case "requires_payment_method": return [3 /*break*/, 4];
                        }
                        return [3 /*break*/, 5];
                    case 2:
                        this.showMessage("Payment succeeded!");
                        this.addPaymentForVisaType(paymentIntent);
                        return [3 /*break*/, 7];
                    case 3:
                        this.showMessage("Your payment is processing.");
                        return [3 /*break*/, 7];
                    case 4:
                        this.showMessage("Your payment was not successful, please try again.");
                        return [3 /*break*/, 7];
                    case 5:
                        this.showMessage("Something went wrong.");
                        return [4 /*yield*/, this._router.navigateByUrl("/payment/error/" + this.courseId)];
                    case 6:
                        _b.sent();
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    StripeService.prototype.logPaymentDetails = function (details) {
        this.userService.logPaymentDetails({ details: details }).subscribe(console.log, console.error);
    };
    StripeService.prototype.addPaymentForVisaType = function (order) {
        var _this = this;
        var payment = {
            course: this.courseId,
            order_id: order['id'],
            seller_transaction_id: order['payment_method'],
            method: "visa",
            state: "completed"
        };
        return this.userService.addPayment(payment).subscribe(function () {
            _this._router.navigateByUrl("/payment/success/" + _this.courseId);
        }, function (error) {
            _this._router.navigateByUrl("/payment/error/" + _this.courseId);
        });
    };
    StripeService.prototype.showMessage = function (messageText) {
        var messageContainer = document.querySelector("#payment-message");
        if (messageContainer) {
            messageContainer.classList.remove("hidden");
            messageContainer.textContent = messageText;
            setTimeout(function () {
                messageContainer.classList.add("hidden");
                messageContainer.textContent = "";
            }, 4000);
        }
    };
    StripeService.prototype.setLoading = function (isLoading) {
        var submitButton = document.querySelector("#submit");
        var spinner = document.querySelector("#spinner");
        var buttonText = document.querySelector("#button-text");
        if (submitButton && spinner && buttonText) {
            if (isLoading) {
                // Disable the button and show a spinner
                submitButton.disabled = true;
                spinner.classList.remove("hidden");
                buttonText.classList.add("hidden");
            }
            else {
                submitButton.disabled = false;
                spinner.classList.add("hidden");
                buttonText.classList.remove("hidden");
            }
        }
    };
    StripeService.prototype.loadStripe = function () {
        var _this = this;
        loadStripe(environment.STRIPE_PUBLISH_KEY)
            .then(function (stripe) {
            _this.stripe = stripe;
        });
    };
    StripeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StripeService_Factory() { return new StripeService(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.Router)); }, token: StripeService, providedIn: "root" });
    return StripeService;
}());
export { StripeService };
