<app-spinner *ngIf="!home && !loadingError"></app-spinner>


<div *ngIf="isLoaded" class="container-fluid px-0 px-md-3">
    <div class="container-fluid one d-none d-md-block"
         [ngStyle]="{'background-image': 'url('+ publicUrl + home?.banner?.ar.image + ')'}">
        <div class="container one__container">
            <div class="row">
                <div class="col-sm-12 col-lg-5 text-center text-lg-right  one__text">
                    <h1 class="mt-3 mdMarginBottom lgLineHeight txtMainColor"
                        [innerHTML]="direction=='ltr'? home.banner.en.title : home.banner.ar.title | safehtml"></h1>
                    <p class="h4 mdMarginBottom txtMainColor" style="line-height: 45px"
                       [innerHTML]="direction=='ltr' ? home.banner.en.description : home.banner.ar.description | safehtml"></p>
                    <button class="btn mainBtnBgColor lgBtn"
                            routerLink="/courses">{{direction === 'ltr' ? 'Join Courses' : 'تصفح الدورات'}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="onePhone d-block d-md-none">
        <div class="card border-0">
            <div style="height: 300px;width: 111%; background-size: cover"
                 [ngStyle]="{'background-image': 'url('+ publicUrl + home?.banner?.ar.image + ')'}"></div>
            <div class="card-body text-center px-5">
                <h2 class="mt-3 mb-3 mdLineHeight txtMainColor"
                    [innerHTML]="direction=='ltr'? home.banner.en.title : home.banner.ar.title | safehtml"></h2>
                <p class="mb-3 mdLineHeight txtMainColor"
                   [innerHTML]="direction=='ltr' ? home.banner.en.description : home.banner.ar.description | safehtml"></p>
            </div>
            <div class="px-4">
                <button class="btn mainBtnBgColor lgBtn w-100"
                        routerLink="/courses">{{direction === 'ltr' ? 'Join Courses' : 'تصفح الدورات'}}</button>
            </div>
        </div>
    </div>

    <div class="container two d-none d-lg-block">
        <div class="d-lg-flex justify-content-between">
            <div class="text-center mb-5 mb-lg-0"
                 *ngFor="let advice of ( direction=='ltr' ? home?.advices?.en : home?.advices?.ar)">
                <div class="tow__shadowImg mx-auto">
                    <img loading="lazy" class="tow__imgAbove" src="{{publicUrl + advice.image}}" alt="{{advice.alt_image}}"/>
                </div>
                <h6 class="mt-4 txtMainColor" [innerHTML]="advice.name | safehtml"></h6>
            </div>
        </div>
    </div>
    <div class="container twoPhone d-lg-none">
        <div class="d-flex flex-column align-items-center justify-content-between">
            <div class="text-center shadowAndImgContainer"
                 *ngFor="let advice of ( direction=='ltr' ? home?.advices?.en : home?.advices?.ar)">
                <div class="towPhone__shadowImg mx-auto">
                    <img loading="lazy" class="tow__imgAbove" src="{{publicUrl + advice.image}}" alt="{{advice.alt_image}}"/>
                </div>
                <p class="mt-3 txtMainColor lightFont" style="font-size: 21px" [innerHTML]="advice.name | safehtml"></p>
            </div>
        </div>
    </div>

    <div class="container-fluid three mainSectionSetting">
        <div class="container px-0 px-md-4">
            <div class="card mb-3 pt-5 py-lg-5 border-0 three__card">
                <div class="row no-gutters">
                    <div class="col-md-6">
                        <img loading="lazy" class="h-100 w-100" src="{{publicUrl + home.problem.ar.image}}"
                             alt="{{home.problem.ar.alt_image}}"/>
                    </div>
                    <div class="col-md-6 pr-3">
                        <div class="card-body text-right">
                            <h2 class="mb-5 txtMainColor" style="line-height: 55px;"
                                [innerHTML]="direction=='ltr'? home.problem.en.title : home.problem.ar.title | safehtml"></h2>
                            <p style="font-size: 23px"
                               [innerHTML]="direction=='ltr'? home.problem['en'].description : home['problem']['ar']['description'] | safehtml">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container4 textbottom m-auto w-100 my-5 py-3 bgLiner d-none d-md-block"
                 [ngStyle]="{'order':this.orderList.indexOf('note1')}" #sections>
                <h3 class="text-center font-weight-bold"
                    [innerHTML]="direction=='ltr'? (home.notes.en.length > 0 ? home.notes.en[0].title : '') : (home.notes.ar.length > 0 ? home.notes.ar[0].title : '') | safehtml"></h3>
            </div>
        </div>
    </div>

    <div class="container-fluid four d-none d-md-block">
        <div class="container text-center">
            <h5 class="h1 lgMarginBottom txtMainColor">{{direction === 'ltr' ? 'AS FEATURED IN' : 'تميز محتوانا علي منصات'}}</h5>
            <div class="d-block d-sm-flex justify-content-around w-75 mx-auto lgMarginTop">
                <div class="mb-5 mb-sm-0"
                     *ngFor="let channel of (direction=='ltr' ? home.companies.en : home.companies.ar)">
                    <img loading="lazy" class="imgOvalLg mb-4" src="{{publicUrl + channel.image}}" alt="{{channel.alt_image}}"/>
                    <p class="txtMainColor font-weight-bold h5" [innerHTML]="channel.name | safehtml"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid fourPhone d-block d-md-none">
        <div class="container text-center">
            <h2 class="txtMainColor">{{direction === 'ltr' ? 'AS FEATURED IN' : 'تميز محتوانا علي منصات'}}</h2>
            <div class="d-sm-flex flex-column align-content-between w-75 mx-auto mdMarginTop">
                <div class="mb-5" *ngFor="let channel of (direction=='ltr' ? home.companies.en : home.companies.ar)">
                    <img loading="lazy" class="imgOvalXl mb-4" src="{{publicUrl + channel.image}}" alt="{{channel.alt_image}}"/>
                    <p class="txtMainColor font-weight-bold h5" style="font-size: 23px" [innerHTML]="channel.name | safehtml"></p>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid five bgMainColor d-none d-md-block" style="padding: 60px 0">
        <div class="container text-center">
            <h5 class="txtSubSecondaryColor h1 mdMarginBottom">{{direction == 'ltr' ? 'What did they say about Mohammad Nadjmo?' : ' ماذا قالوا عن محمد نجمو'}}</h5>
            <div class="row">
                <div class="recommendation col-12 col-md-6 col-xl-3 px-0 five__recommendation smMarginBottom text-center"
                     *ngFor="let recommendation of (direction=='ltr' ? home.recommendations.en : home.recommendations.ar)">
                    <img loading="lazy" class="imgCircleLg mb-3 five__recommendationImg p-1" src="{{publicUrl + recommendation.image}}"
                         alt="{{recommendation.alt_image}}"/>
                    <p class="txtMainColor text-center text-light lightFont"
                       style="font-size: 21px; height: 284px;"
                       [innerHTML]="recommendation.saying | safehtml " #recommendation_saying></p>
                    <span [ngStyle]="{'visibility': recommendation_saying.scrollHeight > recommendation_saying.clientHeight ? 'visible' : 'hidden'}"
                       (click)="popup_info=recommendation.saying"
                       style="padding-top: 0;margin: 0;color: #0BA8D5;text-decoration-line: underline;cursor: pointer;">اقرا
                        اكثر</span>
                    <h6 class="five__recommendationName txtSubMainColor text-center" style="font-size: 15px"
                        [innerHTML]="recommendation.name | safehtml"></h6>
                    <span class="lightFont text-light">{{recommendation.description || ''}}</span>
                </div>
            </div>
        </div>
    </div>
    <section class="mobile-view fivePhone xlRadius bgMainColor text-center d-block d-md-none"
             style="padding: 72px 20px 37px !important;">
        <div class="container-fluid bgMainColor" style="padding-top: 0rem;padding-inline: 0;">
            <h2 class="txtSubSecondaryColor mdMarginBottom">{{direction == 'ltr' ? 'What did they say about Mohammad Nadjmo?' : ' ماذا قالوا عن محمد نجمو'}}</h2>
            <div id="recommendationPhoneDisplay" class="carousel slide bgMainColor  w-100" data-ride="carousel"
                 style="margin-top: 2rem;">
                <ol dir="ltr" class="carousel-indicators step-ol" style="margin: 0 0 -35px;">
                    <li class="step-li txtSubSecondaryColor mx-2" data-target="#recommendationPhoneDisplay"
                        *ngFor="let recommendation of (direction=='ltr' ? home.recommendations.en : home.recommendations.ar);let i=index"
                        [ngClass]="{'active': i == 0}" attr.data-slide-to="{{i}}"
                        style="width: 12px;height: 12px;border-radius: 50%;border: 1px solid rgb(128,128,128); background-color: #68fff4"></li>
                </ol>
                <div class="carousel-inner r row mx-auto bgMainColor" style="align-items: flex-start;width: 100%;">
                    <div class="bgMainColor carousel-item col-md-4 bgMainColor text-center"
                         [ngClass]="{'active': i == 0}" style="margin-right: 0;"
                         *ngFor="let recommendation of (direction=='ltr' ? home.recommendations.en : home.recommendations.ar);let i=index">
                        <div class="Testimonials bgMainColor">
                            <img loading="lazy" src="{{publicUrl + recommendation.image}}" alt="{{recommendation.alt_image}}"/>
                            <div class="Testimonialsdesc mb-3">
                                <p class="txtMainColor text-center text-light lightFont" style="font-size: 23px"
                                   [innerHTML]="recommendation.saying | safehtml " #testimonial_saying></p>
                                <span [ngStyle]="{'visibility': testimonial_saying.scrollHeight > testimonial_saying.clientHeight ? 'visible' : 'hidden'}"
                                   (click)="popup_info=recommendation.saying"
                                   style="padding-top: 0;margin: 0;color: #0BA8D5;text-decoration-line: underline;cursor: pointer;">اقرا اكثر</span>
                                <p class="five__recommendationName txtSecondaryColor text-center"
                                    [innerHTML]="recommendation.name | safehtml"></p>
                                <span class="lightFont text-light">{{recommendation.description || ''}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev fiveArrows txtSecondaryColor opacity-1" aria-label="Previous"
                        type="button" data-slide="prev" data-target="#recommendationPhoneDisplay">
                    <div class="left">
                        <i class="fas fa-caret-left" style="font-size: 46px;"></i>
                    </div>
                </button>
                <button class="carousel-control-next fiveArrows txtSecondaryColor opacity-1" aria-label="Next"
                        type="button" data-slide="next" data-target="#recommendationPhoneDisplay">
                    <div class="right ">
                        <i class="fas fa-caret-right" style="font-size: 46px;"></i>
                    </div>
                </button>
            </div>
        </div>
    </section>

    <div class="container-fluid six mainSectionSetting d-none d-lg-block">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6 text-lg-right text-center aboutMeParent">
                    <h3 class="txtMainColor mb-4 lgMarginBottom">مرحبا, أنا محمد نجمو - Mohamed Nadjmo</h3>
                    <p class="txtMainColor smLineHeight"
                       [innerHTML]="direction=='ltr'? home.about.en.zipped_description : home.about.ar.zipped_description | safehtml"></p>
                    <div class="d-flex justify-content-start smMarginTop aboutMeChild">
                        <button class="btn bgSubMainColor mdBtn ml-4"
                                routerLink="/courses">{{direction === 'ltr' ? 'Join Courses' : 'تصفح الدورات'}}</button>
                        <a class="btn btn-link pb-0 mr-5 lightFont" style="font-size: 20px" routerLink="/aboutUs"
                           [innerHTML]="direction=='ltr'? 'Learn more about us' : 'إعرف المزيد'"></a>
                    </div>
                </div>
                <div class="text-center col-12 col-lg-6">
                    <img loading="lazy" class="w-75" style="border-radius: 12px;" src="{{publicUrl + home.about.ar.image}}"
                         alt="{{home.about.ar.alt_image}}"/>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid six mainSectionSetting d-block d-lg-none">
        <div class="container">
            <div class="row">
                <div class="text-center col-12 col-lg-6 mb-4">
                    <img loading="lazy" class="w-100" style="border-radius: 12px;" src="{{publicUrl + home.about.ar.image}}"
                         alt="{{home.about.ar.alt_image}}"/>
                </div>
                <div class="col-12 col-lg-6 mb-5 text-right">
                    <h3 class="txtMainColor mb-4 lgMarginBottom">مرحبا, أنا محمد نجمو - Mohamed Nadjmo</h3>
                    <p class="txtMainColor smLineHeight aboutText" style="font-size: 20px; padding-left: 35px;"
                       [innerHTML]="direction=='ltr'? home.about.en.zipped_description : home.about.ar.zipped_description | safehtml"></p>
                    <div class="d-flex justify-content-start align-items-end smMarginTop">
                        <button class="btn bgSubMainColor mdBtn ml-4"
                                routerLink="/courses">{{direction === 'ltr' ? 'Join Courses' : 'تصفح الدورات'}}</button>
                        <a class="btn btn-link pb-0 mr-5 lightFont" style="font-size: 20px" routerLink="/aboutUs"
                           [innerHTML]="direction=='ltr'? 'Learn more about us' : 'إعرف المزيد'"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid seven mainSectionSetting">
        <div class="container">
            <div class="p-3 text-center lgMarginBottom bgLiner d-none d-md-block" style="border-radius: 9px;">
                <p class="h3 text-light">
                    <b [innerHTML]="direction=='ltr'? home.steps.en.title : home.steps.ar.title | safehtml"></b>
                </p>
            </div>
            <div class="text-center lgMarginBottom txtMainColor">
                <h2 *ngIf="direction=='ltr'" class="mt-0 h2">The recovery process
                    in {{home.steps.en.descriptions.length}} simple steps:</h2>
                <h3 *ngIf="direction=='rtl'" class="mt-0 h1">عملية التعافي في {{home.steps.ar.descriptions.length}}
                    خطوات </h3>
            </div>
            <div class="d-block d-md-flex justify-content-around ">
                <div class="text-center seven_card px-3 mb-5 mb-md-0"
                     *ngFor="let step of (direction=='ltr' ? home.steps.en.descriptions : home.steps.ar.descriptions); let i=index">
                    <img loading="lazy" class="imgOvalXl seven__img mb-4" src="{{publicUrl + step.image}}" alt="{{step.alt_image}}"/>
                    <p class="text-center txtMainColor"
                       style="font-family: helvetica-neue-arabic-light,serif !important; font-size: 25px"
                       [innerHTML]="step.description | safehtml"></p>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid eight bgMainColor d-none d-lg-block">
        <div class="container">
            <div class="eight__title lgMarginBottom">
                <h3 class="h1 text-white text-center">
                    {{direction === 'ltr' ? 'What we guarantee for you on the site' : 'ما نضمنه لك في الموقع'}}
                </h3>
            </div>
            <div class="d-block mb-4 d-md-flex justify-content-between lgMarginBottom py-3">
                <div class="eight__cardContent w-75 mx-auto px-3 py-5 mdRadius text-center mb-4"
                     *ngFor="let guaranty of (direction=='ltr' ? home.guaranties.en : home.guaranties.ar)">
                    <img loading="lazy" class="imgCircleLg card-img-top mb-4" src="{{ publicUrl + guaranty.image}}"
                         alt="{{ guaranty.alt_image }}"/>
                    <h4 class="txtSubSecondaryColor mb-4" [innerHTML]="guaranty.title | safehtml"><i
                            class="fas fa-check-square"></i></h4>
                    <p class="txtLightColor smLineHeight lightFont" style="font-size: 20px"
                       [innerHTML]="guaranty.description | safehtml">
                </div>
            </div>
            <div class="eight__btnContainer smMarginTop">
                <button class="btn" routerLink="/courses">تصفح الدورات</button>
            </div>
        </div>
    </div>
    <div class="container-fluid eight bgMainColor d-block d-lg-none xlRadius">
        <div class="container">
            <div class="eight__title mdMarginBottom">
                <h3 class="h1 text-white text-center">
                    {{direction === 'ltr' ? 'What we guarantee for you on the site' : 'ما نضمنه لك في الموقع'}}
                </h3>
            </div>
            <div class="text-center mb-5"
                 *ngFor="let guaranty of (direction=='ltr' ? home.guaranties.en : home.guaranties.ar)">
                <img loading="lazy" class="imgCircleLg card-img-top mb-4" src="{{ publicUrl + guaranty.image}}"
                     alt="{{ guaranty.alt_image }}"/>
                <p class="txtSubSecondaryColor mb-5 lightFont" style="font-size: 23px" [innerHTML]="guaranty.title | safehtml"><i
                        class="fas fa-check-square"></i></p>
            </div>
            <div class="eight__btnContainer-mobile text-center mdMarginTop">
                <button class="btn btn-primary bgSubMainColor px-4 py-2 font-weight-bold" routerLink="/courses">
                    <span>تصفح الدورات</span>
                </button>
            </div>
        </div>
    </div>

    <div class="container-fluid nine mainSectionSetting d-none d-md-block">
        <div class="container nine_textContainer p-2 text-center bgLiner">
            <h6 class="h3"
                [innerHTML]="direction=='ltr'? (home.notes.en.length > 1 ? home.notes.en[1].title : '') : (home.notes.ar.length > 1 ? home.notes.ar[1].title : '') | safehtml"></h6>
        </div>
    </div>

    <!--    From Old-->
    <section class="desktop-view container10 ten"
             [ngStyle]="{'order':this.orderList.indexOf('categories'), 'background': backgroundList[11]}">
        <h3 class="mt-0">{{direction === 'ltr' ? 'Some of the psychological problems that we treated' : 'بعض المشاكل النفسيه التي قمنا بعلاجها'}} </h3>
        <div class="carousel slide" data-ride="carousel" style="margin-top: 3rem;">
            <div class="container carousel-inner row mx-auto justify-content-center px-0"
                 style="align-items: flex-start;width: 100%;margin-bottom: 2rem;display: -webkit-box;-webkit-box-pack: center;">
                <div class="carousel-item col-md-2"
                     style="display: block;margin: 2rem 0;width: 25%;padding: 0; border-right: 0.1rem solid #b1b1d0;"
                     [ngStyle]="{'border-left': [5, 11].includes(index) ? '0.1rem solid #b1b1d0' : 'unset'}"
                     *ngFor="let category of (direction=='ltr' ? home.categories.en : home.categories.ar); index as index">
                    <div class="categories">
                        <img loading="lazy" src="{{publicUrl + category.image}}" alt="{{category.alt_image}}"/>
                        <div class="categoriestext">
                            <p class="lightFont mb-0" style="color: #141472;margin-bottom: 1rem;">{{category.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="mobile-view px-0 container10 tenPhone" [ngStyle]="{'order':this.orderList.indexOf('categories')}">
        <h2 class="txtMainColor w-75 mx-auto">{{direction == 'rtl' ? 'بعض المشاكل النفسية التي قمنا بعلاجها' : 'Some of the psychological problems that we treated'}}</h2>
        <!--        <p style="color: #141472;" *ngIf="direction=='ltr'">-->
        <!--            Yes, these are the psychological problems that we have addressed directly or through courses with all success-->
        <!--        </p>-->
        <!--        <p style="color:#141472;" *ngIf="direction=='rtl'">نعم هذه هي المشاكل النفسية التي قمنا بعلاجها </p>-->
        <div id="myCarouselTestimonials" class="carousel slide lgMarginTop d-flex align-items-center"
             data-ride="carousel"
             style="margin: 4rem 0; height: 250px;">
            <ol dir="ltr" class="carousel-indicators step-ol" style="margin-bottom: -2rem; bottom: -30px">
                <li class="step-li" data-target="#myCarouselTestimonials"
                    *ngFor="let category of (direction=='ltr' ? home.categories.en : home.categories.ar);let i=index"
                    [ngClass]="{'active': i == 0}" attr.data-slide-to="{{i}}"
                    style="width: 12px;height: 12px;border-radius: 50%;border: 1px solid #009EFF;"></li>
            </ol>
            <div class="carousel-inner r row mx-auto mb-0"
                 style="align-items: flex-start;width: 100%;">
                <div class="carousel-item" [ngClass]="{'active': i == 0}" style="margin-right: 0;"
                     *ngFor="let category of (direction=='ltr' ? home.categories.en : home.categories.ar);let i=index">
                    <div class="categories mx-auto">
                        <img loading="lazy" src="{{publicUrl + category.image}}" alt="{{category.alt_image}}"/>
                        <div class="categoriestext mx-auto">
                            <p class="txtMainColor lightFont" style="margin-bottom: 1rem;">{{category.name}}</p>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev txtMainColor tenArrow" href="#myCarouselTestimonials"
                   role="button" data-slide="prev" style="width: auto; padding: 10px">
                   <span class="carousel-control-prev-icon" aria-hidden="true" style="color: #333">
                       <i class="fas fa-caret-left txtSecondaryColor" style="font-size: 45px;">
                       </i>
                   </span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next txtMainColor tenArrow txtSecondaryColor" href="#myCarouselTestimonials"
                   role="button" data-slide="next" style="width: auto; padding: 10px">
                   <span class="carousel-control-next-icon" aria-hidden="true" style="color: #333">
                       <i class="fas fa-caret-right txtSecondaryColor" style="font-size: 45px;">
                       </i>
                   </span>
                    <span class="sr-only">Next</span>
                </a>
            </div>

        </div>
    </section>

    <!--    From Old-->
    <section class="desktop-view container11 eleven mainSectionSetting mt-0">
        <div class="container-fluid py-3" style="padding-inline: 0;">
            <div class="container">
                <div class="mdMarginBottom text-center">
                    <h2 class="txtMainColor "
                        style="font-size: 40px; margin-top: 2rem; padding-bottom: 20px"> {{direction === 'ltr' ? 'liberation stories' : 'قصص التحرر'}}</h2>
                </div>
                <div class="row">
                    <div class="carousel-item col-12 col-md-6 col-lg-4 mb-5 mb-lg-0" style="margin-right: 0;display: block;"
                         *ngFor="let testimonial of (direction=='ltr' ? home.testimonials.en : home.testimonials.ar);let i=index">
                        <div class="Testimonials w-100 text-center">
                            <img loading="lazy" class="mx-auto" src="{{publicUrl + testimonial.image}}"
                                 alt="{{testimonial.alt_image}}"/>
                            <div class="Testimonialsdesc">
                                <p class="txtMainColor text-center lightFont" style="font-size: 21px" [innerHTML]="testimonial.saying | safehtml "
                                   #testimonial_saying></p>
                                <span [ngStyle]="{'visibility': testimonial_saying.scrollHeight > testimonial_saying.clientHeight ? 'visible' : 'hidden'}"
                                   (click)="popup_info=testimonial.saying"
                                   style="padding-top: 0;margin: 0;color: #009EFF;text-decoration-line: underline;cursor: pointer;">اقرا
                                    اكثر</span>
                                <p class="txtSecondaryColor mt-2" [innerHTML]="testimonial.name | safehtml"></p>
                                <span class="txtSecondaryColor lightFont" [innerHTML]="testimonial.description | safehtml"></span>
                            </div>
                        </div>
                    </div>
                </div>
<!--                <div id="myCarousel3" class="carousel slide" data-ride="carousel" style="margin-top: 3rem;">-->
<!--                    <div class="carousel-inner r row my-0 mx-auto" style="align-items: flex-start;width: 100%;">-->
<!--                        <div class="carousel-item col-12 col-md-6 col-lg-4 mb-5 mb-lg-0" style="margin-right: 0;display: block;"-->
<!--                             *ngFor="let testimonial of (direction=='ltr' ? home.testimonials.en : home.testimonials.ar);let i=index">-->
<!--                            <div class="Testimonials w-100 text-center">-->
<!--                                <img loading="lazy" class="mx-auto" src="{{publicUrl + testimonial.image}}"-->
<!--                                     alt="{{testimonial.alt_image}}"/>-->
<!--                                <div class="Testimonialsdesc">-->
<!--                                    <p class="txtMainColor text-center lightFont" style="font-size: 21px" [innerHTML]="testimonial.saying | safehtml "-->
<!--                                       #testimonial_saying></p>-->
<!--                                    <a [ngStyle]="{'visibility': testimonial_saying.scrollHeight > testimonial_saying.clientHeight ? 'visible' : 'hidden'}"-->
<!--                                       (click)="popup_info=testimonial.saying"-->
<!--                                       style="padding-top: 0;margin: 0;color: #009EFF;text-decoration-line: underline;cursor: pointer;">اقرا-->
<!--                                        اكثر</a>-->
<!--                                    <p class="txtSecondaryColor mt-2" [innerHTML]="testimonial.name | safehtml"></p>-->
<!--                                    <span class="txtSecondaryColor lightFont" [innerHTML]="testimonial.description | safehtml"></span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
    </section>
    <section class="mobile-view container11 eleven">
        <div class="container-fluid" style="padding-top: 0rem;padding-inline: 0;">
            <h2 class="text-center mb-3 textLightGreen">{{direction === 'ltr' ? 'liberation stories' : 'قصص التحرر'}}</h2>
            <div id="myCarouselTestimonialss" class="carousel slide" data-ride="carousel" style="margin-top: 2rem;">
                <ol dir="ltr" class="carousel-indicators step-ol txtMainColor" style="bottom: -50px;">
                    <li class="mx-2" data-target="#myCarouselTestimonialss"
                        *ngFor="let testimonial of (direction=='ltr' ? home.testimonials.en : home.testimonials.ar);let i=index"
                        [ngClass]="{'active': i == 0}" attr.data-slide-to="{{i}}"
                        style="width: 12px;height: 12px;border-radius: 50%;border: 1px solid #009EFF; color: rgb(128,128,128)"></li>
                </ol>
                <div class="carousel-inner r row mx-auto" style="align-items: flex-start;width: 100%;">
                    <div class="carousel-item col-md-4" [ngClass]="{'active': i == 0}" style="margin-right: 0;"
                         *ngFor="let testimonial of (direction=='ltr' ? home.testimonials.en : home.testimonials.ar);let i=index">
                        <div class="Testimonials h-auto">
                            <img loading="lazy" src="{{publicUrl + testimonial.image}}" alt="{{testimonial.alt_image}}"/>
                            <div class="Testimonialsdesc mt-5 mb-2 text-center w-100">
                                <p class="txtMainColor lightFont" style="font-size: 23px" [innerHTML]="testimonial.saying | safehtml "
                                   #testimonial_saying></p>
                                <span class="txtMainColor mb-2"
                                   [ngStyle]="{'visibility': testimonial_saying.scrollHeight > testimonial_saying.clientHeight ? 'visible' : 'hidden'}"
                                   (click)="popup_info=testimonial.saying"
                                   style="text-decoration-line: underline;cursor: pointer;">
                                    اقرا اكثر</span>
                                <p class="txtSecondaryColor mt-2" [innerHTML]="testimonial.name | safehtml"></p>
                                <span class="txtSecondaryColor lightFont" [innerHTML]="testimonial.description | safehtml"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev txtSecondaryColor elevenArrows opacity-1" aria-label="Previous"
                   href="#myCarouselTestimonialss" role="button" data-slide="prev" style="width: auto;">
                    <i class="fas fa-caret-left" style="font-size: 45px;">
                    </i>
                </a>
                <a class="carousel-control-next txtSecondaryColor elevenArrows opacity-1" aria-label="Next"
                   href="#myCarouselTestimonialss" role="button" data-slide="next" style="width: auto;">
                    <i class="fas fa-caret-right" style="font-size: 45px;">
                    </i>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </section>

    <!--    From Old-->
    <section class="desktop-view mainSectionSetting container12 bgLightBlue py-5  twelve">
        <div class="container py-5">
            <h3 class="txtMainColor my-0"
                style="padding-bottom: 30px">{{direction === 'ltr' ? 'Special Content' : 'الدورات المميزه'}}</h3>
            <div class="inline-block pricecont" style="width:100%;margin-top: 3rem;position: relative;">
                <div class="pricecomponent"
                     *ngFor="let content of (direction=='ltr' ? home.paid.en : home.paid.ar);let i = index"
                     style="margin: auto;display:inline-block;">
                    <div
                            style="height: 300px; background-size: contain; background-position: center; background-repeat: no-repeat;"
                            [ngStyle]="{'background-image': 'url(' + publicUrl + content.image + ')'}"
                    >
                    </div>
                    <h4 class="txtMainColor" [innerHTML]="content.name | safehtml"></h4>
                    <a class="btn mainBtnBgColor px-3 smRadius text-light font-weight-bold"
                       style="border-radius: 0"
                       href="{{home.paid.ar[i].link}}">{{direction === 'ltr' ? 'View Course' : 'عرض الدورة'}}</a>
                </div>
                <div class="c12left">
                    <i class="fas fa-caret-left" style="font-size: 50px;color: #80CFFF;"></i>
                </div>
                <div class="c12right">
                    <i class="fas fa-caret-right" style="font-size: 50px;color: #80CFFF;"></i>
                </div>
            </div>
        </div>
    </section>
    <section class="mobile-view container12 twelvePhone" [ngStyle]="{'order':this.orderList.indexOf('paid content')}" style="padding: 2rem 0; border-radius: 30px;">
        <div class="c6-content">
            <h2 class="txtMainColor mb-5">{{direction === 'ltr' ? 'Special Content' : 'الدورات المميزه'}}</h2>
            <div id="carouselExampleIndicatorsPaid" class="carousel slide" data-ride="carousel">
                <ol dir="ltr" class="carousel-indicators step-ol" style="margin-bottom: -2.3rem;">
                    <li class="step-li mx-2" data-target="#carouselExampleIndicatorsPaid"
                        *ngFor="let content of (direction=='ltr' ? home.paid.en : home.paid.ar);let i=index"
                        [ngClass]="{'active': i == 0}" attr.data-slide-to="{{i}}"
                        style="width: 13px;height: 13px;border-radius: 50%;border: 1px solid #141472;"></li>
                </ol>
                <div class="carousel-inner pricecont" style="width:100%;margin-top: 0rem;max-height: fit-content;">
                    <div class="pricecomponent carousel-item"
                         *ngFor="let content of (direction=='ltr' ? home.paid.en : home.paid.ar);let i=index"
                         style="margin: auto;" [ngClass]="{'active': i == 0}">
                        <img loading="lazy" class="w-100" src="{{publicUrl + content.image}}" alt="{{content.alt_image}}"/>
                        <h3 class="txtMainColor" style="margin: 29px 0 56px" [innerHTML]="content.name | safehtml"></h3>
                        <a class="btn mainBtnBgColor px-3 text-light font-weight-bold w-100"
                           style="font-size: 19px;"
                           href="{{home.paid.ar[i].link}}">{{direction === 'ltr' ? 'View Course' : 'عرض الدورة'}}</a>
                    </div>
                </div>
                <a class="carousel-control-prev twelveArrow txtMainColor opacity-1"
                   href="#carouselExampleIndicatorsPaid" role="button" data-slide="prev">
                    <i class="fas fa-caret-left"></i>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next twelveArrow txtMainColor opacity-1"
                   href="#carouselExampleIndicatorsPaid" role="button" data-slide="next">
                    <i class="fas fa-caret-right"></i>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </section>

    <!--    From Old-->
    <section class="desktop-view container13 thirteen mainSectionSetting">
        <div class="container p-0 ">
            <h3 class="textLightGreen">{{direction === 'ltr' ? 'We help you feel better by' : 'نساعدك على ان تحس بشعور أفضل من خلال'}}</h3>
            <div class="container13cont1 mdMarginTop d-flex justify-content-between thirteen__flex">
                <div class="container13component" *ngFor="let feeling of ( direction=='ltr' ? home?.feelings?.en : home?.feelings?.ar)">
                    <a [href]="feeling['link']" *ngIf="feeling['link']">
                        <img loading="lazy" class="imgOvalXl mb-3" [src]="publicUrl + feeling['image']" alt="feeling" />
                        <h4 class="txtSecondaryColor">{{feeling['title']}}</h4>
                        <p class="txtSecondaryColor">{{feeling['description']}}</p>
                    </a>
                    <span *ngIf="!feeling['link']">
                        <img loading="lazy" class="imgOvalXl mb-3" [src]="publicUrl + feeling['image']" alt="feeling" />
                        <h4 class="txtSecondaryColor">{{feeling['title']}}</h4>
                        <p class="txtSecondaryColor">{{feeling['description']}}</p>
                    </span>
                </div>
            </div>
            <div class="text-center">
                <button class="btn bgSubMainColor mdBtn"
                        routerLink="/courses">{{direction === 'ltr' ? 'Join Courses' : 'تصفح الدورات'}}</button>
            </div>
        </div>
    </section>
    <section class="mobile-view  container13 thirteen mainSectionSetting" style="padding:1rem 0;">
        <div class="c6-content">
            <h3 class="px-5 mdLineHeight">{{direction == 'ltr' ? 'we help you feel better by' : 'نساعدك على ان تحس بشعور أفضل من خلال'}}</h3>
            <div id="carouselExampleIndicatorsService" class="carousel slide" data-ride="carousel">
                <div class=" container13cont1" style="width:100%;margin-top:0rem;max-height:fit-content;">
                    <div class="container13component" *ngFor="let feeling of ( direction=='ltr' ? home?.feelings?.en : home?.feelings?.ar)">
                        <a [href]="feeling['link']" *ngIf="feeling['link']">
                            <img loading="lazy" class="imgOvalXl" [src]="publicUrl + feeling['image']" alt="feeling">
                            <h4 class="txtMainColor lightFont mt-3">{{feeling['title']}}</h4>
                        </a>
                        <span *ngIf="!feeling['link']">
                            <img loading="lazy" class="imgOvalXl" [src]="publicUrl + feeling['image']" alt="feeling">
                            <h4 class="txtMainColor lightFont mt-3">{{feeling['title']}}</h4>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center">
            <button class="btn bgSubMainColor mdBtn w-100" style="font-size: 20px; border-radius: 5px"
                    routerLink="/courses">{{direction === 'ltr' ? 'Join Courses' : 'تصفح الدورات'}}</button>
        </div>
    </section>


    <div id="bookSection"></div>
    <section class="container14 mb-0 d-none d-lg-block" [ngStyle]="{'order':this.orderList.indexOf('book')}">
        <div class="inline-block container14cont">
            <div class="container14rightside">
                <h4 [innerHTML]="direction=='ltr'? home.book.en.description : home.book.ar.description | safehtml"></h4>
                <p class="txtMainColor pl-5">دليلك المبسط للخروج من دوامة القلق ونوبات الهلع</p>
                <form>
                    <input class="bg-light text-right pr-3" type="text" name="fname" minlength="3"
                           [placeholder]="direction=='ltr'? 'First name':'الاسم'" #userFName/>
                    <p style="color: red;font-size: 0.7em;margin: 0;" [innerHTML]="book_form_error.name"></p>
                    <input class="bg-light text-right pr-3" type="text" name="lname" minlength="3"
                           [placeholder]="direction=='ltr'? 'Last name':'اللقب'" #userLName/>
                    <p style="color: red;font-size: 0.7em;margin: 0;" [innerHTML]="book_form_error.lname"></p>
                    <input class="bg-light text-right pr-3" type="email" name="email" required
                           [placeholder]="direction=='ltr'? 'Email Address':'البريد الإلكتروني'" #userEmail/>
                    <p style="color: red;font-size: 0.7em;margin: 0;" [innerHTML]="book_form_error.email"></p>
                    <div class="btn-holder my-3" style="display: flex;justify-content: space-between;">
                        <button class="btn bgSubMainColor px-3"
                                (click)="send()">{{direction === 'ltr' ? 'Download' : 'تحميل'}}</button>
                    </div>
<!--                    <a class="d-none" [download]="home.book.ar.file" #book></a>-->
                </form>
            </div>
            <div class="container14leftside border-0">
                <img loading="lazy" class="border-0" src="{{publicUrl + home.book.ar.cover}}" alt="{{home.book.ar.alt_cover}}"/>
            </div>
        </div>
    </section>
    <section class="fourteenPhone d-lg-none mainSectionSetting bgLightBlueColor">
        <div class="px-4" style="height: 500px">
            <img loading="lazy" class="w-100 h-100" src="{{publicUrl + home['book']['ar']['cover']}}" alt="{{home['book']['ar']['alt_cover']}}" />
        </div>
        <div class="px-5 text-center mt-5">
            <h2 class="txtMainColor">حمل كتابي جرعة أدرينالين مجانا</h2>
            <p class="txtMainColor lightFont mt-4" style="font-size: 23px; padding: 0 20px;">
                دليلك المبسط للخروج من دوامة القلق ونوبات الهلع
            </p>
        </div>
        <div class="mt-5 text-center px-4">
            <form [formGroup]="bookForm">
                <input formControlName="fName" class="form-control lightFont" type="text" placeholder="الإسم الأول">
                <div *ngIf="
                                      (bookForm.controls.fName.touched && bookForm.controls.fName.invalid) ||
                                      bookForm.controls.fName.dirty ||
                                      bookForm.controls.fName.touched
                                    ">
                    <small class="text-danger fw-bold mb-3"
                           *ngIf="bookForm.controls.fName.hasError('required')">
                        {{direction == 'ltr' ? 'First Name is required' : 'الاسم الاول مطلوب'}}
                    </small>
                    <small class="text-danger fw-bold mb-3"
                           *ngIf="bookForm.controls.fName.hasError('minlength')">
                        {{ direction == 'ltr' ? 'First Name must be at least 3 characters' : 'الاسم الاول يجب أن يكون 3 أحرف على الأقل' }}
                    </small>
                </div>
                <input formControlName="lName" class="form-control lightFont mt-3" type="text"
                       placeholder="الإسم الأخير">
                <div *ngIf="
                                      (bookForm.controls.lName.touched && bookForm.controls.lName.invalid) ||
                                      bookForm.controls.lName.dirty ||
                                      bookForm.controls.lName.touched
                                    ">
                    <small class="text-danger fw-bold mb-3"
                           *ngIf="bookForm.controls.lName.hasError('required')">
                        {{direction == 'ltr' ? 'Last Name is required' : 'الاسم الاخير مطلوب'}}
                    </small>
                    <small class="text-danger fw-bold mb-3"
                           *ngIf="bookForm.controls.lName.hasError('minlength')">
                        {{ direction == 'ltr' ? 'Last Name must be at least 3 characters' : 'الاسم الاخير يجب أن يكون 3 أحرف على الأقل' }}
                    </small>
                </div>
                <input formControlName="email" class="form-control lightFont mt-3" type="email"
                       placeholder="البريد الإلكتروني">
                <div *ngIf="
                                      (bookForm.controls.email.touched && bookForm.controls.email.invalid) ||
                                      bookForm.controls.email.dirty ||
                                      bookForm.controls.email.touched
                                    ">
                    <small class="text-danger fw-bold mb-3"
                           *ngIf="bookForm.controls.email.hasError('required')">
                        {{direction == 'ltr' ? 'Email is required' : 'البريد الإلكتروني مطلوب'}}
                    </small>
                    <small class="text-danger fw-bold mb-3"
                           *ngIf="bookForm.controls.email.hasError('email')">
                        {{ direction == 'ltr' ? 'Email must be a valid email address' : 'البريد الإلكتروني يجب أن يكون بريد إلكتروني صالح' }}
                    </small>
                </div>
                <a href="/assets/book/جرعة-أدرينالين.pdf" class="d-none" #downloadBookLink download>تحميل</a>
                <button class="mt-5 w-100 btn btn-primary"
                        [disabled]="bookForm.invalid"
                        (click)="downloadBook()">تحميل
                </button>
            </form>
        </div>
    </section>

    <app-client-footer [ngStyle]="{'order':this.orderList.indexOf('footer'), 'background':'#053847'}"></app-client-footer>
</div>

<div (click)="popup_info=null" *ngIf="popup_info" id="popup_info" class="overlay_popup"
     [ngStyle]="{'visibility': popup_info!=null?'visible':'hidden', 'opacity':popup_info!=null?1:0}">
    <div class="popup_info">
        <a (click)="popup_info=null" class="close">&times;</a>
        <div class="content" [innerHTML]="popup_info"></div>
    </div>
</div>