$(document).ready(function () {
    $("#myCarousel3").on("slide.bs.carousel", function (e) {
        var $e = $(e.relatedTarget);
        var idx = $e.index();
        var itemsPerSlide = 3;
        var totalItems = $(".m").length;
        if (idx >= totalItems - (itemsPerSlide - 1)) {
            var it = itemsPerSlide - (totalItems - idx);
            for (var i = 0; i < it; i++) {
                // append slides to end
                if (e.direction == "left") {
                    $(".m")
                        .eq(i)
                        .appendTo(".r");
                } else {
                    $(".m")
                        .eq(0)
                        .appendTo($(this).find(".r"));
                }
            }
        }
    });
    $("#myCarouselP").on("slide.bs.carousel", function (e) {
        var $e = $(e.relatedTarget);
        var idx = $e.index();
        var itemsPerSlide = 3;
        var totalItems = $(".mP").length;
        if (idx >= totalItems - (itemsPerSlide - 1)) {
            var it = itemsPerSlide - (totalItems - idx);
            for (var i = 0; i < it; i++) {
                // append slides to end
                if (e.direction == "left") {
                    $(".mP")
                        .eq(i)
                        .appendTo(".rP");
                } else {
                    $(".mP")
                        .eq(0)
                        .appendTo($(this).find(".rP"));
                }
            }
        }
    });
    $("#myCarouselRec").on("slide.bs.carousel", function (e) {
        var $e = $(e.relatedTarget);
        var idx = $e.index();
        var itemsPerSlide = 3;
        var totalItems = $(".mRec").length;
        if (idx >= totalItems - (itemsPerSlide - 1)) {
            var it = itemsPerSlide - (totalItems - idx);
            for (var i = 0; i < it; i++) {
                // append slides to end
                if (e.direction == "left") {
                    $(".mRec")
                        .eq(i)
                        .appendTo(".rRec");
                } else {
                    $(".mRec")
                        .eq(0)
                        .appendTo($(this).find(".rRec"));
                }
            }
        }
    });
});
