import {Component, ElementRef, NgZone, OnInit, ViewChild} from "@angular/core";
import {MatSnackBar} from "@angular/material";
import {Router} from "@angular/router";
import {UserAuthService} from "src/app/services/user-auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CountriesService} from "../../services/countries.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  @ViewChild("FName", {static: false}) FName: ElementRef;
  @ViewChild("LName", {static: false}) LName: ElementRef;
  @ViewChild("confirmPassword", {static: false}) confirmPassword: ElementRef;
  @ViewChild("email", {static: false}) email: ElementRef;
  @ViewChild("password", {static: false}) password: ElementRef;
  @ViewChild("gender", {static: false}) gender: ElementRef;
  @ViewChild("birthdate", {static: false}) birthdate: ElementRef;
  @ViewChild("country", {static: false}) country: ElementRef;
  @ViewChild("address", {static: false}) address: ElementRef;
  @ViewChild("phone", {static: false}) phone: ElementRef;
  @ViewChild("terms", {static: false}) terms: ElementRef;
  direction = 'rtl'

  FNameError = ""
  LNameError = ""
  EmailError = ""
  GenderError = ""
  BirthdayError = ""
  TermsError = ""
  PasswordError = ""
  ConfirmPasswordError = ""
  CountryError=""
  err = false

  constructor(
      private _snackBar: MatSnackBar,
      private ngZone: NgZone,
      private userAuthService: UserAuthService,
      private router: Router,
      private fb: FormBuilder,
      private _countriesService: CountriesService,
  ) {
  }

  openSnackBar(msg) {
    this._snackBar.open(msg, 'إغلاق', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }


  form: FormGroup;

  ngOnInit() {
    this.form = this.fb.group({
      firstname: ['', [Validators.required, Validators.minLength(3)]],
      lastname: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      // phone: [''],
      password: ['', [Validators.required, Validators.minLength(8)]],
      // passwordConfirmation: ['', [Validators.required, Validators.minLength(8)]],
      // country: [''],
      // adress: [''],
      // birthdate: [''],
      // gender: ['', [Validators.required]],
    }, {
        // validator: this.userAuthService.mustMatch('password', 'passwordConfirmation')
    });

    this.direction = localStorage.getItem('direction');
    this.userAuthService.source.subscribe((value) => {
      this.direction = value;
    });
  }

  register() {
    this.isLogged = true;
    // delete this.form.value.passwordConfirmation;
    // if(this.form.valid && this.termsInput) {
    if(this.form.valid) {

      // for(const key in this.form.value) {
      //   if(this.form.value[key] === '') {
      //     delete this.form.value[key];
      //   }
      // }

      this.userAuthService.register(this.form.value).subscribe(
          () => {
            let dialog = this.userAuthService.openDialog('تم التسجيل بنجاح يرجي التحقق من بريدك الالكتروني لتأكيد البريد الالكتروني');

            setTimeout(() => {
              dialog.close();
              window.location.assign('https://mail.google.com/');
            }, 5000);
          },
          (error: any) => {
            let msg = this.userAuthService.handleLoginAndRegisterError(error);
            // this.openSnackBar(msg);
            this.userAuthService.openDialog(msg);
            this.isLogged = false;
          }
      );
    } else {
      // this.openSnackBar('يرجي ملئ جميع الحقول المطلوبة');
      this.userAuthService.openDialog('يرجي ملئ جميع الحقول المطلوبة');
    }
  }
  registerWithGoogle(){
    this.userAuthService.userGoogleLogin().then( user => {
      this.userAuthService.registerGoogle(user).subscribe(
        (res: any) => {
          let result = {
            accesstoken: res.accessToken,
            refreshtoken: res.refreshToken
          }
          this.userAuthService.saveData(result);
          this.ngZone.run(() => {this.router.navigateByUrl("/homePage")});
        },
        (error: any) => {
          if(error.error.error === "Email already exists") {
            let msg = (this.direction == 'ltr')? 'Email already exists' : 'البريد الإلكتروني موجود مسبقا';
            // this.openSnackBar(msg);
            this.userAuthService.openDialog(msg);
          }
        }
      );
    }).catch( () => {
      let msg = (this.direction == 'ltr')? 'something went wrong' : 'حدث خطأ';
      // this.openSnackBar(msg);
      this.userAuthService.openDialog(msg);
    });
  }

  ageControl(){
    let today = new Date();
    let day;
    if (today.getDate() < 10) day = String("0"+(today.getMonth() === 1 && today.getDate() === 29 ? 28 : today.getDate()));
    else day = String(today.getMonth() === 1 && today.getDate() === 29 ? 28 : today.getDate());
    let month;
    if(today.getMonth() < 9) month = String("0"+(today.getMonth()+1));
    else month = String(today.getMonth()+1);
    let year = String(today.getFullYear() - 18);
    return  year + "-" + month + "-" + day;
  }

  ar_countries = this._countriesService.ar_countries;
  termsInput: boolean = false;
  isLogged: boolean = false;
  passwordInputType: any = 'password';

  @ViewChild("eyeIcon", {static: false}) eyeIcon: ElementRef;
  togglePasswordVisibility() {
    this.passwordInputType = this.userAuthService.togglePasswordVisibility(this.eyeIcon, this.passwordInputType);
  }
}
