import { Component, OnInit } from '@angular/core';
import { PublicService } from 'src/app/services/public.service';
import { UserAuthService } from 'src/app/services/user-auth.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private publicService: PublicService,private authService:UserAuthService) { }
  direction = 'rtl';
  public qstList: Array<any> = null;
  public error;
  isLoaded: boolean = false;

  ngOnInit() {
    this.direction = localStorage.getItem('direction');
    this.authService.source.subscribe((value) => {
      this.direction = value;
    });

    this.publicService.getHome().subscribe((res: any) => {
        this.isLoaded = true;
      this.qstList = res.faqs;
    },
      (error) => {
        this.error = error;
      }
    );

  }


}
