import { Routes } from '@angular/router';
import { CoursesComponentComponent } from './clientSideFrontEnd/courses-component/courses-component.component';
import { FaqComponent } from './clientSideFrontEnd/faq/faq.component';
import { AboutUsComponent } from './clientSideFrontEnd/about-us/about-us.component';
import { RegisterComponent } from './clientSideFrontEnd/register/register.component';
import { BlogComponent } from './clientSideFrontEnd/blog/blog.component';
import { LogInClientComponent } from './clientSideFrontEnd/log-in-client/log-in-client.component';
import { ClientComponent } from './component/client/client.component';
import { HomePageComponent } from './clientSideFrontEnd/home-page/home-page.component';
import { ContactUsComponent } from './clientSideFrontEnd/contact-us/contact-us.component';
import { ClientCourseViewComponent } from './clientSideFrontEnd/client-course-view/client-course-view.component';
import { ViewClientProfileComponent } from './clientSideFrontEnd/view-client-profile/view-client-profile.component';
import { UserAuthGuard } from './guards/user-auth.guard';
import { TermsAndConditionsComponent } from './clientSideFrontEnd/terms-and-conditions/terms-and-conditions.component';
import { EmailConfirmComponent } from './clientSideFrontEnd/email-confirm/email-confirm.component';
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { ConsultationSessionComponent } from "./components/consultation-session/consultation-session.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
var ɵ0 = function () { return import("./components/payment/payment.module.ngfactory").then(function (m) { return m.PaymentModuleNgFactory; }); };
var routes = [
    {
        path: "",
        component: ClientComponent,
        children: [
            {
                path: "viewClientProfile",
                component: ViewClientProfileComponent,
            },
            {
                path: "register",
                component: RegisterComponent,
                canActivate: [UserAuthGuard]
            },
            {
                path: "reset-password/:token",
                component: ResetPasswordComponent,
                canActivate: [UserAuthGuard]
            },
            {
                path: "faq",
                component: FaqComponent,
            },
            {
                path: "contactUs",
                component: ContactUsComponent,
            },
            {
                path: 'consultation-session',
                component: ConsultationSessionComponent
            },
            {
                path: "logInClient",
                component: LogInClientComponent,
                canActivate: [UserAuthGuard]
            },
            {
                path: "aboutUs",
                component: AboutUsComponent,
            },
            {
                path: "courses",
                component: CoursesComponentComponent,
            },
            {
                path: "clientCourseView/:id",
                component: ClientCourseViewComponent,
            },
            {
                path: "blog",
                component: BlogComponent,
            },
            {
                path: "terms",
                component: TermsAndConditionsComponent,
            },
            {
                path: "privacy-policy",
                component: PrivacyPolicyComponent,
            },
            {
                path: "confirm-email/:id",
                component: EmailConfirmComponent,
            },
            {
                path: "",
                component: HomePageComponent,
            }
        ]
    },
    {
        path: 'payment',
        loadChildren: ɵ0
    },
    { path: "**", redirectTo: "" }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
