var videos = document.getElementsByClassName("my_video");
var thecanvas = document.getElementsByClassName("thecanvas");
var x = 1;
var i;
for (i = 0; i < videos.length; i++) {console.log(3333);
  (function () {
    var video = videos[i];
    var canvas = thecanvas[i];
    video.addEventListener("loadeddata", function () {
      video.currentTime = x;
      x++;
    });

    video.addEventListener("seeked", function () {
      draw(video, canvas);
    });
  })();
}

function draw(video, thecanvas) {
  // get the canvas context for drawing
  var context = thecanvas.getContext("2d");
  // draw the video contents into the canvas x, y, width, height
  context.drawImage(video, 0, 0, thecanvas.width, thecanvas.height);
}
