<div class="container text-right my-5">
    <h1 class="text-center my-4">إعادة تعيين كلمة المرور</h1>
    <div class="row justify-content-center">
        <div class="col-md-6 col-sm-8">
            <form [formGroup]="form" method="post">
                <div class="form-group">
                    <label for="password">كلمة المرور الجديدة</label>
                    <input
                            type="password"
                            class="form-control"
                            id="password"
                            formControlName="password"
                           name="password">
                    <div *ngIf="
                                      (form.controls.password.invalid && isLogged) ||
                                      form.controls.password.dirty ||
                                      form.controls.password.touched
                                    ">
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.password.hasError('required')">
                            {{direction === 'rtl' ? 'كلمة المرور مطلوبة' : 'Password is required'}}
                        </small>
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.password.hasError('minlength')">
                            {{direction === 'rtl' ? 'كلمة المرور يجب أن تكون أكثر من 8 أحرف' : 'Password must be at least 8 characters'}}
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="passwordConfirmation">تأكيد كلمة المرور</label>
                    <input
                            type="password"
                            class="form-control"
                            id="passwordConfirmation"
                            name="passwordConfirmation"
                            formControlName="passwordConfirmation">
                    <div *ngIf="
                                      (form.controls.passwordConfirmation.invalid && isLogged) ||
                                      form.controls.passwordConfirmation.dirty ||
                                      form.controls.passwordConfirmation.touched
                                    ">
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.passwordConfirmation.hasError('required')">
                            {{direction === 'rtl' ? 'تأكيد كلمة المرور مطلوب' : 'Password confirmation is required'}}
                        </small>
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.passwordConfirmation.hasError('minlength')">
                            {{direction === 'rtl' ? 'كلمة المرور يجب أن تكون أكثر من 8 أحرف' : 'Password must be at least 8 characters'}}
                        </small>
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.passwordConfirmation.hasError('mustMatch')">
                            {{direction === 'rtl' ? 'كلمة المرور غير متطابقة' : 'Password does not match'}}
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <input type="submit" class="btn btn-primary" value="تحديث كلمة المرور" (click)="submit()">
                </div>
            </form>
        </div>
    </div>
</div>