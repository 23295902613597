import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FaqComponent } from './clientSideFrontEnd/faq/faq.component';
import { AboutUsComponent } from './clientSideFrontEnd/about-us/about-us.component';
import { RegisterComponent } from './clientSideFrontEnd/register/register.component';
import { LogInClientComponent } from './clientSideFrontEnd/log-in-client/log-in-client.component';
import { ClientComponent } from './component/client/client.component';
import { HomePageComponent } from './clientSideFrontEnd/home-page/home-page.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { ClientFooterComponent } from './clientSideFrontEnd/client-footer/client-footer.component';
import { ContactUsComponent } from './clientSideFrontEnd/contact-us/contact-us.component';
import { ClientCourseViewComponent } from './clientSideFrontEnd/client-course-view/client-course-view.component';
import { PaginatorComponent } from './clientSideFrontEnd/paginator/paginator.component';
import { PlyrModule } from 'ngx-plyr';
import { ViewClientProfileComponent } from './clientSideFrontEnd/view-client-profile/view-client-profile.component';
// import { TokenUserInterceptor } from './interceptors/token.user.interceprot';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoursesComponentComponent } from './clientSideFrontEnd/courses-component/courses-component.component';
import { safehtmlpipe } from './clientSideFrontEnd/safehtml.pipe';

import { MaterialModule } from './material-module';
import { BlogComponent } from './clientSideFrontEnd/blog/blog.component';
import { TermsAndConditionsComponent } from './clientSideFrontEnd/terms-and-conditions/terms-and-conditions.component';
import { SpinnerComponent } from './clientSideFrontEnd/spinner/spinner.component';
import { EmailConfirmComponent } from './clientSideFrontEnd/email-confirm/email-confirm.component';
import {NgxPaginationModule}  from 'ngx-pagination';
import { ClientCoursesComponent } from './clientSideFrontEnd/client-courses/client-courses.component';
import { EmbedVideoService } from 'ngx-embed-video';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ConsultationSessionComponent } from './components/consultation-session/consultation-session.component';
import { WhatsappIconComponent } from './components/whatsapp-icon/whatsapp-icon.component';
import { StripeCheckoutComponent } from './components/stripe-checkout/stripe-checkout.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import {TokenInterceptor} from "./interceptors/token-interceptor";
import { ToastDialogComponent } from './components/toast-dialog/toast-dialog.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    FaqComponent,
    AboutUsComponent,
    RegisterComponent,
    LogInClientComponent,
    ClientComponent,
    HomePageComponent,
    ClientFooterComponent,
    ContactUsComponent,
    ClientCourseViewComponent,
    PaginatorComponent,
    ViewClientProfileComponent,
    CoursesComponentComponent,
    safehtmlpipe,
    BlogComponent,
    TermsAndConditionsComponent,
    SpinnerComponent,
    EmailConfirmComponent,
    ClientCoursesComponent,
    ResetPasswordComponent,
    ConsultationSessionComponent,
    WhatsappIconComponent,
    StripeCheckoutComponent,
    PrivacyPolicyComponent,
    ToastDialogComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    Ng2TelInputModule,
    PlyrModule,
    MaterialModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    EmbedVideoService
  ],
  entryComponents: [
    ToastDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
