import * as i0 from "@angular/core";
import * as i1 from "../services/user-auth.service";
import * as i2 from "@angular/router";
var UserAuthGuard = /** @class */ (function () {
    function UserAuthGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    UserAuthGuard.prototype.canActivate = function () {
        if (this.authService.accessToken) {
            this.router.navigate(["/homePage"]);
        }
        return !this.authService.accessToken;
    };
    UserAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserAuthGuard_Factory() { return new UserAuthGuard(i0.ɵɵinject(i1.UserAuthService), i0.ɵɵinject(i2.Router)); }, token: UserAuthGuard, providedIn: "root" });
    return UserAuthGuard;
}());
export { UserAuthGuard };
