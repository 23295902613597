import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserAuthService } from '../services/user-auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserAuthGuard implements CanActivate {
 
  constructor(private authService : UserAuthService, private router : Router){}

  canActivate(){
    if(this.authService.accessToken){
      this.router.navigate([`/homePage`]);
    }
    return !this.authService.accessToken;
  }
  
}
