<body [ngStyle]="{'direction':direction}" style="background-color: white;">
<div id="arrow-up" class="arrow-up">
    <a *ngIf="page=='Home'" href="#prenav"><i class="fas fa-arrow-circle-up"></i></a>
</div>
<div class="container px-0">
    <div id="prenav" class="container pre-nav d-none d-md-flex">
        <div class="logo">
            <a class="brand" [routerLink]="['/']">
                <img src="/assets/images/logo.png" alt="logo"
                    style="width: 4rem;">
            </a>
        </div>
        <div class="social-media">
            <i class="fab fa-facebook-f" (click)="onNavigate('https://www.facebook.com/Mohamed-Nadjmo-%D9%85%D8%AD%D9%85%D8%AF-%D9%86%D8%AC%D9%85%D9%88-989906337859301')"></i>
            <i class="fab fa-instagram" (click)="onNavigate('https://www.instagram.com/mohamed_nadjmo/')"></i>
            <i class="fab fa-youtube" (click)="onNavigate('https://www.youtube.com/channel/UCPwnWpoyhke7kvOsVdlnuSA')"></i>
        </div>
        <div class="links">
            <ul>
                <li class="nav-item">
                    <a *ngIf="direction=='ltr'" class="nav-link" [routerLink]="['/faq']">Most asked questions
                        <hr class="mt-1" [ngClass]="{'active': page=='/faq'}">
                    </a>
                    <a *ngIf="direction=='rtl'" class="nav-link" [routerLink]="['/faq']">الاسئلة الشائعة
                        <hr class="mt-1" [ngClass]="{'active': page=='/faq'}">
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/aboutUs">
                        {{direction == 'ltr' ? 'About Us' : 'حولنا'}}
                        <hr class="mt-1" [ngClass]="{'active': page=='/aboutUs'}">
                    </a>
                </li>
                <li class="nav-item">
                    <a *ngIf="direction=='ltr'" class="nav-link" [routerLink]="['/contactUs']">Contact Us
                        <hr class="mt-1" [ngClass]="{'active': page=='/contactUs'}">
                    </a>
                    <a *ngIf="direction=='rtl'" class="nav-link" [routerLink]="['/contactUs']"> تواصل معنا
                        <hr class="mt-1" [ngClass]="{'active': page=='/contactUs'}">
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <nav id="navbar" class="navbar navbar-expand-lg navbar-light bg-light fixed-top change_nav_allowed container"
        style="position: unset;">

        <div class="logo-mobile">
            <a class="brand" [routerLink]="['/']"><img src="/assets/images/logo.png" alt="logo"
                    style="width: 6rem;"></a>
        </div>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navCollapse" #navMenu aria-expanded="false" aria-controls="navCollapse" aria-label="toggle navbar">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navCollapse">
<!--            [ngClass]="{'show': isCollapsed==true , 'hide': isCollapsed==false}">-->
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0 nav-first" style="text-align: start;flex: 3;">
                <li class="nav-item active">
                    <a *ngIf="direction=='ltr'" class="nav-link" [routerLink]="['/']" (click)="handleCloseMenu()">Home
                        <hr class="mt-1" [ngClass]="{'active': page=='Home'}">
                    </a>
                    <a *ngIf="direction=='rtl'" class="nav-link" [routerLink]="['/']" (click)="handleCloseMenu()">الصفحة الرئيسية
                        <hr class="mt-1" [ngClass]="{'active': page=='Home'}">
                    </a>
                </li>
                <li class="nav-item">
                    <a *ngIf="direction=='ltr'" class="nav-link" [routerLink]="['/courses']" (click)="handleCloseMenu()">Courses
                        <hr class="mt-1" [ngClass]="{'active': page=='/courses' || page.includes('/clientCourseView')}">
                    </a>
                    <a *ngIf="direction=='rtl'" class="nav-link" [routerLink]="['/courses']" (click)="handleCloseMenu()">الدورات
                        <hr class="mt-1" [ngClass]="{'active': page=='/courses' || page.includes('/clientCourseView')}">
                    </a>
                </li>
                <li class="nav-item">
                    <a *ngIf="direction=='ltr'" class="nav-link" [routerLink]="['/courses']" (click)="handleCloseMenu()">Personal Consulting
                        <hr class="mt-1" [ngClass]="{'active': page=='/contactUs'}">
                    </a>
                    <a *ngIf="direction=='rtl'" class="nav-link" [routerLink]="['/consultation-session']" (click)="handleCloseMenu()">الإستشارات الشخصية
                        <hr class="mt-1" [ngClass]="{'active': page=='/consultation-session'}">
                    </a>
                </li>
                <hr class="mt-1 hrBorder">
            </ul>

            <ul class="navbar-nav mr-auto mt-2 mt-lg-0 nav-last" style="text-align: start;justify-content: flex-end;">
                <li class="nav-item" *ngIf="!id">
                    <a *ngIf="direction=='ltr'" class="nav-link" [routerLink]="['/logInClient']" (click)="handleCloseMenu()">Login
                        <hr class="mt-1" [ngClass]="{'active': page=='/logInClient'}">
                    </a>
                    <a *ngIf="direction=='rtl'" class="nav-link" [routerLink]="['/logInClient']" (click)="handleCloseMenu()">الدخول
                        <hr class="mt-1" [ngClass]="{'active': page=='/logInClient'}">
                    </a>
                </li>
                <li class="nav-item" *ngIf="!id">
                    <a *ngIf="direction=='ltr'" class="nav-link" [routerLink]="['/register']" (click)="handleCloseMenu()">Register
                        <hr class="mt-1" [ngClass]="{'active': page=='/register'}">
                    </a>
                    <a *ngIf="direction=='rtl'" class="nav-link" [routerLink]="['/register']" (click)="handleCloseMenu()">التسجيل
                        <hr class="mt-1" [ngClass]="{'active': page=='/register'}">
                    </a>
                </li>

                <li class="nav-item profile-item" *ngIf="id">
                    <a class="nav-link" [routerLink]="['/viewClientProfile']" (click)="handleCloseMenu()">
                        {{ direction == 'rtl' ? 'حسابي' : 'Profile'}}
                        <i 
                            style="color: #93a2b8;float: left;margin: auto;border: 2px solid;border-radius: 50px;padding: 4px;font-size: 12px" 
                            class="fas fa-user">
                        </i>
                        <hr class="mt-1" [ngClass]="{'active': page=='/profile'}">
                    </a>
                </li>

                <li class="nav-item profile-item" *ngIf="id">
                    <span class="nav-link text-dark" (click)="logout();" style="cursor: pointer">
                        {{ direction == 'ltr' ? 'logout' : 'تسجيل الخروج'}}
                        <hr class="mt-1">
                    </span>
                </li>

                <li class="nav-item" id="nav-item-dropdown" style="width: 200px;position: relative;" *ngIf="id && direction=='rtl'">
                    <div *ngIf="id && direction=='rtl'" class="wrapper2"
                        style="top: -1.2rem;left: 0%;right: unset;transform: unset;">
                        <div class="navbar2" style="padding:0 !important;">
                            <div class="right">
                                <ul>
                                    <li id="dropdown1ar" (click)="drop1ar=!drop1ar">
                                        <span>
<!--                                            <img src="{{userImage}}" alt="User" width="40" height="40" style="border-radius: 50px;">-->
                                            <img [src]="userImage"
                                                 onerror="this.onerror=null;this.src='/assets/images/default.png';"
                                                 alt="User"
                                                 width="40" height="40"
                                                 style="border-radius: 50px;">
                                            <p id="nav-item-dropdown-p" style="margin: 0 0.8rem;" [innerHTML]="direction == 'rtl' ? 'حسابي' : 'My Account' "></p>
                                            <i class="fas fa-angle-down"></i>
                                        </span>
                                        <div class="dropdown"
                                            [ngStyle]="{'display':drop1ar == true ? 'block' : 'none' }">
                                            <ul class="profile-menu">
                                                <li [routerLink]="['/viewClientProfile']" (click)="handleCloseMenu()">
                                                    <button class="btn-link" style="color: rgb(128,128,128) !important; cursor:pointer;">
                                                        <i style="color:#93a2b8;" class="fas fa-user"></i>حسابي
                                                    </button>
                                                </li>
                                                            <hr class="mt-1" style="width: 90%;margin: auto;">
                                                <li>
                                                    <button class="btn-link" (click)="logout()" style="color: rgb(128,128,128) !important; cursor: pointer">
                                                        <i style="color:#93a2b8;" class="fas fa-sign-out-alt"></i>تسجيل
                                                        الخروج
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="nav-item" id="nav-item-dropdown" style="width: 200px;position: relative;" *ngIf="id && direction=='ltr'">
                    <div *ngIf="id && direction=='ltr'" class="wrapper2"
                        style="top: -1.2rem;left: unset;right: 0%;transform: unset;">
                        <div class="navbar2" style="padding:0 !important;">
                            <div class="right1en right">
                                <ul>
                                    <li id="dropdown1en" (click)="drop1en=!drop1en">
                                        <span>
                                            <p id="nav-item-dropdown-p" style="margin:0;">My Account</p>
                                            <img src="{{userImage}}" alt="User" width="40" height="40"
                                                style="border-radius: 50px;">
                                            <i class="fas fa-angle-down"></i>
                                        </span>
                                        <div class="dropdown"
                                            [ngStyle]="{'display':drop1en == true ? 'block' : 'none' }">
                                            <ul class="profile-menu">
                                                <li [routerLink]="['/viewClientProfile']" (click)="handleCloseMenu()">
                                                    <a
                                                        style="color: rgb(128,128,128) !important;"><i style="color:#93a2b8;"
                                                                                                       class="fas fa-user"></i>My Profile Info
                                                    </a>
                                                </li>
                                                            <hr class="mt-1" style="width: 90%;margin: auto;">
                                                <li>
                                                    <a (click)="logout()" style="color: rgb(128,128,128) !important; cursor: pointer"><i
                                                            style="color:#93a2b8;" class="fas fa-sign-out-alt"></i>
                                                        Logout</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>

                <hr class="mt-1 hrBorder">
                <li class="nav-item" style="display: flex;align-items: center;">
                    <a *ngIf="direction=='ltr'" class="nav-link" style="color: #009EFF !important;" [routerLink]="['/']" fragment="bookSection" (click)="handleCloseMenu()">Free Book</a>
                    <a *ngIf="direction=='rtl'" class="nav-link" style="color: #009EFF !important;" [routerLink]="['/']" fragment="bookSection" (click)="handleCloseMenu()">حمل الكتاب المجاني</a>
                </li>

                <hr class="mt-1 hrBorder">

<!--                start work-->
                <li class="nav-item d-md-none">
                    <a *ngIf="direction=='ltr'" class="nav-link" [routerLink]="['/faq']" (click)="handleCloseMenu()">Most asked questions
                        <hr class="mt-1" [ngClass]="{'active': page=='/faq'}">
                    </a>
                    <a *ngIf="direction=='rtl'" class="nav-link" [routerLink]="['/faq']" (click)="handleCloseMenu()">الاسئلة الشائعة
                        <hr class="mt-1" [ngClass]="{'active': page=='/faq'}">
                    </a>
                </li>
                <li class="nav-item d-md-none">
                    <a *ngIf="direction=='ltr'" class="nav-link" [routerLink]="['/aboutUs']" (click)="handleCloseMenu()">About Us
                        <hr class="mt-1" [ngClass]="{'active': page=='/aboutUs'}">
                    </a>
                    <a *ngIf="direction=='rtl'" class="nav-link" [routerLink]="['/aboutUs']" (click)="handleCloseMenu()">حولنا
                        <hr class="mt-1" [ngClass]="{'active': page=='/aboutUs'}">
                    </a>
                </li>
                <li class="nav-item d-md-none">
                    <a *ngIf="direction=='ltr'" class="nav-link" [routerLink]="['/contactUs']" (click)="handleCloseMenu()">Contact Us
                        <hr class="mt-1" [ngClass]="{'active': page=='/contactUs'}">
                    </a>
                    <a *ngIf="direction=='rtl'" class="nav-link" [routerLink]="['/contactUs']" (click)="handleCloseMenu()"> تواصل معنا
                        <hr class="mt-1" [ngClass]="{'active': page=='/contactUs'}">
                    </a>
                </li>
                <div class="d-flex w-75 mx-auto justify-content-around mt-3 d-md-none">
                    <i class="fab fa-facebook-f h3" (click)="onNavigate('https://www.facebook.com/Mohamed-Nadjmo-%D9%85%D8%AD%D9%85%D8%AF-%D9%86%D8%AC%D9%85%D9%88-989906337859301')"></i>
                    <i class="fab fa-instagram h3" (click)="onNavigate('https://www.instagram.com/mohamed_nadjmo/')"></i>
                    <i class="fab fa-youtube h3" (click)="onNavigate('https://www.youtube.com/channel/UCPwnWpoyhke7kvOsVdlnuSA')"></i>
                </div>

<!--end work-->

            </ul>


            <button *ngIf="!id" class="btn button2" [routerLink]="['/courses']">{{direction == 'rtl' ? 'تصفح الدورات' : 'view Courses'}}</button>
            <button *ngIf="id" class="btn button2" [routerLink]="['/viewClientProfile']">{{direction === 'rtl' ? 'دوراتي' : 'My courses'}}</button>
            <!--  <div class="wrapper2" style="position:unset;margin: auto;display: contents">
                <div *ngIf="direction=='ltr'" class="navbar2" style="padding: 0px .5rem;width: 5rem;margin: auto .5rem;background: #fff;
                    left: unset;
                    right: 25%;
                    position: absolute;">
                    <div class="right">
                        <ul>
                            <li id="dropdown2en" (click)="drop2en=!drop2en">
                                <a>
                                    <i *ngIf="direction=='rtl'" style="padding: 0 0 0 .4rem;" class="fas fa-angle-down"></i>
                                    <p *ngIf="direction=='rtl'" id="nav-item-dropdown-p" style="margin:0;display: block;">ع <span style="font-size: 14px;" class="flag-icon flag-icon-sa"></span></p>
                                    <p *ngIf="direction!='rtl'" id="nav-item-dropdown-p" style="margin:0;display: block;"><span style="font-size: 14px;" class="flag-icon flag-icon-us"></span> EN</p>
                                    <i *ngIf="direction!='rtl'" style="padding: 0 0 0 .4rem;" class="fas fa-angle-down"></i>
                                </a>
                                <div class="dropdown before" style="padding: 0px .5rem;
                                top: 2.5rem;
                                width: 5rem;
                                right: -.67rem;background: #fff;" [ngStyle]="{'display':drop2en == true ? 'block' : 'none' }">
                                    <ul>
                                        <li *ngIf="direction!='rtl'" (click)="setDirection('rtl')"><a style="color: gray !important;"><span style="font-size: 14px;margin-right: 0.5rem;" class="flag-icon flag-icon-sa"></span> ع</a></li>
                                        <li *ngIf="direction=='rtl'" (click)="setDirection('ltr')"><a style="color: gray !important;">EN <span style="font-size: 14px;margin-right: 0.5rem;" class="flag-icon flag-icon-us"></span></a></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div *ngIf="direction=='rtl'" class="navbar2" style="padding: 0px .5rem;width: 5rem;margin: auto .5rem;background: #fff;
                    right: unset;
                    left: 25%;
                    position: absolute;">
                    <div class="right">
                        <ul>
                            <li id="dropdown2ar" (click)="drop2ar=!drop2ar">
                                <a>
                                    <i *ngIf="direction=='rtl'" style="padding: 0 0 0 .4rem;" class="fas fa-angle-down"></i>
                                    <p *ngIf="direction=='rtl'" id="nav-item-dropdown-p" style="margin:0;display: block;">ع <span style="font-size: 14px;" class="flag-icon flag-icon-sa"></span></p>
                                    <p *ngIf="direction!='rtl'" id="nav-item-dropdown-p" style="margin:0;display: block;"><span style="font-size: 14px;" class="flag-icon flag-icon-us"></span> EN</p>
                                    <i *ngIf="direction!='rtl'" style="padding: 0 0 0 .4rem;" class="fas fa-angle-down"></i>
                                </a>
                                <div class="dropdown before" style="padding: 0px .5rem;
                                top: 2.5rem;
                                width: 5rem;
                                right: -.67rem;background: #fff;" [ngStyle]="{'display':drop2ar == true ? 'block' : 'none' }">
                                    <ul>
                                        <li *ngIf="direction!='rtl'" (click)="setDirection('rtl')"><a style="color: gray !important;"><span style="font-size: 14px;margin-right: 0.5rem;" class="flag-icon flag-icon-sa"></span> ع</a></li>
                                        <li *ngIf="direction=='rtl'" (click)="setDirection('ltr')"><a style="color: gray !important;">EN <span style="font-size: 14px;margin-right: 0.5rem;" class="flag-icon flag-icon-us"></span></a></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
        </div>
    </nav>
</div>
    <router-outlet [ngStyle]="{'direction':direction}"></router-outlet>

    <app-client-footer *ngIf="page != 'Home' && !page.includes('/clientCourseView') && page != '/courses'"></app-client-footer>
    <!-- <app-paginator [pages]="pages"  [active_page]="active_page"></app-paginator> -->

</body>