import { Subject } from "rxjs/internal/Subject";
import { ServerApi } from "../../environments/environment";
import * as cryptoJS from 'crypto-js';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
        this.user = new Subject();
    }
    UserService.prototype.getFile = function (url) {
        return this.http.get("" + url, {
            responseType: 'blob'
        });
    };
    UserService.prototype.getPublicCourse = function (id) {
        return this.http.get(ServerApi.PublicRouteTemp + "/courses/public/" + id);
    };
    UserService.prototype.cipherEncrypt2 = function (data) {
        var encryptedData = cryptoJS.AES.encrypt(data, ServerApi.CIPHER_KEY).toString();
        return encryptedData;
    };
    UserService.prototype.addPayment = function (obj) {
        var cryptedObj = this.cipherEncrypt2(JSON.stringify(obj));
        return this.http.post(ServerApi.PublicRouteTemp + "/subscriptions", { data: cryptedObj });
    };
    UserService.prototype.addRequestPayment = function (obj) {
        return this.http.post(ServerApi.PublicRouteTemp + "/subscriptions/request", obj);
    };
    UserService.prototype.getUser = function () {
        return this.http.get(ServerApi.PublicRouteTemp + "/users/account");
    };
    UserService.prototype.editUserInfo = function (user) {
        return this.http.patch(ServerApi.PublicRouteTemp + "/users/infos", user);
    };
    UserService.prototype.editUserImage = function (img) {
        var data = new FormData();
        data.append("image", img);
        return this.http.patch(ServerApi.PublicRouteTemp + "/users/image", data);
    };
    UserService.prototype.getCourse = function (id) {
        return this.http.get(ServerApi.PublicRouteTemp + "/courses/user/" + id);
    };
    UserService.prototype.getAllCourses = function () {
        return this.http.get(ServerApi.PublicRouteTemp + "/courses/public");
    };
    UserService.prototype.getAllRequests = function () {
        return this.http.get(ServerApi.PublicRouteTemp + "/subscriptions/user/requests");
    };
    UserService.prototype.logPaymentDetails = function (body) {
        return this.http.post(ServerApi.PublicRouteTemp + "/payments/log", body);
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
