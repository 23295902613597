import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Injectable} from "@angular/core";
import {UserAuthService} from "../services/user-auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private _userService: UserAuthService){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = this._userService.accessToken;

        if (!token) {
            return next.handle(request);
        }
        const modifiedRequest = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}` // Replace 'Bearer' with your token scheme
            }
        });
        return next.handle(modifiedRequest); // Forward the modified request
    }
}
