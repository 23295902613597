import * as i0 from "@angular/core";
var UpdateFontsService = /** @class */ (function () {
    function UpdateFontsService() {
    }
    UpdateFontsService.prototype.convertTheFonts = function (font) {
        return font
            .replace(/<([A-z]+)>/g, '<$1 class="lightFont">')
            .replace(/&nbsp;/g, '');
    };
    UpdateFontsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateFontsService_Factory() { return new UpdateFontsService(); }, token: UpdateFontsService, providedIn: "root" });
    return UpdateFontsService;
}());
export { UpdateFontsService };
