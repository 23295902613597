import { Component, ElementRef, NgZone, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { UserAuthService } from "src/app/services/user-auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: "app-log-in-client",
  templateUrl: "./log-in-client.component.html",
  styleUrls: ["./log-in-client.component.css"],
})
export class LogInClientComponent implements OnInit {
  @ViewChild("email", { static: false }) email: ElementRef;
  @ViewChild("password", { static: false }) password: ElementRef;
  errorMsg = ""
  errorMsg_ar = ""
  direction = 'rtl'
  isLogged = false;
  forgetPassMail: string;
  constructor(
    private _snackBar: MatSnackBar,
    private ngZone: NgZone,
    private userAuthService: UserAuthService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  form: FormGroup;

  openSnackBar(msg) {
    this._snackBar.open(msg, 'إغلاق', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]]
    });
    this.direction = localStorage.getItem('direction');
    this.userAuthService.source.subscribe((value) => {
      this.direction = value;
    });
  }

  login() {
    console.log(this.form.value);
    this.isLogged = true;
    if(this.form.valid) {
      this.userAuthService.userLogin(this.form.value).subscribe(
          (res: any) => {
            this.userAuthService.saveData(res);
            this.ngZone.run(() => {this.router.navigateByUrl("/viewClientProfile")});
          },
          (error: any) => {
            this.handleErrorCatch(error);
          }
      );
    }
  }

  loginWithGoogle(){
    this.userAuthService.userGoogleLogin().then( () => {
      this.userAuthService.loginGoogle().subscribe(
        (res: any) => {
          let result = {
            accesstoken: res.jsontoken,
            refreshtoken: res.refreshtoken,
            user: res.user
          }
          this.userAuthService.saveData(result);
          this.ngZone.run(() => {this.router.navigateByUrl("/viewClientProfile")});
        },
        (error: any) => {
          this.handleErrorCatch(error);
        }
      );
    }).catch( error => {
      this.handleErrorCatch(error);
    });
  }


  request(email){
    if(email) {
      this.userAuthService.resetPassword(email).subscribe(
          () =>{
            this.userAuthService.openDialog('تم الإرسال في بريدكم');

          },(error) =>{
            this.handleErrorCatch(error);
          }
      )
    } else {
      this.openSnackBar("الرجاء إدخال البريد الإلكتروني");
    }
    this.forgetPassMail = '';
  }

  @ViewChild("eyeIcon", {static: false}) eyeIcon: ElementRef;
  passwordInputType: any = 'password';
  togglePasswordVisibility() {
    this.passwordInputType = this.userAuthService.togglePasswordVisibility(this.eyeIcon, this.passwordInputType);
  }

  private handleErrorCatch(error: any) {
    let msg = this.userAuthService.handleLoginAndRegisterError(error);
    console.log(this.form.value['email']);
    if(msg === 'لم يتم تفعيل الحساب بعد برجاء التوجه للبريد الإلكتروني لتفعيل الحساب') {
      msg += ' أو الضغط على إعادة إرسال البريد الإلكتروني';
      this.userAuthService.openDialog(msg, true, this.form.value['email']);
    } else {
      this.userAuthService.openDialog(msg);
    }
    this.isLogged = false;
  }
}
