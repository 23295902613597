import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UpdateFontsService {

  constructor() { }

  convertTheFonts(font: string): string {
    return font
        .replace(/<([A-z]+)>/g, '<$1 class="lightFont">')
        .replace(/&nbsp;/g, '');
  }
}
