import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserAuthService } from 'src/app/services/user-auth.service';

@Component({
  selector: 'app-email-confirm',
  templateUrl: './email-confirm.component.html',
  styleUrls: ['./email-confirm.component.css']
})
export class EmailConfirmComponent implements OnInit {

  answer = { message_ar: "في إنتظار الجواب..."};
  direction='rtl';
  constructor(private _activeRoute: ActivatedRoute, private userAuthService: UserAuthService) { }

  ngOnInit() {
    this._activeRoute.params.subscribe(
      (params) => {
        this.userAuthService.emailConfirm(params.id).subscribe(
          () => {
            this.answer.message_ar = "تم تأكيد بريدكم الإلكتروني";
          },
          () => {
            this.answer.message_ar = "حدث خطأ";
          }
        )
      }
    );
  }

}
