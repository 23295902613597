import {AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from "@angular/core";
import {MatAccordion, MatSnackBar} from "@angular/material";
import {ActivatedRoute, Router} from "@angular/router";
import Stepper from "bs-stepper";
import {PlyrComponent} from "ngx-plyr";
import {UserAuthService} from "src/app/services/user-auth.service";
import {UserService} from "src/app/services/user.service";
import {environment, ServerApi} from "src/environments/environment";
import {UpdateFontsService} from "../../services/update-fonts.service";
import {PublicService} from "../../services/public.service";


declare var paypal;

@Component({
  selector: "app-client-course-view",
  templateUrl: "./client-course-view.component.html",
  styleUrls: ["./client-course-view.component.css"],
})


export class ClientCourseViewComponent implements OnInit, AfterViewInit {

  publicUrl = ServerApi.PublicRouteTemp;

  @ViewChild('paypalButtons', { static: true }) paypalElement: ElementRef;
  @ViewChild(PlyrComponent, { static: false }) plyr: PlyrComponent;
  //ccp info
  @ViewChild("ccpFile", { static: false }) ccpFile: ElementRef;
  //user info free access
  @ViewChild("reasonFreeAccess", { static: false })
  reasonFreeAccess: ElementRef;
  @ViewChildren("accordion") accordion: QueryList<MatAccordion>;
  @ViewChild("thankyoutoggle", { static: false }) thankyoutoggle: ElementRef;
  @ViewChild("chapterBtn", { static: false }) chapterBtn: ElementRef;
  

  fileUrl;
  paymentMethod = "";
  originalEuroPriceBeforeConversion: any;
  originalTotalPriceForPayments: any;

  total: any = 0;
  paymentSuccess = null;
  paymentFailed = null;
  requestFree;
  freeAccessSuccess = null;
  freeAccessFailed = null;
  canViewCourse = false;
  loggedIn = false;
  loading = false;
  downloading;

  user;
  isAlreadySubscribed: boolean = true;
  canAskFreeAccess: boolean = true;
  isLoaded: boolean = true;
  isFreeSubscribeBtnDisabled: boolean = false;


  constructor(
      private _snackBar: MatSnackBar,
      private _activeRouter: ActivatedRoute,
      private userService: UserService,
      private userAuthService: UserAuthService,
      private _router: Router,
      private _fontsService: UpdateFontsService,
      private _publicServices: PublicService,
  ) {
  }

  ngAfterViewInit(): void {
    this.accordion.changes.subscribe((comps: QueryList<MatAccordion>) => {
      comps.first && comps.first.openAll();
    });
  }
  paymentHandler: any = null;

  success: boolean = false
  openSnackBar(msg) {
    this._snackBar.open(msg, 'إغلاق', {duration: 5000});
  }

  public courseId;
  public userId;
  public course: any = null;
  public chapters: any = [];
  public error = null;
  private stepper: Stepper;
  direction = 'rtl';
  selectedFileIndex = null;
  selectedChapterIndex = null;
  watchedFilesCount = 0;
  currency = 'EUR ';

  // ask question
  email;
  qst;
  isSubscribeProcess: boolean = false;
  ngOnInit() {
    const paymentIndent = new URLSearchParams(window.location.search).get("payment_intent");
    if (paymentIndent) {
      this.isSubscribeProcess = true;
    }
    //About stripe
    this.invokeStripe();

    this.getRequests();
    $.getScript("../../../assets/imagesSlideshowJs/dropdown.js");
    this.direction = localStorage.getItem('direction');
    this.userAuthService.source.subscribe((value) => {
      this.direction = value;
    });
    this._activeRouter.params.subscribe(
      (params) => (this.courseId = params.id)
    );
    this.userId = this.userAuthService.loggedUserId;

    if (this.userId) this.loggedIn = true;

    this.user = this.userAuthService.user;

    // http://localhost:4200/clientCourseView/6472286f37d7451351aea2fe?payment_intent=pi_3NH7GjCmC3ZwNXOu0pbulvIl&payment_intent_client_secret=pi_3NH7GjCmC3ZwNXOu0pbulvIl_secret_XHrpxGdbg3zJSEQj3KL37NHCS&redirect_status=succeeded

    this.getCurrentCourse(this.courseId);


    this.stepper = new Stepper(document.querySelector("#stepper1"), {
      linear: false,
      animation: true,
    });
  }

  getCurrentCourse(courseId: string) {
    this.userService.getCourse(courseId).subscribe(
        (res: any) => {
          this.canViewCourse = true;
          this.course = res;
          this.updateCourseDescription();
          this.chapters = this.course.chapters;
          if (localStorage['selectedFile' + this.course._id]) {
            const file = JSON.parse(localStorage['selectedFile' + this.course._id]);
            this.changeVideoUrl(file, file.selectedChapterIndex, file.selectedFileIndex);
          } else {
            if (this.chapters && this.chapters && this.chapters[0].files) {
              if (this.isAlreadySubscribed) {
                const fileUrl = this.chapters[0].files[0].link;
                this.checkAndSetUrl(fileUrl);
              }
              this.selectedFileTitle = this.chapters[0].files[0].title.ar;
            }

            if (this.isAlreadySubscribed) {
              this.selectedFileIndex = this.selectedChapterIndex = 0;
            }
          }
          //price
          this.total =
              this.course.price.euro -
              ((this.course.discount ? this.course.discount : 0) * this.course.price.euro) / 100;
          this.total = this.total.toFixed(2);
        },
        (error) => {
          this.isAlreadySubscribed = false;
          this.error = error;
          this.getPublicCourse();
        }
    );
  }


  refreshCourse() {
    this.userService.getCourse(this.courseId).subscribe(
        (res: any) => {
          this.canViewCourse = this.isAlreadySubscribed = true;
          this.course = res;
          this.updateCourseDescription();
          this.chapters = this.course.chapters;
          this.chapterBtn.nativeElement.click();
          this.changeVideoUrl(this.course.chapters[0].files[0]);
        },
        (error) => {
          this.error = error;
      }
    );
  }

  goto(url) {
    if(!url)return;
    this.downloading = url;
    this.userService.getFile(url).subscribe(
        (res: any) => {
          let dataType = res.type;
          let binaryData = [];
          binaryData.push(res);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          if (url.split("-")[3]){
            downloadLink.setAttribute('download', url.split("-")[3]);
          }
          downloadLink.target = "_blank";

          document.body.appendChild(downloadLink);
          downloadLink.click();
          this.downloading = null;
        },
        () => {
          this.downloading = null;
        }
      );
  }

  
  next() {
    this.stepper.next();
  }

  getPublicCourse() {
    this.isLoaded = false;
    this.userService.getPublicCourse(this.courseId).subscribe(
        (res: any) => {
          this.course = res;
          if(this.course.price.euro != 0) {
            this._publicServices.convertCurrency({amount: res.price.euro, courseCurrency: 'EUR'})
                .subscribe((res) => {
                  this.originalEuroPriceBeforeConversion = this.course.price.euro;
                  this.course.price.euro = res.data.convertedAmount;
                  this.currency = res.data.clientCurrency;
                  this.updateCourseDescriptionAndSetPrice();
                }, (err) => {
                  console.error(err);
                  this.updateCourseDescriptionAndSetPrice();
                });
          } else {
            this.updateCourseDescriptionAndSetPrice();
          }

        },
        (error) => {
          this.error = error;
        }
    );
  }

  drivePattern = /^https?:\/\/drive\.google\.com\/(file\/d\/|open\?id=)([a-zA-Z0-9_-]+)([?&]usp=sharing)?/;
  youTubePattern = /^https?:\/\/(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)([?&]t=[0-9]+)?/;
  isDriveUrl: boolean = false;
  isYouTubeUrl: boolean = true;
  isNormalText: boolean = false;
  showLoader: boolean = false;

  selectedFileTitle: any = null;

  changeVideoUrl(file, chapterIndex = 0, fileIndex = 0) {
    window.scrollTo(0, 400);

    this.selectedChapterIndex = chapterIndex;
    this.selectedFileIndex = fileIndex;
    if (!this.isAlreadySubscribed) {
      return;
    }

    this.measureWatchedFilesCount(chapterIndex, fileIndex);
    let url = file.link;
    this.selectedFileTitle = file.title.ar;
    this.showLoader = true;
    this.sleep(1000).then(() => {
      this.checkAndSetUrl(url);
      this.showLoader = false;

      file.selectedFileIndex = this.selectedFileIndex = fileIndex;
      file.selectedChapterIndex = this.selectedChapterIndex = chapterIndex;
      file.watchedFilesCount = this.watchedFilesCount;
      localStorage['selectedFile' + this.courseId] = JSON.stringify(file);
    });
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  submitFreeAccess() {
    this.freeAccessSuccess = null;
    this.freeAccessFailed = null;

    if(!this.reasonFreeAccess.nativeElement.value) return;

    const freeAccess = new FormData();
    let data = {
      request: {
        type : "help",
        reason : this.reasonFreeAccess.nativeElement.value,
      },
      course: this.course._id
    }
    let requestAsFile = new Blob([JSON.stringify(data)],{
      type:'application/json',
    });

    freeAccess.append("data", requestAsFile, "data");

    this.userService.addRequestPayment(freeAccess).subscribe(
      () => {
        this.freeAccessSuccess = "success";
        this.openSnackBar("تمت العملية");
        this.canAskFreeAccess = false;
      },
      (error) => {
        this.freeAccessFailed = error;
        this.openSnackBar("حدث خطأ");
      }
    );
  }


  async addFreeCourse() {
    this.isFreeSubscribeBtnDisabled = true;
    if(!this.loggedIn) {
      await this._router.navigateByUrl('/logInClient')
      return;
    }
    this.loading = true;
    let freePayment: {};

    freePayment = {
      method: "free",
      state: "completed",
      course: this.course._id,
    };
    this.userService.addPayment(freePayment).subscribe(
      () => {
        this.paymentMethod = null;
        this.loading = false;
        this.handleSuccess(true);
      },
      (error) => {
        this.paymentFailed = error;
        this.loading = false;
        this.openSnackBar("حدث خطأ");
      }
    );
  }


  CcpFileError = ""

  emptyVars() {
    this.CcpFileError = "";
  }

// @ts-ignore
  @ViewChild('closeBtnForPaymentModal') closeBtnForPaymentModal: ElementRef;

  setUpPaypalPayment() {
    paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: this.course.name.ar,
                amount: {
                  value: this.originalTotalPriceForPayments
                }
              }
            ]
          });
        },
        onApprove: async (data, actions) => {
          this.paymentMethod='paypal';
          this.paymentSuccess = null;
          this.paymentFailed = null;
          const order = await actions.order.capture();
          let payment = {
            course: this.course._id,
            order_id: order.id,
            seller_transaction_id: order.purchase_units[0].payments.captures[0].id,
            method: "paypal",
            state: "completed"
          };
          this.userService.addPayment(payment).subscribe(
            () => {
              this.paymentSuccess = order.id;
              this.closeBtnForPaymentModal.nativeElement.click();
              this._router.navigateByUrl(`/payment/success/${this.courseId}`)
              // this.handleSuccess();
            },
            (error) => {
              this.paymentFailed = error;
              this.openSnackBar("حدث خطأ في الدفع يرجى التواصل معنا إذا تم سحب المبلغ من حسابكم");
              this.thankyoutoggle.nativeElement.click();
            }
          );
        },
        onError: async () => {
          let payment = {
            course: this.course._id,
            method: "paypal",
            state: "error"
          };
          this.userService.addPayment(payment).subscribe(
            () => {
              this.closeBtnForPaymentModal.nativeElement.click();
              this._router.navigateByUrl(`/payment/error/${this.courseId}`)
              // this.openSnackBar("حدث خطأ في الدفع يرجى التواصل معنا إذا تم سحب المبلغ من حسابكم");
            },
            () => {
              this.closeBtnForPaymentModal.nativeElement.click();
              this._router.navigateByUrl(`/payment/error/${this.courseId}`)
              // this.openSnackBar("حدث خطأ في الدفع يرجى التواصل معنا إذا تم سحب المبلغ من حسابكم");
            }
          );
        }
      })
      .render(this.paypalElement.nativeElement);
  }


  sendMessage(){
    
  }

  files_count(){
    let count = 0;
    this.course.chapters.forEach(chapter => {
      count += chapter.files.length;
    });
    return count;
  }

  attachments_count() {
    let count = 0;
    this.course.chapters.forEach(chapter => {
      chapter.files.forEach(file => {
        if(file.attachments)count += file.attachments.length;
      });
    });
    return count;
  }
  checkIfCanGetFree(): boolean {
    return !!(this.course.price.euro!==0 && this.course.get_free)
  }

  requests = [];

  private getRequests() {
    this.userService.getAllRequests().subscribe((res: any) => {
      this.requests = res.map((item) => item.course);
      const idIsExist = this.requests.find(id => id === this.courseId)
      if(idIsExist) {
        this.isAlreadySubscribed = true;
      }
    }, console.error);
  }

  async checkIsLogged() {
    if(!this.loggedIn) {
      await this._router.navigateByUrl('/logInClient');
    }
  }



  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.onload = () => {
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: environment.STRIPE_PUBLISH_KEY,
          locale: 'auto',
          token: function () {
          },
        });
      };

      window.document.body.appendChild(script);
    }
  }
  isFilePdf(attachment: any) {
    return attachment.match(/\.pdf$/);
  }

  isFileAudio(attachment: any) {
    return attachment.match(/\.mp3$/);
  }

  isFileImg(attachment: any) {
    return attachment.match(/\.(png|jpg|jpeg)$/);
  }

  isFileDoc(attachment: any) {
    return attachment.match(/\.docx$/);
  }

  handleSuccess(isFree = false) {
    this.refreshCourse();
    this.openSnackBar("تمت عملية الشراء");
    if (!isFree) {
      this.thankyoutoggle.nativeElement.click();
    }
  }
  getNextFile() {
    if (this.selectedFileIndex < this.course.chapters[this.selectedChapterIndex].files.length - 1) {
      this.selectedFileIndex++;
    } else if (
        this.selectedFileIndex === this.course.chapters[this.selectedChapterIndex].files.length - 1
        && this.selectedChapterIndex !== this.watchedFilesCount
    ) {
      this.selectedFileIndex = 0;
      this.selectedChapterIndex++;
    } else {
      return;
    }
    this.changeVideoUrl(
        this.course.chapters[this.selectedChapterIndex].files[this.selectedFileIndex],
        this.selectedChapterIndex,
        this.selectedFileIndex
    );
  }

  getPrevFile() {
    if (this.selectedFileIndex > 0) {
      this.selectedFileIndex--;
    } else if (this.selectedFileIndex === 0 && this.selectedChapterIndex !== 0) {
      this.selectedChapterIndex--;
      this.selectedFileIndex = this.course.chapters[this.selectedChapterIndex].files.length - 1;
    } else {
      return;
    }
    this.changeVideoUrl(
        this.course.chapters[this.selectedChapterIndex].files[this.selectedFileIndex],
        this.selectedChapterIndex,
        this.selectedFileIndex
    );
  }

  private measureWatchedFilesCount(chapterIndex: number, fileIndex: number) {
    this.watchedFilesCount = 0;
    this.course.chapters.forEach((chapter, index) => {
      if (index < chapterIndex) {
        this.watchedFilesCount += chapter.files.length;
      } else if (index === chapterIndex) {
        this.watchedFilesCount += fileIndex;
      }
    });
  }

  private checkAndSetUrl(url: any) {
    this.isYouTubeUrl = this.isDriveUrl = this.isNormalText = false;
    if (this.drivePattern.test(url)) {
      this.isDriveUrl = true;
      let id = url.match(this.drivePattern)[2];
      url = environment.DRIVE_CONSTANT_URL + id;
    } else if (this.youTubePattern.test(url)) {
      this.isYouTubeUrl = true;
    } else {
      this.isNormalText = true;
    }
    this.fileUrl = url;
  }

  private updateCourseDescription() {
        this.course.description['ar'] = this._fontsService.convertTheFonts(this.course.description['ar'])
        .replace(/<ul>/g, '<ul style="padding-right: 30px;">')
        .replace(/<ol>/g, '<ol style="padding-right: 30px;">');
  }

  private updateCourseDescriptionAndSetPrice() {
    this.updateCourseDescription();
    this.total =
        this.course.price.euro -
        ((this.course.discount ? this.course.discount : 0) * this.course.price.euro) / 100;
    this.total = this.total.toFixed(2);

    this.originalTotalPriceForPayments = this.originalEuroPriceBeforeConversion -
        ((this.course.discount ? this.course.discount : 0) * this.originalEuroPriceBeforeConversion) / 100;

    if(isNaN(this.originalTotalPriceForPayments)) {
        this.originalTotalPriceForPayments = this.total;
    }

    this.isLoaded = true;
    this.checkPaypalAvailability();
  }

  checkPaypalAvailability(attempts = 0, maxAttempts = 3, interval = 1000) {
    if (typeof paypal !== 'undefined' && typeof paypal.Buttons === 'function') {
      this.setUpPaypalPayment();
    } else {
      // PayPal SDK is not yet available
      console.log('PayPal SDK not yet available')
      if (attempts < maxAttempts) {
        // Retry after a certain interval
        setTimeout(() => {
          this.checkPaypalAvailability(attempts + 1, maxAttempts, interval);
        }, interval);
      } else {
        // Maximum attempts reached, handle error or fallback to an alternative
        console.error('PayPal SDK could not be loaded');
      }
    }
  }
  
  private  pausedCourses = ['6472286f37d7451351aea2fe'];
  
  isPausedCourse() {
      return this.pausedCourses.includes(this.courseId);
  }
}
