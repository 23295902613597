import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { UserAuthService } from "src/app/services/user-auth.service";
import { ServerApi } from "src/environments/environment";

@Component({
  selector: "app-client",
  templateUrl: "./client.component.html",
  styleUrls: ["./client.component.css"],
})

export class ClientComponent implements OnInit {
  id;
  userImage = "";
  page: string;

  direction:any = "rtl"
  drop1en=false
  drop1ar=false
  drop2ar=false
  drop2en=false
  constructor(
    private userAuthService: UserAuthService,
    location: Location,
    router: Router
  ) {
    router.events.subscribe(() => {
      if (location.path() != "") {
        this.page = location.path();
      } else {
        this.page = "Home";
      }
    });
  }


  ngOnInit() {
    $.getScript("../../../assets/imagesSlideshowJs/func.js");

    this.id = this.userAuthService.loggedUserId;
    if(this.id)this.userImage = ServerApi.PublicRouteTemp + '/users/image/' + this.id;
    this.userAuthService.userId.subscribe(() => {
      this.id = this.userAuthService.loggedUserId;
      if(this.id)this.userImage = ServerApi.PublicRouteTemp + '/users/image/' + this.id;
    });
    
    this.setDirection(this.direction);
  }
  setDirection(dir) {
    this.direction = dir;
    localStorage.setItem('direction', this.direction);
    this.userAuthService.changeSource(this.direction);

  }
  logout() {
    this.userAuthService.logout().subscribe(
      () => {
        this.id = null;
        this.userAuthService.signUserOut();
        this.handleCloseMenu();
      },
      () => {
      }
    );
  }
  onNavigate(url){
    window.open(url, "_blank");
  }

  // @ts-ignore
  @ViewChild('navMenu') navMenu: ElementRef;
  handleCloseMenu() {
    if (window.matchMedia("(max-width: 767px)").matches) {
      this.navMenu.nativeElement.click();
    }
  }
}
