export const environment = {
  production: true,
  STRIPE_PUBLISH_KEY: 'pk_live_51Mf9gOCmC3ZwNXOuOOeW7l5vsdVe5bMYfohqVIQdThUh5G0iWd00KUVY7EjG2k9EqahoC4G5njU0crVuVxpS8zl800dUNSUycG',
  BOOKING_LINK: 'https://calendly.com/mohamednadjmo/60min?month=2023-05',
  DRIVE_CONSTANT_URL: 'https://drive.google.com/uc?export=download&id=',
  GOOGLE_OAUTH_CLIENT_ID: '979252862352-fn0skb6no34urlfokc3cm1ft70c9a2e6.apps.googleusercontent.com',
  GOOGLE_OAUTH_CLIENT_SECRET: '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',
  GOOGLE_OAUTH_SCOPE: 'openid profile email https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
  APP_DOMAIN: 'https://thebravespirit.org',
};
export class ServerApi {
  public static PublicRoute = "https://api.thebravespirit.org";
  public static PublicRouteTemp = "https://api.thebravespirit.org";
  public static CONTACT_NUMBER: number = 201095896510;
  static CONTACT_NUMBER_VIEW: string = '+ (20) 109 589 6510';
  public static CIPHER_ALGORITHM = "aes-256-cbc";
  public static CIPHER_KEY="CnvNJLMwpVHQ477m8iFbvrB19nmHWTYw";
  public static CIPHER_IV="f9b774496c09fb0bb56f3047f602eafb";
}