<!--<div class="container text-center w-100">-->
    <form id="payment-form" class="mx-auto w-100">
        <div id="link-authentication-element">
            <!--Stripe.js injects the Link Authentication Element-->
        </div>
        <div id="payment-element">
            <!--Stripe.js injects the Payment Element-->
        </div>
        <button id="submit">
            <div class="spinner hidden" id="spinner"></div>
            <span id="button-text" (click)="handleSubmit($event)">Pay now</span>
        </button>
        <div id="payment-message" class="hidden"></div>
    </form>
<!--</div>-->

<script src="./checkout.js"></script>