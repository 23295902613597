import {Component, OnInit} from "@angular/core";
import {PublicService} from "src/app/services/public.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ServerApi} from "../../../environments/environment";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.css"],
})
export class ContactUsComponent implements OnInit {
  constructor(
      private publicService: PublicService,
      private fb: FormBuilder,
      private _snackBar: MatSnackBar
  ) {
  }

  contactNumber = ServerApi.CONTACT_NUMBER;
  contactNumberView = ServerApi.CONTACT_NUMBER_VIEW;
  form: FormGroup;
  isSubmitted = false;
  direction = "rtl";
  iconStyle: any = {
    "margin": "0px 5px"
  };

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      subject: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      message: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(500)]],
    });
  }

  openSnackBar(msg) {
    this._snackBar.open(msg, 'إغلاق', {duration: 10000});
  }

  sendMessage() {
    this.isSubmitted = true;
    console.log(this.form.value)
    if (this.form.invalid) {
      return;
    }
    this.publicService.contactUsForm(this.form.value).subscribe(
        () => {
          let msg = (this.direction == 'ltr') ?
              "Your message has been sent successfully" :
              "تم ارسال رسالتك بنجاح";
          this.openSnackBar(msg);
        },
        () => {
          let msg = (this.direction == 'ltr') ?
              "something went wrong please try again" :
              "حدث خطأ ما يرجي المحاولة مرة اخري";
          this.openSnackBar(msg);
          this.isSubmitted = false;
        }
    );
  }
}
