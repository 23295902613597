<app-whatsapp-icon></app-whatsapp-icon>
<div class="container">
    <div class="wrapper row" dir="ltr">
        <div class="col-12 col-lg-8 offset-lg-2">
            <section class="container__text text-center pt-4 pb-2">
                <h1 class="txtMainColor mb-3">تواصل معنا</h1>
                <p dir="rtl" class="smLineHeight">
                    اذا كان لديك استفسار او تواجهك مشكلة تقنية او مشكلة في الاشتراك يرجى منك ان تضع رسالتك اسفل الصندوق او تتواصل معنا على الرقم
                    <a class="d-md-none" target="_blank" [href]="'https://wa.me/' + contactNumber"><bdi>{{contactNumberView}}</bdi><i class="fab fa-whatsapp mx-1"></i></a>
                    <a class="d-none d-md-inline" target="_blank" [href]="'https://web.whatsapp.com/send?phone=' + contactNumber"><bdi>{{contactNumberView}}</bdi>
                        <app-whatsapp-icon [iconStyle]="iconStyle"></app-whatsapp-icon>
                    </a>
                </p>
            </section>
            <form [formGroup]="form" class="container__form card text-right" dir="rtl">
                <div class="mb-3">
                    <label>البريد الإلكتروني</label>
                    <input formControlName="email" type="email" class="form-control">
                    <div *ngIf="
                                      (form.controls.email.invalid && isSubmitted) ||
                                      form.controls.email.dirty ||
                                      form.controls.email.touched
                                    ">
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.email.hasError('required')">
                            {{direction == 'ltr' ? 'Email is required' : 'البريد الإلكتروني مطلوب'}}
                        </small>
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.email.hasError('email')">
                            {{direction == 'ltr' ? 'Email is invalid' : 'البريد الإلكتروني غير صالح'}}
                        </small>
                    </div>
                </div>
                <div class="mb-3">
                    <label>الإسم</label>
                    <input formControlName="name" type="text" class="form-control">
                    <div *ngIf="
                                      (form.controls.name.invalid && isSubmitted) ||
                                      form.controls.name.dirty ||
                                      form.controls.name.touched
                                    ">
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.name.hasError('required')">
                            {{direction == 'ltr' ? 'user name is required' : 'الاسم مطلوب'}}
                        </small>
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.name.hasError('minlength')">
                            {{direction == 'ltr' ? 'user name must be at least 3 characters' : 'يجب أن يكون الاسم على الأقل 3 أحرف'}}
                        </small>
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.name.hasError('maxlength')">
                            {{direction == 'ltr' ? 'User name must be less than 20 characters' : 'يجب أن يكون الاسم أقل من 50 حرفًا'}}
                        </small>
                    </div>
                </div>
                <div class="mb-3">
                    <label>الموضوع</label>
                    <input formControlName="subject" type="text" class="form-control">
                    <div *ngIf="
                                      (form.controls.subject.invalid && isSubmitted) ||
                                      form.controls.subject.dirty ||
                                      form.controls.subject.touched
                                    ">
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.subject.hasError('required')">
                            {{direction == 'ltr' ? 'Subject is required' : 'الموضوع مطلوب'}}
                        </small>
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.subject.hasError('minlength')">
                            {{direction == 'ltr' ? 'Subject must be at least 3 characters' : 'يجب أن يكون الموضوع على الأقل 3 أحرف'}}
                        </small>
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.subject.hasError('maxlength')">
                            {{direction == 'ltr' ? 'Subject must be less than 50 characters' : 'يجب أن يكون الموضوع أقل من 50 حرفًا'}}
                        </small>
                    </div>
                </div>
                <div class="mb-3">
                    <label>رسالتك</label>
                    <textarea formControlName="message" type="text" class="form-control" rows="3"></textarea>
                    <div *ngIf="
                                      (form.controls.message.invalid && isSubmitted) ||
                                      form.controls.message.dirty ||
                                      form.controls.message.touched
                                    ">
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.message.hasError('required')">
                            {{direction == 'ltr' ? 'Message is required' : 'الرسالة مطلوبة'}}
                        </small>
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.message.hasError('minlength')">
                            {{direction == 'ltr' ? 'Message must be at least 10 characters' : 'يجب أن تكون الرسالة على الأقل 10 أحرف'}}
                        </small>
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.message.hasError('maxlength')">
                            {{direction == 'ltr' ? 'Message must be less than 500 characters' : 'يجب أن تكون الرسالة أقل من 500 حرف'}}
                        </small>
                    </div>
                </div>
                <div class="mb-4">
                    <small>
                        يحتاج
                        <bdi>BraveSpirit</bdi>
                        إلى معلومات الاتصال التي تقدمها لنا للاتصال بك حول طلب خدمة العملاء الخاص بك. يمكنك إلغاء
                        الاشتراك من هذه الاتصالات في أي وقت. للحصول على معلومات حول كيفية إلغاء الاشتراك وعمليات
                        الخصوصية الخاصة
                        بنا والتزامنا بحماية خصوصيتك، يرجى مراجعة <a routerLink="/terms">سياسة الخصوصية</a> الخاصة بنا
                    </small>
                </div>
                <div class="text-center pt-2">
                    <a class="bookBtn bgMainColor text-light" (click)="sendMessage()">ارسال</a>
                </div>
            </form>
        </div>
    </div>
</div>