import { Component, OnInit } from '@angular/core';
import {UserAuthService} from 'src/app/services/user-auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-client-footer',
  templateUrl: './client-footer.component.html',
  styleUrls: ['./client-footer.component.css']
})
export class ClientFooterComponent implements OnInit {

  constructor(private userService: UserService, private authService:UserAuthService) { }

  currentYear = new Date().getFullYear();
  direction='rtl';
  public error;
  public coursesList: Array<any> = null;
  
  ngOnInit() {
    this.direction = localStorage.getItem('direction');
    this.authService.source.subscribe((value) => {
      this.direction = value;
    });


    this.userService
      .getAllCourses().subscribe(
        (res: any) => {
          this.coursesList = res;
        },
        (error) => {
          this.error = error;
        }
      );

  }

}
