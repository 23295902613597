<div class="page-wrapper p-t-180 p-b-100 font-robo">
    <div class="bg"></div>
    <div class="wrapper pt-5">
        <div class="card card-2">

            <div class="card-body">
                <div class="img-cont">
                    <img src="assets/images/logo.png" alt="logo">
                </div>
                <h2 class="title">{{ direction == 'ltr' ? 'Login' : 'تسجيل الدخول' }}</h2>
                <p class="text-center d-none">
                    أهلاً بك مرةً أخرى
                    <br>
                    قم بتسجيل الدخول لتكمل رحلة تعافيك كروح شجاعة!
                </p>
                <form [formGroup]="form" class="loginForm" method="POST">
                    <div class="input-group mb-0">
                        <label for="email">{{ direction == 'ltr' ? 'Email' : 'البريد الإلكتروني' }}</label>
                        <input id="email" placeholder="jone@doe.com"
                               class="form-control mt-1"
                               type="email" name="email"
                               formControlName="email"
                        >
                    </div>
                    <div class="text-right" *ngIf="
                                      (form.controls.email.invalid && isLogged) ||
                                      form.controls.email.dirty ||
                                      form.controls.email.touched
                                    ">
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.email.hasError('required')">
                            {{ direction == 'ltr' ? 'Email is required' : 'البريد الإلكتروني مطلوب' }}
                        </small>
                        <small class="text-danger fw-bold mb-3" *ngIf="form.controls.email.hasError('email')">
                            {{ direction == 'ltr' ? 'Email is invalid' : 'البريد الإلكتروني غير صحيح' }}
                        </small>
                    </div>

                    <div class="input-group mb-0 mt-4">
                        <label for="password">{{ direction == 'ltr' ? 'Password' : 'كلمة السر' }}</label>
                        <input
                               id="password"
                               placeholder="********"
                               class="form-control"
                               [type]="passwordInputType"
                               name="password"
                               formControlName="password"
                        >
                        <div class="input-group-prepend">
                                <span id="togglePassword" (click)="togglePasswordVisibility()">
                                  <i class="fa fa-eye" id="eyeIcon" #eyeIcon></i>
                                </span>
                        </div>
                    </div>
                    <div class="text-right" *ngIf="
                                      (form.controls.password.invalid && isLogged) ||
                                      form.controls.password.dirty ||
                                      form.controls.password.touched
                                    ">
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.password.hasError('required')">
                            {{ direction == 'ltr' ? 'Password is required' : 'كلمة السر مطلوبة' }}
                        </small>
                        <small class="text-danger fw-bold mb-3"
                               *ngIf="form.controls.password.hasError('minlength')">
                            {{ direction == 'ltr' ? 'Password must be at least 8 characters' : 'كلمة السر يجب أن تكون 8 أحرف على الأقل' }}
                        </small>
                    </div>
                    <div class="remember-me">
                        <input *ngIf="direction=='rtl'" type="checkbox" value="1">
                        <label>{{direction == 'ltr' ? 'Remember me' : 'تذكر معلوماتي'}}</label>
                    </div>
                    <label *ngIf="errorMsg && direction=='ltr'" class="error">{{errorMsg}}</label>
                    <label *ngIf="errorMsg_ar && direction=='rtl'" class="error">{{errorMsg_ar}}</label>
                    <div class="row">
                        <button *ngIf="direction=='ltr'" class="btn btn-block" type="submit" (click)="login()">Login
                        </button>
                    </div>
                    <button class="commonBtn" type="submit"
                            (click)="login()">{{ direction == 'ltr' ? 'Login' : 'تسجيل الدخول' }}</button>
                    <div class="oauth-separator">
                        <span>{{direction == 'ltr' ? 'or:' : 'أو'}}</span>
                    </div>
                    <button type="button" class="commonBtn oauth-button google" (click)="loginWithGoogle()">
                        <img src="/assets/googleLogo.webp" alt="Google logo" class="login-form__btn-icon"> Sign in with Google
                    </button>
                    <div class="links text-right">
                        <span class="forgetPassword text-primary" data-toggle="modal"
                              data-target="#resetPass">{{ direction == 'ltr' ? 'Forget Password? ' : 'هل نسيت كلمة السر؟ ' }}</span>
                        <a class="mb-1" routerLink="/register">{{ direction == 'ltr' ? 'Register' : 'تسجيل' }}</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" id="resetPass">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title txtMainColor">{{direction == 'ltr' ? 'Reset Password' : 'إعادة تعيين كلمة المرور'}}</h5>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
        </div>
          <div class="modal-body">
              <form method="POST">
                  <div class="input-group text-right">
                      <h6 class="txtMainColor">{{direction == 'ltr' ? 'Enter your email address and we will send you a link to reset your password' : 'أدخل عنوان بريدك الإلكتروني وسنرسل لك رابطًا لإعادة تعيين كلمة المرور الخاصة بك'}}</h6>
                      <input class="form-control" type="email" name="changeEmail" placeholder="jone@doe.com"
                             [(ngModel)]="forgetPassMail"
                             (keyup.enter)="request(forgetPassMail)">
                  </div>
              </form>
          </div>
          <div class="modal-footer text-right">
              <button class="btn btn-primary mx-2"
                      (click)="request(forgetPassMail)" data-dismiss="modal"> {{direction == 'ltr' ? 'Request a new password' : 'طلب كلمة سر جديدة'}}</button>
              <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="forgetPassMail = ''">{{direction == 'ltr' ? 'Close' : 'إغلاق'}}</button>
          </div>
      </div>
    </div>
  </div>