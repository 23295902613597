<div class="container py-5 text-right">
    <h1 class="text-center">سياسة الخصوصية</h1>
    <p class="lightFont">من أجل حماية بياناتك في عمليات النقل بينك وبين thebravespirit.org وشركائنا ، يتم جمع المعلومات الشخصية بواسطة
        thebravespirit.org من خلال اتصالات المتصفح الآمنة ، تساعد إجراءات الأمان هذه في منع الوصول غير المصرح به والحفاظ
        على أمان البيانات.</p>

    <h1>شرط السن لاستخدام الموقع:</h1>
    <p class="lightFont">هذا الموقع غير مخصص للاستخدام من قبل الأشخاص الذين تقل أعمارهم عن 18 عامًا ، يجب أن يكون عمرك 18 عامًا أو أكثر
        لتقديم أي معلومات شخصية إلى الموقع أو إلينا من خلال الموقع.</p>

    <ol>
        <li>
            <h1> جمع المعلومات :</h1>
            <p class="lightFont" style="text-decoration: underline">معلومات التعريف الشخصية:</p>
            <p class="lightFont">قد يطلب الموقع ويجمع بعض معلومات التعريف الشخصية بما في ذلك ، على سبيل المثال لا الحصر ، بيانات التطبيق والمعلومات الشخصية الأخرى الواردة في تقديمات المستخدم ، من خلال وسائل مختلفة مثل النماذج عبر الإنترنت والتطبيقات عبر الإنترنت والبريد الإلكتروني والتعليقات المقدمة إلى الموقع. بخلاف ما هو منصوص عليه في سياسة الخصوصية هذه ، وما لم تحصل على إذن منك للقيام بذلك ، لن نبيع أو نكشف عن معلوماتك الشخصية لأطراف ثالثة.</p>

            <p class="lightFont" style="text-decoration: underline">المعلومات غير الشخصية:</p>
            <p class="lightFont">قد نقوم أيضًا بجمع بعض المعلومات غير الشخصية عبر الموقع ، مثل نشاط استخدام الموقع والتفضيلات ، والمعروفة أيضًا باسم البيانات الديموغرافية أو بيانات الملف الشخصي.</p>
        </li>
        <li>
            <h1>الاستخدام والمشاركة:</h1>
            <p class="lightFont">نحن نستخدم المعلومات التي تم جمعها على هذا الموقع ومن خلاله لمجموعة متنوعة من الأغراض في سياق العمل وعلى النحو المنصوص عليه بخلاف ذلك في هذه الوثيقة. على سبيل المثال ، يمكن استخدام هذه المعلومات لتقييم أجزاء الموقع الأكثر شيوعًا من غيرها ، وتحديد من أين أتى الزوار ، وأين يذهبون ، ومدة بقائهم على صفحة معينة ، أو الموقع بشكل عام. قد نقوم بمطابقة المعلومات التي تم جمعها منك من خلال وسائل مختلفة أو في أوقات مختلفة ، واستخدام هذه المعلومات مع المعلومات التي تم الحصول عليها من مصادر أخرى ، بما في ذلك الأطراف الثالثة.
                نحتفظ أيضًا بالحق في الكشف عن أي معلومات تتعلق بك إذا كان ذلك مطلوبًا بموجب القانون أو لحماية أو الدفاع عن حقوقنا أو حقوق أو رفاهية مستخدمينا أو إذا كنت متورطًا (أو يشتبه بشكل معقول في مشاركتك) في أي نشاط غير قانوني ، حتى بدون أمر استدعاء أو أمر قضائي أو أمر محكمة.
            </p>
        </li>
        <li>
            <h1>الأمن:</h1>
            <p class="lightFont">يدعم نموذج الطلب الموجود على الموقع التشفير للمساعدة في حماية أمان نقل البيانات إلى الموقع ،على الرغم من أننا سنتخذ جميع الاحتياطات الأمنية المعقولة فيما يتعلق بالمعلومات المخزنة على الموقع ، نظرًا للطبيعة المفتوحة للإنترنت ، لا يمكننا ضمان أن أي معلومات مخزنة على خوادمنا ، أو يتم إرسالها إلى أو من المستخدم ، ستكون خالية من الوصول غير المصرح به ، ونخلي مسؤوليتنا عن أي سرقة أو فقدان أو وصول غير مصرح به أو تلف أو اعتراض أي بيانات أو اتصالات. باستخدام الموقع ، فإنك تقر بأنك تفهم وتوافق على تحمل هذه المخاطر.
            </p>
        </li>
        <li>
            <h1>الروابط:</h1>
            <p class="lightFont">قد يحتوي هذا الموقع على روابط أو مراجع لمواقع ويب أخرى خارجة عن سيطرتنا. يرجى العلم أنه ليس لدينا سيطرة على هذه المواقع وأن سياسة الخصوصية الخاصة بنا لا تنطبق على هذه المواقع.
            </p>
        </li>
        <li>
            <h1>موافقتك على هذه السياسة:</h1>
            <p class="lightFont">باستخدام هذا الموقع ، فإنك تشير إلى موافقتك على سياسة الخصوصية الخاصة بنا. إذا كنت لا توافق على هذه السياسة ، فيرجى عدم استخدام موقعنا. نحن نحتفظ بالحق ، وفقًا لتقديرنا ، في تغيير أو تعديل أو إضافة أو إزالة أجزاء من هذه السياسة في أي وقت حتى يتم تشجيع الزوار على مراجعة هذه السياسة من وقت لآخر. استمرار استخدامك لموقعنا بعد نشر التغييرات على هذه الشروط يعني أنك تقبل هذه التغييرات.
            </p>
        </li>
    </ol>
</div>