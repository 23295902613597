import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {PublicService} from "src/app/services/public.service";
import {UserAuthService} from "src/app/services/user-auth.service";
import {ServerApi} from "src/environments/environment.js";
import "../../../assets/imagesSlideshowJs/slideshow.js";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.css"],
})
export class HomePageComponent implements OnInit {
  @ViewChild("userFName", {static: false}) userFName: ElementRef;
  @ViewChild("userLName", {static: false}) userLName: ElementRef;
  @ViewChild("userEmail", { static: false }) userEmail: ElementRef;
  @ViewChild("book", { static: false }) book: ElementRef;

  isLoaded: boolean = false;


  @ViewChildren("sections") sections: QueryList<ElementRef>;

  home;
  publicUrl = `${ServerApi.PublicRouteTemp}/home/`;
 
  
  book_form_error : any = {};
  popup_info;


  downloadsNumber
  orderList = [
    "banner",
    "advices",
    "problem",
    "note1",
    "solutions",
    "companies",
    "recommendations",
    "about",
    "steps",
    "guaranties",
    "note2",
    "categories",
    "testimonials",
    "paid content",
    "services",
    "book",
    "footer"
  ]
  backgroundList = ["#ffffff", "#ffffff", "#fffff6", "linear-gradient(93deg, #0dc4f8 0%, #0dfa9e 100%)", "#ffffff", "#fffff6", "#07708e", "#fffff6", "#ffffff", "#078f5a", "linear-gradient(93deg, #0dc4f8 0%, #0dfa9e 100%)", "#ffffff", "#fffff6", "#dcf7fe", "#fffff6", "#ffffff", "#242f3d"];
  direction = 'rtl';
  goToBookSection;
  loadingError;
  solutions_mobile: any[][];
  document;

  constructor(private publicService: PublicService, private authService: UserAuthService, private route: ActivatedRoute,
              private _fb: FormBuilder,
              private _snackBar: MatSnackBar,
  ) {
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment == "bookSection") this.goToBookSection = fragment;
    });
  }



  bookForm: FormGroup;

  ngOnInit() {
    this.bookForm = this._fb.group({
        fName: ['', [Validators.required, Validators.minLength(3)]],
        lName: ['', [Validators.required, Validators.minLength(3)]],
        email: ['', [Validators.required, Validators.email]],

    });
    this.getHome();
    this.direction = localStorage.getItem('direction');
    this.authService.source.subscribe((value) => {
      this.direction = value;
    });
    $.getScript("../../../assets/imagesSlideshowJs/slideshow.js");
  }

  getHome(){
    this.publicService.downloadNumber().subscribe(
      (res: any) => {
        this.downloadsNumber = res;
        this.loadingError = false;
        this.route.fragment.subscribe(hash => {
          if(hash) {
            this.scrollToEntireView(hash);
          }
        })
      },
      (error) => {
        this.loadingError = error;
      }
    );
    this.publicService.getHome().subscribe(
      (res: any)=>{
        this.home = res;
        this.updateNecessarySections();
        this.home.problem['ar']['description'] = this.home.problem['ar']['description']
            .replace(/<ul>/g, '<ul style="padding-right: 30px;">')
            .replace(/<ol>/g, '<ol style="padding-right: 30px;">');
        this.route.fragment.subscribe((fragment: string) => {
          if(fragment == "bookSection")this.goToBookSection = fragment;
        });
        this.loadingError = false;
        this.solutions_mobile_fill();
      },
      (error)=>{
        this.loadingError = error;
        console.log(error);
      }, ()=> {
        this.isLoaded = true;
      }
    )
  }

  solutions_mobile_fill() {
    let solutions_list = this.home.solutions.ar.descriptions.map((s, index) => {
      return { ar: s, en: this.home.solutions.ar.descriptions[index]}
    });

    let n = this.home.solutions.ar.descriptions.length;
    let n1 = this.home.solutions.ar.descriptions.length;
    if (n % 3 == 0)
      n1 = n / 3;
    if (n % 3 == 1)
      n1 = (n + 2) / 3;
    if (n % 3 == 2)
      n1 = (n + 1) / 3;

    let temp = new Array(3).fill("").map(() => new Array(n1).fill(""));
    let i = 0,j=0;

    while (j < n1) {
      if (solutions_list[i])
        temp[0][j] = solutions_list[i];

      if (solutions_list[i + 1])
        temp[1][j] = solutions_list[i + 1];
      else
        temp[1][j] = solutions_list[0];

      if (solutions_list[i + 2])
        temp[2][j] = solutions_list[i + 2];
      else
        temp[2][j] = solutions_list[1];

      i += 3;
      j += 1
    }
    this.solutions_mobile = temp;
  }

  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
  }


  send() {
    this.book_form_error = {};
    if(this.userFName.nativeElement.value == null || this.userFName.nativeElement.value == "" || this.userFName.nativeElement.value.length < 3)
    this.book_form_error.name = "الاسم يتكون من 3 حروف على الأقل";
    if(this.userLName.nativeElement.value == null || this.userLName.nativeElement.value == "" || this.userLName.nativeElement.value.length < 3)
    this.book_form_error.lname = "اللقب يجب أن يتكون من 3 حروف على الأقل";
    if(!this.validateEmail(this.userEmail.nativeElement.value))
    this.book_form_error.email = "البريد الإلكتروني غير صالح";

    if(this.book_form_error.name ||  this.book_form_error.lname || this.book_form_error.email)return;

    let obj = {
      firstname: this.userFName.nativeElement.value,
      lastname: this.userLName.nativeElement.value,
      email: this.userEmail.nativeElement.value,
    };
    this.publicService.contactUsDownload(obj).subscribe(
      (res: any) => {
        this.downloadsNumber += 1;
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob([res], {type: "application/pdf"}));

        downloadLink.setAttribute('download', "book");
        document.body.appendChild(downloadLink);
        downloadLink.click();

      }, console.error);
  }

  private updateBannerDescription() {
    this.htmlTags.forEach((tag) => {
      this.home.banner.ar.description = this.home.banner.ar.description
          .replace(new RegExp(tag, 'g'), `${tag} class="lightFont h4 lineHeight40"`);
    });
  }

  private updateNecessaryNames(sectionName: string) {
    this.home[sectionName]['ar'] = this.home[sectionName]['ar'].map((item) => {
      let splitLines = item.name.split("\n");
      let firstLine = splitLines[0];
      let remainingLines = splitLines.slice(1).join("<br>");
      item.name = item.name
          .replace(/&nbsp;/g, '');

      if(sectionName == 'testimonials') {
        item.name = item.name
            .replace(firstLine, `<p class="first-line opacity-1 lightFont" style="font-size: 25px">${firstLine}</p>`)
            .replace(remainingLines, `<span class="remaining-lines txtMainColor lightFont" style="font-size: 21px">${remainingLines}</span>`);
      } else if (sectionName == 'recommendations') {
        item.name = item.name
            .replace(firstLine, `<span class="first-line opacity-1" style="font-size: 21px">${firstLine}</span><br>`)
            .replace(remainingLines, `<span class="remaining-lines lightFont opacity-5" style="font-size: 21px">${remainingLines}</span>`);
      }
      return item;
    });
  }


  private updateAboutDescription() {
      this.htmlTags.forEach((tag) => {
        this.home['about']['ar']['zipped_description'] = this.home['about']['ar']['zipped_description']
            .replace(new RegExp(tag, 'g'), `${tag} class="lightFont" style="font-size: 19px;"`)
            .replace(/&nbsp;/g, '');
      });
  }

  private updateGuarantyDescription() {
    this.home['guaranties']['ar'].forEach((guaranty, index) => {
      this.htmlTags.forEach((tag) => {
        this.home['guaranties']['ar'][index]['description'] = guaranty['description']
            .replace(new RegExp(tag, 'g'), `${tag} class="lightFont" style="font-size: 20px"`)
            .replace(/&nbsp;/g, '');
      });
    })
  }

  private updateProblemDescription() {
    this.htmlTags.forEach((tag) => {
      if(['<ul', '<ol'].includes(tag)) {
        this.home['problem']['ar']['description'] = this.home['problem']['ar']['description']
            .replace(new RegExp(tag, 'g'), `${tag} style="padding-right: 30px !important;"`);
      } else {
        this.home['problem']['ar']['description'] = this.home['problem']['ar']['description']
            .replace(new RegExp(tag, 'g'), `${tag} class="lightFont txtSecondaryColor mb-0"`);
      }
    });
  }

  private updateRecommendationsSaying () {
    this.home['recommendations']['ar'].forEach((recommendation, index) => {
      this.htmlTags.forEach((tag) => {
        this.home['recommendations']['ar'][index]['saying'] = recommendation['saying']
            .replace(new RegExp(tag, 'g'), `${tag} class="lightFont" style="font-size: 21px"`);
      });
    })
  }

  private updateTestimonialsSaying() {
    this.home['testimonials']['ar'].forEach((testimonial, index) => {
      this.htmlTags.forEach((tag) => {
        this.home['testimonials']['ar'][index]['saying'] = testimonial['saying']
            .replace(new RegExp(tag, 'g'), `${tag} class="lightFont" style="font-size: 21px"`);
      });
    })
  }

  htmlTags = [
      '<p', '<span', '<ul', '<ol', '<li', '<br', '<h1', '<h2', '<h3', '<h4', '<h5', '<h6', '<div', '<img', '<a',
    '<iframe', '<table', '<tbody', '<tr', '<td', '<th', '<thead', '<tfoot'];

  private updateNecessarySections() {
    this.updateBannerDescription();
    this.updateNecessaryNames('testimonials');
    this.updateNecessaryNames('recommendations');
    this.updateProblemDescription();
    this.updateAboutDescription();
    this.updateRecommendationsSaying();
    this.updateGuarantyDescription();
    this.updateTestimonialsSaying();
  }


  // @ts-ignore
  @ViewChild('downloadBookLink') downloadBookLink: ElementRef;

  openSnackBar(msg) {
    this._snackBar.open(msg, 'إغلاق', {duration: 5000});
  }

  downloadBook() {
    if(this.bookForm.invalid) return;

    this.publicService.downloadBook(this.bookForm.value).subscribe(
        () => {
          this.downloadBookLink.nativeElement.click();
          this.bookForm.reset();
        }, (error) => {
            let msg = 'حدث خطأ ما، يرجى المحاولة مرة أخرى.';
            if(error.error.msg === 'Email already exists in the file.'){
              msg = 'هذا البريد الإلكتروني موجود بالفعل.';
            }
            this.openSnackBar(msg);
        });
  }

  scrollToEntireView(hash: string) {
    if (hash) {
      const el = document.querySelector(`#${hash}`);
      // @ts-ignore
      el.scrollIntoView({ behavior: 'smooth' });
      return false;
    }
  }
}
