<!--<div class="page-wrapper p-t-180 p-b-100 font-robo" style="min-height: 90vh;">-->
<!--    <div class="wrapper wrapper&#45;&#45;w960 pt-3" style="min-height: auto;width: unset;">-->
<!--        <div class="card card-2 pt-3">-->
<!--            <div class="card-body py-4">-->
<!--                <h2 class="title text-center" style="margin-bottom: 1rem;">{{direction == 'ltr' ? 'Create Account' : 'إنشاء حساب'}}</h2>-->
<!--                <form [formGroup]="form">-->
<!--                    <div class="input-group">-->
<!--                        <div class="col-6">-->
<!--                            <input-->
<!--                                    class="form-control"-->
<!--                                    type="text"-->
<!--                                    [placeholder]="direction=='ltr'? 'First Name' : 'الاسم الاول' "-->
<!--                                    formControlName="firstname"-->
<!--                                    name="firstname">-->
<!--                            <div *ngIf="-->
<!--                                      (form.controls.firstname.invalid && isLogged) ||-->
<!--                                      form.controls.firstname.dirty ||-->
<!--                                      form.controls.firstname.touched-->
<!--                                    ">-->
<!--                                <small class="text-danger fw-bold mb-3"-->
<!--                                       *ngIf="form.controls.firstname.hasError('required')">-->
<!--                                    {{direction == 'ltr' ? 'First Name is required' : 'الاسم الاول مطلوب'}}-->
<!--                                </small>-->
<!--                                <small class="text-danger fw-bold mb-3"-->
<!--                                       *ngIf="form.controls.firstname.hasError('minlength')">-->
<!--                                    {{ direction == 'ltr' ? 'First Name must be at least 3 characters' : 'الاسم الاول يجب أن يكون 3 أحرف على الأقل' }}-->
<!--                                </small>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-6">-->
<!--                            <input-->
<!--                                    class="form-control"-->
<!--                                    type="text"-->
<!--                                    [placeholder]="direction=='ltr'? 'Last Name' : 'الإسم الأخير' "-->
<!--                                    formControlName="lastname"-->
<!--                                    name="lastname">-->
<!--                            <div *ngIf="-->
<!--                                      (form.controls.lastname.invalid && isLogged) ||-->
<!--                                      form.controls.lastname.dirty ||-->
<!--                                      form.controls.lastname.touched-->
<!--                                    ">-->
<!--                                <small class="text-danger fw-bold mb-3"-->
<!--                                       *ngIf="form.controls.lastname.hasError('required')">-->
<!--                                    {{direction == 'ltr' ? 'Last Name is required' : 'الاسم الاخير مطلوب'}}-->
<!--                                </small>-->
<!--                                <small class="text-danger fw-bold mb-3"-->
<!--                                       *ngIf="form.controls.lastname.hasError('minlength')">-->
<!--                                    {{ direction == 'ltr' ? 'Last Name must be at least 3 characters' : 'الاسم الاخير يجب أن يكون 3 أحرف على الأقل' }}-->
<!--                                </small>-->
<!--                            </div>-->

<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="input-group">-->
<!--                        <div class="col-6">-->
<!--                            <input class="form-control"-->
<!--                                   type="email"-->
<!--                                   [placeholder]="direction=='ltr'? 'Email' : 'البريد الإلكتروني' "-->
<!--                                   formControlName="email"-->
<!--                                   name="email"-->
<!--                            >-->
<!--                            <div *ngIf="-->
<!--                                      (form.controls.email.invalid && isLogged) ||-->
<!--                                      form.controls.email.dirty ||-->
<!--                                      form.controls.email.touched-->
<!--                                    ">-->
<!--                                <small class="text-danger fw-bold mb-3"-->
<!--                                       *ngIf="form.controls.email.hasError('required')">-->
<!--                                    {{direction == 'ltr' ? 'Email is required' : 'البريد الإلكتروني مطلوب'}}-->
<!--                                </small>-->
<!--                                <small class="text-danger fw-bold mb-3"-->
<!--                                       *ngIf="form.controls.email.hasError('email')">-->
<!--                                    {{direction == 'ltr' ? 'Email is invalid' : 'البريد الإلكتروني غير صالح'}}-->
<!--                                </small>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-6" style="color:#666;">-->
<!--                            <input class="form-control"-->
<!--                                   type="text"-->
<!--                                   ng2TelInput-->
<!--                                   formControlName="phone"-->
<!--                                   name="phone"-->
<!--                            >-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="input-group">-->
<!--                        <div class="col-6">-->
<!--                            <input-->
<!--                                    class="form-control"-->
<!--                                    type="password"-->
<!--                                    [placeholder]="direction=='ltr'? 'Password' : 'كلمة السر ' "-->
<!--                                    formControlName="password"-->
<!--                                    name="password"-->
<!--                                    #password-->
<!--                            >-->
<!--                            <div *ngIf="-->
<!--                                      (form.controls.password.invalid && isLogged) ||-->
<!--                                      form.controls.password.dirty ||-->
<!--                                      form.controls.password.touched-->
<!--                                    ">-->
<!--                                <small class="text-danger fw-bold mb-3"-->
<!--                                       *ngIf="form.controls.password.hasError('required')">-->
<!--                                    {{direction == 'ltr' ? 'Password is required' : 'كلمة السر مطلوبة'}}-->
<!--                                </small>-->
<!--                                <small class="text-danger fw-bold mb-3"-->
<!--                                       *ngIf="form.controls.password.hasError('minlength')">-->
<!--                                    {{ direction == 'ltr' ? 'Password must be at least 8 characters' : 'كلمة السر يجب أن تكون 8 أحرف على الأقل' }}-->
<!--                                </small>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-6">-->
<!--                            <input-->
<!--                                    class="form-control"-->
<!--                                    type="password"-->
<!--                                    [placeholder]="direction=='ltr'? 'Password Confirmation' : 'تأكيد كلمة المرور ' "-->
<!--                                    formControlName="passwordConfirmation"-->
<!--                                    name="passwordConfirmation"-->
<!--                            >-->
<!--                            <div *ngIf="-->
<!--                                      (form.controls.passwordConfirmation.invalid && isLogged) ||-->
<!--                                      form.controls.passwordConfirmation.dirty ||-->
<!--                                      form.controls.passwordConfirmation.touched-->
<!--                                    ">-->
<!--                                <small class="text-danger fw-bold mb-3"-->
<!--                                       *ngIf="form.controls.passwordConfirmation.hasError('required')">-->
<!--                                    {{direction == 'ltr' ? 'Password Confirmation is required' : 'تأكيد كلمة المرور مطلوب'}}-->
<!--                                </small>-->
<!--                                <small class="text-danger fw-bold mb-3"-->
<!--                                       *ngIf="form.controls.passwordConfirmation.hasError('minlength')">-->
<!--                                    {{ direction == 'ltr' ? 'Password Confirmation must be at least 8 characters' : 'تأكيد كلمة المرور يجب أن تكون 8 أحرف على الأقل' }}-->
<!--                                </small>-->
<!--                                <small class="text-danger fw-bold mb-3"-->
<!--                                       *ngIf="form.controls.passwordConfirmation.hasError('mustMatch')">-->
<!--                                    {{ direction == 'ltr' ? 'Password Confirmation must be the same as password' : 'تأكيد كلمة المرور يجب أن يكون نفس كلمة المرور' }}-->
<!--                                </small>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="input-group">-->
<!--                        <div class="col-6">-->
<!--                            <input-->
<!--                                    [placeholder]="direction=='ltr'? 'Country' : 'الدولة ' "-->
<!--                                    class="form-control"-->
<!--                                    list="countries"-->
<!--                                    formControlName="country"-->
<!--                                    name="country"-->
<!--                            >-->
<!--                            <datalist id="countries">-->
<!--                                <option value="" disabled="disabled" selected="selected">اختر الدولة</option>-->
<!--                                <option-->
<!--                                        *ngFor="let country of ar_countries"-->
<!--                                        [value]="country.country_name">{{country.country_name}}-->
<!--                                </option>-->
<!--                            </datalist>-->
<!--                        </div>-->
<!--                        <div class="col-6">-->
<!--                            <input-->
<!--                                    type="text"-->
<!--                                    class="form-control"-->
<!--                                    [placeholder]="direction=='ltr'? 'Address' : 'العنوان' "-->
<!--                                    formControlName="adress"-->
<!--                                    name="adress"-->
<!--                            >-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="input-group">-->
<!--                        <div class="col-6">-->
<!--                            <input-->
<!--                                    type="date"-->
<!--                                    class="form-control"-->
<!--                                    max="{{ageControl()}}"-->
<!--                                    placeholder="Birthdate"-->
<!--                                    formControlName="birthdate"-->
<!--                                    name="birthdate"-->
<!--                            >-->
<!--                        </div>-->
<!--                        <div class="col-6">-->
<!--                            <div class="form-group h-100">-->
<!--                                <select-->
<!--                                        formControlName="gender"-->
<!--                                        name="gender"-->
<!--                                        class="form-control"-->
<!--                                        style="height: 46px"-->
<!--                                >-->
<!--                                    <option value="" disabled selected hidden>الجنس</option>-->
<!--                                    <option value="0">ذكر</option>-->
<!--                                    <option value="1">أنثى</option>-->
<!--                                </select>-->
<!--                                <div *ngIf="-->
<!--                                      (form.controls.gender.invalid && isLogged) ||-->
<!--                                      form.controls.gender.dirty ||-->
<!--                                      form.controls.gender.touched-->
<!--                                    ">-->
<!--                                    <small class="text-danger fw-bold mb-3"-->
<!--                                           *ngIf="form.controls.gender.hasError('required')">-->
<!--                                        {{direction == 'ltr' ? 'Sex is required' : 'الجنس مطلوب'}}-->
<!--                                    </small>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="input-group mb-0">-->
<!--                        <div class="col-6">-->
<!--                            <label for="agreeTerms"-->
<!--                                   style="color: #333;margin-left: 10px;">-->
<!--                                {{direction == 'ltr' ? 'I agree to the' : 'أنا أوافق على'}}-->
<!--                                <a routerLink="/terms">-->
<!--                                    {{direction == 'ltr' ? 'terms' : 'الشروط والأحكام'}}-->
<!--                                </a>-->
<!--                                <input-->
<!--                                        [checked]="termsInput"-->
<!--                                        (change)="termsInput = !termsInput"-->
<!--                                        id="agreeTerms"-->
<!--                                        name="terms"-->
<!--                                        type="checkbox"-->
<!--                                        style="width: fit-content;margin: auto 8px;">-->

<!--                                <div *ngIf="isLogged && !termsInput">-->
<!--                                    <small class="text-danger fw-bold mb-3">-->
<!--                                        {{direction == 'ltr' ? 'You must agree to the terms' : 'يجب أن توافق على الشروط'}}-->
<!--                                    </small>-->
<!--                                </div>-->
<!--                            </label>-->
<!--                        </div>-->
<!--                        <div class="col-12">-->
<!--                            <div class="w-25 mx-auto">-->
<!--                                <button class="commonBtn"-->
<!--                                        (click)="register()"-->
<!--                                        type="submit">{{ direction == 'ltr' ? 'Register' : 'تسجيل حساب' }}</button>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-12">-->
<!--                            <div class="w-50 mx-auto">-->
<!--                                <div class="oauth-separator">-->
<!--                                    <span>{{direction == 'ltr' ? 'or' : 'أو'}}</span>-->
<!--                                </div>-->
<!--                                <button type="button" class="commonBtn oauth-button google" (click)="registerWithGoogle()">-->
<!--                                    <img src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"-->
<!--                                         alt="Google logo" class="login-form__btn-icon"> Sign up with Google-->
<!--                                </button>-->
<!--                                <div class="links text-center">-->
<!--                                    <a class="mb-1" routerLink="/logInClient">-->
<!--                                        {{direction == 'ltr' ? 'Already have an account?' : 'هل لديك حساب؟'}}-->
<!--                                    </a>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </form>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="page-wrapper pb-5" style="min-height: 70vh;">
    <div class="container pt-5" style="min-height: auto;">
        <div class="card mx-auto card-2 pt-3 form-wrapper">
            <div class="card-body py-4">
                <h2 class="title text-center" style="margin-bottom: 1rem;">{{direction == 'ltr' ? 'Create Account' : 'إنشاء حساب'}}</h2>
                <form [formGroup]="form">
                    <div class="input-group">
                        <div class="col-12 col-md-6 mb-4 mb-md-0">
                            <input
                                    class="form-control"
                                    type="text"
                                    [placeholder]="direction=='ltr'? 'First Name' : 'الاسم الاول' "
                                    formControlName="firstname"
                                    name="firstname">
                            <div *ngIf="
                                      (form.controls.firstname.invalid && isLogged) ||
                                      form.controls.firstname.dirty ||
                                      form.controls.firstname.touched
                                    ">
                                <small class="text-danger fw-bold mb-3"
                                       *ngIf="form.controls.firstname.hasError('required')">
                                    {{direction == 'ltr' ? 'First Name is required' : 'الاسم الاول مطلوب'}}
                                </small>
                                <small class="text-danger fw-bold mb-3"
                                       *ngIf="form.controls.firstname.hasError('minlength')">
                                    {{ direction == 'ltr' ? 'First Name must be at least 3 characters' : 'الاسم الاول يجب أن يكون 3 أحرف على الأقل' }}
                                </small>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <input
                                    class="form-control"
                                    type="text"
                                    [placeholder]="direction=='ltr'? 'Last Name' : 'الإسم الأخير' "
                                    formControlName="lastname"
                                    name="lastname">
                            <div *ngIf="
                                      (form.controls.lastname.invalid && isLogged) ||
                                      form.controls.lastname.dirty ||
                                      form.controls.lastname.touched
                                    ">
                                <small class="text-danger fw-bold mb-3"
                                       *ngIf="form.controls.lastname.hasError('required')">
                                    {{direction == 'ltr' ? 'Last Name is required' : 'الاسم الاخير مطلوب'}}
                                </small>
                                <small class="text-danger fw-bold mb-3"
                                       *ngIf="form.controls.lastname.hasError('minlength')">
                                    {{ direction == 'ltr' ? 'Last Name must be at least 3 characters' : 'الاسم الاخير يجب أن يكون 3 أحرف على الأقل' }}
                                </small>
                            </div>

                        </div>
                    </div>
                    <div class="input-group">
                        <div class="col">
                            <input class="form-control"
                                   type="email"
                                   [placeholder]="direction=='ltr'? 'Email' : 'البريد الإلكتروني' "
                                   formControlName="email"
                                   name="email"
                            >
                            <div *ngIf="
                                      (form.controls.email.invalid && isLogged) ||
                                      form.controls.email.dirty ||
                                      form.controls.email.touched
                                    ">
                                <small class="text-danger fw-bold mb-3"
                                       *ngIf="form.controls.email.hasError('required')">
                                    {{direction == 'ltr' ? 'Email is required' : 'البريد الإلكتروني مطلوب'}}
                                </small>
                                <small class="text-danger fw-bold mb-3"
                                       *ngIf="form.controls.email.hasError('email')">
                                    {{direction == 'ltr' ? 'Email is invalid' : 'البريد الإلكتروني غير صالح'}}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="col input-group mb-0">
                            <input
                                    class="form-control"
                                    [type]="passwordInputType"
                                    [placeholder]="direction=='ltr'? 'Password' : 'كلمة السر ' "
                                    formControlName="password"
                                    name="password"
                                    #password
                            >
                            <div class="input-group-prepend">
                                <span id="togglePassword" (click)="togglePasswordVisibility()">
                                  <i class="fa fa-eye" id="eyeIcon" #eyeIcon></i>
                                </span>
                            </div>
                        </div>
                        <div class="col" *ngIf="
                                      (form.controls.password.invalid && isLogged) ||
                                      form.controls.password.dirty ||
                                      form.controls.password.touched
                                    ">
                            <small class="text-danger fw-bold mb-3"
                                   *ngIf="form.controls.password.hasError('required')">
                                {{direction == 'ltr' ? 'Password is required' : 'كلمة السر مطلوبة'}}
                            </small>
                            <small class="text-danger fw-bold mb-3"
                                   *ngIf="form.controls.password.hasError('minlength')">
                                {{ direction == 'ltr' ? 'Password must be at least 8 characters' : 'كلمة السر يجب أن تكون 8 أحرف على الأقل' }}
                            </small>
                        </div>
                    </div>
                    <div class="input-group mt-3">
                        <div class="col">
                            <label
                                   style="color: #333;margin-left: 10px;">
                                التسجيل يعني الالتزام
                                <a routerLink="/terms">
                                    {{direction == 'ltr' ? 'terms' : 'بالشروط والأحكام'}}
                                </a>
                            </label>
                        </div>
                        <div class="col-12">
                            <div class="mx-auto">
                                <button class="commonBtn"
                                        (click)="register()"
                                        type="submit">{{ direction == 'ltr' ? 'Register' : 'تسجيل حساب' }}</button>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mx-auto">
                                <div class="oauth-separator">
                                    <span>{{direction == 'ltr' ? 'or' : 'أو'}}</span>
                                </div>
                                <button type="button" class="commonBtn oauth-button google" (click)="registerWithGoogle()">
                                    <img src="/assets/googleLogo.webp" alt="Google logo" class="login-form__btn-icon"> Sign up with Google
                                </button>
                                <div class="links text-center">
                                    <a class="mb-1" routerLink="/logInClient">
                                        {{direction == 'ltr' ? 'Already have an account?' : 'هل لديك حساب؟'}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>