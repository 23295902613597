import { Component, OnInit } from '@angular/core';
import { PublicService } from 'src/app/services/public.service';
import { UserAuthService } from 'src/app/services/user-auth.service';
import { ServerApi } from 'src/environments/environment';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  about;
  direction='rtl';
  home;
  publicUrl = `${ServerApi.PublicRouteTemp}/home/`;
  constructor(private publicService:PublicService, private authService:UserAuthService) { }

  ngOnInit() {
    window.scroll(0,0);
    this.direction = localStorage.getItem('direction');
    this.authService.source.subscribe((value) => {
      this.direction = value;
    });
    this.publicService.getHome().subscribe(
      (res: any) => {
        this.home = res;
        this.about = res.about;
        this.about.ar.image = ServerApi.PublicRouteTemp + '/home/' + this.about.ar.image;
        this.updateDescription();
      },console.error);
  }

  private updateDescription() {
    this.htmlTags.forEach((tag) => {
      this.about['ar']['description'] = this.about['ar']['description']
          .replace(new RegExp(tag, 'g'), `${tag} class="smLineHeight lightFont about__description-text"`).replace(/&nbsp;/g, '');
    });
  }

  htmlTags = [
    '<p', '<span', '<ul', '<ol', '<li', '<br', '<h1', '<h2', '<h3', '<h4', '<h5', '<h6', '<div', '<img', '<a',
    '<iframe', '<table', '<tbody', '<tr', '<td', '<th', '<thead', '<tfoot'];

}
