<main class="container text-right pt-3">
    <section class="faq">
        <h1 class="txtMainColor my-3">الاسئلة الشائعة</h1>
        <mat-accordion *ngIf="isLoaded" [multi]="true" #accordion="matAccordion">
            <mat-expansion-panel
                    style="border: 1px solid #67676742;background: #f6f6f6;color: #525253;"
                    *ngFor="let qst of qstList; let index = index"
            >
                <mat-expansion-panel-header class="bgLightColor">
                    <span class="title-mat">{{index + 1}}. {{qst.questions}}</span>
                </mat-expansion-panel-header>
                <div class="file-list-item px-5">
                    <p>
                        - {{qst.answers}}
                    </p>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
    <section *ngIf="!isLoaded" class="text-center">
        <img src="assets/images/loading.gif" alt="loading">
    </section>
    <section class="contact">
        <div class="text-center my-3">
            <p class="lead txtMainColor">
                {{direction == 'ltr' ? "If you have any other questions or couldn't find what you were looking for, please" : "إذا كان لديك أي أسئلة أخرى أو لم تجد ما كنت تبحث عنه، يرجى"}}
                <a routerLink="/contactUs">
                    {{direction == 'ltr' ? "contact us" : "التواصل معنا"}}.
                </a>
            </p>
        </div>
    </section>
</main>
