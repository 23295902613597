<div class="container about" *ngIf="about">
    <div class="card my-5">
        <div class="about_titleAndImage text-center">
            <h1 class="txtMainColor">{{direction == 'ltr' ? 'About Us' : 'حولنا'}}</h1>
            <div class="mx-auto mt-4 about_titleAndImage-img-container">
                <img class="w-100 mdRadius" src="{{about.ar.image}}" alt="Mohammed Nadjmo" />
            </div>
        </div>
        <div class="about__description text-right mt-4 px-4">
            <div [innerHTML]=" direction == 'ltr' ? about.en.description : about.ar.description | safehtml"></div>
        </div>
        <div class="row">
            <div class="col-md-4 text-center mt-4" *ngFor="let solution of (direction=='ltr' ? home.solutions.en.descriptions : home.solutions.ar.descriptions)">
                <img class="imgCircleLg border" src="{{publicUrl + solution.image}}" alt="icon" />
                <p class="txtMainColor mt-3" [innerHTML]="solution.description | safehtml"></p>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!about" style="min-height: 100vh;"></div>