import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserAuthService} from "../../services/user-auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(
      private _snackBar: MatSnackBar,
      private _activatedRoute: ActivatedRoute,
      private _userAuthService: UserAuthService,
      private _formBuilder: FormBuilder,
      private _router: Router
  ) {
  }

  form: FormGroup;
    isLogged: boolean = false;
    direction: string = 'rtl';
    token: string = this._activatedRoute.snapshot.params['token'];
  ngOnInit() {
    this.form = this._formBuilder.group(
        {
          password: ['', [Validators.required, Validators.minLength(8)]],
          passwordConfirmation: ['', [Validators.required, Validators.minLength(8)]]
        },{
          validator: this._userAuthService.mustMatch('password', 'passwordConfirmation')
        }
    )
  }


    openSnackBar(msg) {
        this._snackBar.open(msg, 'إغلاق', {duration: 5000});
    }
    submit() {
      this.isLogged = true;
        if(this.form.invalid) return;
        this._userAuthService.resetPasswordWithToken(this.form.controls['password'].value, this.token)
            .subscribe(
                () => {
                    let msg = 'تم تغيير كلمة المرور بنجاح';
                    this.openSnackBar(msg);
                    this.isLogged = false;
                    this._router.navigateByUrl('/logInClient');
                },
                () => {
                    this.openSnackBar('حدث خطأ ما يرجى المحاولة مرة أخرى');
                }
            );
    }

}
